// ProductRow.js

import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Checkbox, TextField } from '@mui/material';

const ProductRow = React.memo(function ProductRow({
  product,
  onSelect,
  onChangeTitle,
  onChangeDesc,
  focusedProductId,
  setFocusedProductId,
}) {
  // generatedTitleとeditableJpDescをローカルステートで管理
  const [localGeneratedTitle, setLocalGeneratedTitle] = useState(product.generatedTitle);
  const [localJpDesc, setLocalJpDesc] = useState(product.editableJpDesc);

  // productが更新されたとき、かつこの行がフォーカス外であればローカル状態を同期
  useEffect(() => {
    if (focusedProductId !== product.id) {
      setLocalGeneratedTitle(product.generatedTitle);
      setLocalJpDesc(product.editableJpDesc);
    }
  }, [product.generatedTitle, product.editableJpDesc, focusedProductId, product.id]);

  return (
    <TableRow hover>
      <TableCell padding="checkbox">
        <Checkbox
          checked={product.selected}
          onChange={() => onSelect(product.id)}
        />
      </TableCell>
      <TableCell
        sx={{
          fontSize: '0.8rem',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          maxWidth: '300px',
          padding: '4px',
        }}
      >
        {product.title}
      </TableCell>

      <TableCell sx={{ padding: '4px' }}>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={localJpDesc}
          onFocus={() => setFocusedProductId(product.id)}
          onChange={(e) => setLocalJpDesc(e.target.value)}
          onBlur={() => {
            setFocusedProductId(null);
            // フォーカスアウト時にproductsを更新
            onChangeDesc(product.id, localJpDesc);
          }}
          InputProps={{
            sx: {
              textarea: {
                maxHeight: focusedProductId === product.id ? 'none' : '8em',
                overflow: 'auto',
                transition: 'max-height 0.3s ease',
              },
            },
          }}
          inputProps={{
            style: {
              fontSize: '0.8rem',
              padding: '2px',
              lineHeight: '1.2',
            },
          }}
        />
      </TableCell>

      <TableCell sx={{ padding: '4px' }}>
        <TextField
          multiline
          fullWidth
          variant="outlined"
          value={localGeneratedTitle}
          onFocus={() => setFocusedProductId(product.id)}
          onChange={(e) => setLocalGeneratedTitle(e.target.value)}
          onBlur={() => {
            setFocusedProductId(null);
            // フォーカスアウト時にproductsを更新
            onChangeTitle(product.id, localGeneratedTitle);
          }}
          inputProps={{
            style: {
              fontSize: '0.8rem',
              padding: '2px',
              lineHeight: '1.2',
            },
          }}
          sx={{
            margin: 0,
          }}
        />
      </TableCell>
    </TableRow>
  );
});

export default ProductRow;
