// Notificat.js
import React, { useEffect, useState } from "react";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Box,
  Button
} from "@mui/material";
import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { db } from "./firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
  doc,
  updateDoc,
  writeBatch
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Notificat({ user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    // ユーザーごとの通知を取得（最新20件）
    const q = query(
      collection(db, "notifications"),
      where("userId", "==", user.uid),
      orderBy("timestamp", "desc"),
      limit(20)
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationsData = [];
        querySnapshot.forEach((docSnap) => {
          notificationsData.push({ id: docSnap.id, ...docSnap.data() });
        });
        setNotifications(notificationsData);
      },
      (error) => {
        console.error("通知の取得中にエラーが発生しました:", error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notificationId) => {
    const notificationRef = doc(db, "notifications", notificationId);
    await updateDoc(notificationRef, { read: true }).catch((error) => {
      console.error("通知の更新中にエラーが発生しました:", error);
    });
    handleMenuClose();
  };

  const handleMarkAllAsRead = async () => {
    const unreadNotifications = notifications.filter((n) => !n.read);
    if (unreadNotifications.length === 0) return;

    const batch = writeBatch(db);
    unreadNotifications.forEach((n) => {
      const ref = doc(db, "notifications", n.id);
      batch.update(ref, { read: true });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error("一括既読更新中にエラーが発生しました:", error);
    }
    // onSnapshotで自動更新されるので特別なUI操作は不要
  };

  // 未読の通知数
  const unreadCount = notifications.filter((n) => !n.read).length;

  // URLリンク化用正規表現
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const renderNotificationText = (text) => {
    if (!text) return null;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <div>
      <IconButton color="inherit" onClick={handleMenuOpen}>
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: '600px',
            overflowY: 'auto'
          },
        }}
      >
        {notifications.length === 0 ? (
          <MenuItem onClick={handleMenuClose}>
            新しいお知らせはありません
          </MenuItem>
        ) : (
          <Box sx={{ maxWidth: '100%' }}>
            {/* 上部に全て既読ボタン */}
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button
                variant="text"
                size="small"
                onClick={async () => {
                  await handleMarkAllAsRead();
                }}
              >
                すべて既読にする
              </Button>
            </Box>
            <Divider />
            {notifications.map((notification, idx) => {
              const isUnread = !notification.read;
              const textColor = isUnread ? 'black' : 'text.secondary';
              const bgColor = isUnread ? 'rgba(0,0,0,0.04)' : 'transparent';
              const displayText = notification.details || notification.message;

              return (
                <Box key={notification.id}>
                  <MenuItem
                    onClick={() => handleNotificationClick(notification.id)}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      whiteSpace: 'normal',
                      backgroundColor: bgColor,
                      cursor: 'pointer'
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: isUnread ? 'bold' : 'normal', color: textColor }}
                    >
                      {notification.title || '通知'}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 0.5, color: textColor }}
                    >
                      {renderNotificationText(displayText)}
                    </Typography>
                  </MenuItem>
                  {idx < notifications.length - 1 && <Divider />}
                </Box>
              );
            })}
          
          </Box>
        )}
      </Menu>
    </div>
  );
}

export default Notificat;




// // Notificat.js
// import React, { useEffect, useState } from "react";
// import {
//   IconButton,
//   Badge,
//   Menu,
//   MenuItem,
//   Typography,
//   Divider,
//   Box
// } from "@mui/material";
// import { Notifications as NotificationsIcon } from "@mui/icons-material";
// import { db } from "./firebaseConfig";
// import {
//   collection,
//   query,
//   where,
//   onSnapshot,
//   orderBy,
//   limit,
//   doc,
//   updateDoc,
// } from "firebase/firestore";

// function Notificat({ user }) {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notifications, setNotifications] = useState([]);

//   useEffect(() => {
//     if (!user) return;

//     // ユーザーごとの通知を取得
//     const q = query(
//       collection(db, "notifications"),
//       where("userId", "==", user.uid),
//       orderBy("timestamp", "desc"),
//       limit(20)
//     );

//     const unsubscribe = onSnapshot(
//       q,
//       (querySnapshot) => {
//         const notificationsData = [];
//         querySnapshot.forEach((docSnap) => {
//           notificationsData.push({ id: docSnap.id, ...docSnap.data() });
//         });
//         setNotifications(notificationsData);
//       },
//       (error) => {
//         console.error("通知の取得中にエラーが発生しました:", error);
//       }
//     );

//     return () => unsubscribe();
//   }, [user]);

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
  
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleNotificationClick = async (notificationId) => {
//     const notificationRef = doc(db, "notifications", notificationId);
//     await updateDoc(notificationRef, { read: true }).catch((error) => {
//       console.error("通知の更新中にエラーが発生しました:", error);
//     });
//     handleMenuClose();
//   };

//   // 未読の通知数
//   const unreadCount = notifications.filter((n) => !n.read).length;

//   // URLリンク化用正規表現
//   const urlRegex = /(https?:\/\/[^\s]+)/g;

//   const renderNotificationText = (text) => {
//     if (!text) return null;
//     const parts = text.split(urlRegex);
//     return parts.map((part, index) => {
//       if (urlRegex.test(part)) {
//         return (
//           <a
//             key={index}
//             href={part}
//             target="_blank"
//             rel="noopener noreferrer"
//             style={{ color: 'blue', textDecoration: 'underline' }}
//           >
//             {part}
//           </a>
//         );
//       } else {
//         return <span key={index}>{part}</span>;
//       }
//     });
//   };

//   return (
//     <div>
//       <IconButton color="inherit" onClick={handleMenuOpen}>
//         <Badge badgeContent={unreadCount} color="secondary">
//           <NotificationsIcon />
//         </Badge>
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//         PaperProps={{
//           style: {
//             maxHeight: 400,
//             width: '300px',
//           },
//         }}
//       >
//         {notifications.length === 0 ? (
//           <MenuItem onClick={handleMenuClose}>
//             新しいお知らせはありません
//           </MenuItem>
//         ) : (
//           <Box sx={{ maxWidth: '100%' }}>
//             {notifications.map((notification, idx) => {
//               const isUnread = !notification.read;
//               const textColor = isUnread ? 'black' : 'text.secondary';
//               const bgColor = isUnread ? 'rgba(0,0,0,0.04)' : 'transparent';
//               const displayText = notification.details || notification.message;

//               return (
//                 <Box key={notification.id}>
//                   <MenuItem
//                     onClick={() => handleNotificationClick(notification.id)}
//                     style={{
//                       display: 'flex',
//                       flexDirection: 'column',
//                       alignItems: 'flex-start',
//                       whiteSpace: 'normal',
//                       backgroundColor: bgColor
//                     }}
//                   >
//                     <Typography
//                       variant="subtitle1"
//                       sx={{ fontWeight: isUnread ? 'bold' : 'normal', color: textColor }}
//                     >
//                       {notification.title || '通知'}
//                     </Typography>
//                     <Typography
//                       variant="body2"
//                       sx={{ mt: 0.5, color: textColor }}
//                     >
//                       {renderNotificationText(displayText)}
//                     </Typography>
//                   </MenuItem>
//                   {idx < notifications.length - 1 && <Divider />}
//                 </Box>
//               );
//             })}
//           </Box>
//         )}
//       </Menu>
//     </div>
//   );
// }

// export default Notificat;
