// src/AccountSettings.js

import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Switch,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// テンプレート名 + 画像URL をこちらで定義する例
const bannerTemplates = [
  {
    key: "antique",
    label: "アンティーク",
    imageUrl: "https://i.postimg.cc/VkX1s85p/2.png",
  },
  {
    key: "modern",
    label: "コレクティブ",
    imageUrl: "https://i.postimg.cc/xTwnSCjL/1.png",
  },
  {
    key: "simple",
    label: "時計",
    imageUrl: "https://i.postimg.cc/J7dRPx96/3.png",
  },
  {
    key: "business",
    label: "ファッション",
    imageUrl: "https://i.postimg.cc/c4g0jYWs/4.png",
  },
];

function AccountSettings({ user }) {
  const [loading, setLoading] = useState(true);

  // Firestoreから読み込むユーザー設定
  const [bannerEnabled, setBannerEnabled] = useState(false);
  const [bannerUrl, setBannerUrl] = useState("");
  const [picInsertEnabled, setPicInsertEnabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  // Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    const fetchUserSettings = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setBannerEnabled(data.bannerEnabled ?? false);
          setBannerUrl(data.bannerUrl ?? "");
          setPicInsertEnabled(data.picInsertEnabled ?? false);
          setSelectedTemplate(data.selectedTemplate ?? ""); // Firestoreにあれば読み込み
        }
      } catch (error) {
        console.error("ユーザー設定の読み込み中にエラー:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserSettings();
  }, [user]);

  const handleSave = async () => {
    if (!user) return;
    setLoading(true);

    try {
      const docRef = doc(db, "users", user.uid);
      await setDoc(
        docRef,
        {
          bannerEnabled,
          bannerUrl,
          picInsertEnabled,
          selectedTemplate,
        },
        { merge: true }
      );

      setSnackbar({
        open: true,
        message: "設定を保存しました",
        severity: "success",
      });
    } catch (error) {
      console.error("設定の保存中にエラー:", error);
      setSnackbar({
        open: true,
        message: `設定の保存に失敗しました: ${error.message}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // テンプレートを選択したときの処理
  const handleSelectTemplate = (templateKey, templateImageUrl) => {
    setSelectedTemplate(templateKey);
    // バナー画像URLをテンプレートのURLに合わせる等
    setBannerUrl(templateImageUrl);
  };

  if (!user) {
    return <Typography>ログインしてください。</Typography>;
  }

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 1200, mx: "auto" }}>
      {/* 戻るボタン + タイトル */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={() => navigate("/")} sx={{ mr: 1 }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          アカウント設定
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {/* 左側カラム */}
        <Grid item xs={12} md={8}>
          {/* 基本情報 */}
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              基本情報
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary">
                メールアドレス
              </Typography>
              <TextField
                size="small"
                fullWidth
                disabled
                value={user.email || "(未取得)"}
                sx={{ mt: 1 }}
              />
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                ユーザーID (UID)
              </Typography>
              <TextField
                size="small"
                fullWidth
                disabled
                value={user.uid}
                sx={{ mt: 1 }}
              />
            </Box>
          </Paper>

          {/* バナー設定 */}
          <Paper variant="outlined" sx={{ mb: 3 }}>
            <Box sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                バナー設定
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  バナー画像URL
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="https://example.com/banner.jpg"
                  value={bannerUrl}
                  onChange={(e) => setBannerUrl(e.target.value)}
                  sx={{ mt: 1 }}
                />
              </Box>

              {/* バナー画像プレビュー */}
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                {bannerUrl ? (
                  <img
                    src={bannerUrl}
                    alt="Banner Preview"
                    style={{ width: "100%", display: "block" }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: 160,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#f9f9f9",
                      color: "#999",
                    }}
                  >
                    バナー画像のプレビュー
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>

          {/* 表示設定 */}
          <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
              表示設定
            </Typography>
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={bannerEnabled}
                    onChange={(e) => setBannerEnabled(e.target.checked)}
                  />
                }
                label="バナー画像を先頭に表示"
              />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                ページの最上部にバナー画像を表示します
              </Typography>
            </Box>

            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={picInsertEnabled}
                    onChange={(e) => setPicInsertEnabled(e.target.checked)}
                  />
                }
                label="複数商品画像をDescriptionに挿入"
              />
              <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                商品説明文に複数の画像を表示します
              </Typography>
            </Box>
          </Paper>

          {/* 保存ボタン */}
          <Box sx={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleSave}>
              設定を保存
            </Button>
          </Box>
        </Grid>

        {/* 右側カラム: バナーテンプレート一覧 */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2 }}>
            バナーテンプレート
          </Typography>

          <Grid container spacing={2}>
            {bannerTemplates.map((tpl) => (
              <Grid item xs={12} key={tpl.key}>
                <Card
                  variant="outlined"
                  sx={{
                    cursor: "pointer",
                    borderColor:
                      selectedTemplate === tpl.key ? "primary.main" : "#ccc",
                  }}
                  onClick={() => handleSelectTemplate(tpl.key, tpl.imageUrl)}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="120"
                      image={tpl.imageUrl}
                      alt={tpl.label}
                    />
                    <CardContent>
                      <Typography
                        variant="body1"
                        color={
                          selectedTemplate === tpl.key
                            ? "primary"
                            : "text.primary"
                        }
                      >
                        {tpl.label}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Snackbar通知 */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountSettings;





// // src/AccountSettings.js

// import React, { useState, useEffect } from "react";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "./firebaseConfig";
// import {
//   Box,
//   Typography,
//   Switch,
//   FormControlLabel,
//   TextField,
//   Button,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Paper,
//   Card,
//   CardContent,
//   CardHeader,
//   Divider,
//   IconButton,
//   Grid,
// } from "@mui/material";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
// import { useNavigate } from "react-router-dom";

// function AccountSettings({ user }) {
//   const [loading, setLoading] = useState(true);

//   // Firestoreから読み込むユーザー設定
//   const [bannerEnabled, setBannerEnabled] = useState(false);
//   const [bannerUrl, setBannerUrl] = useState("");
//   const [picInsertEnabled, setPicInsertEnabled] = useState(false);

//   // Snackbar(通知)用
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "info",
//   });

//   const navigate = useNavigate();

//   // Firestoreからユーザー設定を読み込む
//   useEffect(() => {
//     if (!user) return;

//     const fetchUserSettings = async () => {
//       try {
//         const docRef = doc(db, "users", user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           setBannerEnabled(data.bannerEnabled ?? false);
//           setBannerUrl(data.bannerUrl ?? "");
//           setPicInsertEnabled(data.picInsertEnabled ?? false);
//         }
//       } catch (error) {
//         console.error("ユーザー設定の読み込み中にエラー:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserSettings();
//   }, [user]);

//   // 「設定を保存」処理
//   const handleSave = async () => {
//     if (!user) return;
//     setLoading(true);

//     try {
//       const docRef = doc(db, "users", user.uid);
//       await setDoc(
//         docRef,
//         {
//           bannerEnabled,
//           bannerUrl,
//           picInsertEnabled,
//         },
//         { merge: true }
//       );

//       setSnackbar({
//         open: true,
//         message: "設定を保存しました",
//         severity: "success",
//       });
//     } catch (error) {
//       console.error("ユーザー設定の保存中にエラー:", error);
//       setSnackbar({
//         open: true,
//         message: `設定の保存に失敗しました: ${error.message}`,
//         severity: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (!user) {
//     return <Typography>ログインしてください。</Typography>;
//   }
//   if (loading) {
//     return (
//       <Box sx={{ textAlign: "center", mt: 4 }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ maxWidth: 800, mx: "auto", py: 4, px: 2 }}>
//       {/* ヘッダー部分 */}
//       <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
//         <IconButton
//           color="primary"
//           onClick={() => navigate("/")}
//           sx={{ mr: 1 }}
//         >
//           <ArrowBackIosNewIcon />
//         </IconButton>
//         <Typography variant="h5" sx={{ fontWeight: "bold" }}>
//           アカウント設定
//         </Typography>
//       </Box>

//       <Divider sx={{ mb: 3 }} />

//       {/* 基本情報 */}
//       <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
//         <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
//           基本情報
//         </Typography>
//         <Box sx={{ mb: 1 }}>
//           <Typography variant="body2" color="text.secondary">
//             メールアドレス
//           </Typography>
//           <Typography variant="body1">{user.email || "(未取得)"}</Typography>
//         </Box>
//         <Box>
//           <Typography variant="body2" color="text.secondary">
//             ユーザーID (UID)
//           </Typography>
//           <Typography variant="body1">{user.uid}</Typography>
//         </Box>
//       </Paper>

//       {/* バナー設定 */}
//       <Paper variant="outlined" sx={{ mb: 3 }}>
//         <CardHeader
//           title="バナー設定"
//           sx={{ bgcolor: "#f1f1f1", borderBottom: "1px solid #ccc" }}
//         />
//         <CardContent>
//           {/* バナー画像URL入力欄 */}
//           <Box sx={{ mb: 2 }}>
//             <Typography variant="body2" color="text.secondary">
//               バナー画像URL
//             </Typography>
//             <TextField
//               size="small"
//               placeholder="https://example.com/banner.jpg"
//               fullWidth
//               value={bannerUrl}
//               onChange={(e) => setBannerUrl(e.target.value)}
//               sx={{ mt: 1 }}
//             />
//           </Box>

//           {/* 画像プレビュー風エリア (ダミー) */}
//           <Box
//             sx={{
//               height: 150,
//               bgcolor: "#f9f9f9",
//               border: "1px dashed #ccc",
//               borderRadius: 2,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               color: "#999",
//               mb: 2,
//             }}
//           >
//             {bannerUrl ? (
//               <img
//                 src={bannerUrl}
//                 alt="Banner Preview"
//                 style={{ maxHeight: "100%", maxWidth: "100%" }}
//               />
//             ) : (
//               <Typography variant="body2">バナー画像のプレビュー</Typography>
//             )}
//           </Box>
//         </CardContent>
//       </Paper>

//       {/* 表示設定 */}
//       <Paper variant="outlined" sx={{ mb: 3 }}>
//         <CardHeader
//           title="表示設定"
//           sx={{ bgcolor: "#f1f1f1", borderBottom: "1px solid #ccc" }}
//         />
//         <CardContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={bannerEnabled}
//                     onChange={(e) => setBannerEnabled(e.target.checked)}
//                   />
//                 }
//                 label="バナー画像を先頭に表示"
//               />
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 sx={{ ml: 4, mb: 2 }}
//               >
//                 ページの最上部にバナー画像を表示します
//               </Typography>
//             </Grid>
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={picInsertEnabled}
//                     onChange={(e) => setPicInsertEnabled(e.target.checked)}
//                   />
//                 }
//                 label="複数商品画像をDescriptionに挿入"
//               />
//               <Typography
//                 variant="body2"
//                 color="text.secondary"
//                 sx={{ ml: 4, mb: 1 }}
//               >
//                 商品説明文に複数の画像を表示します
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </Paper>

//       {/* 保存ボタン */}
//       <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
//         <Button variant="contained" color="primary" onClick={handleSave}>
//           設定を保存
//         </Button>
//         <Button
//           variant="outlined"
//           color="secondary"
//           onClick={() => navigate("/")}
//         >
//           ホームへ戻る
//         </Button>
//       </Box>

//       {/* Snackbar通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           severity={snackbar.severity}
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           sx={{ width: "100%" }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }

// export default AccountSettings;




// // src/AccountSettings.js

// import React, { useState, useEffect } from "react";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "./firebaseConfig";
// import {
//   Box,
//   Typography,
//   Switch,
//   FormControlLabel,
//   TextField,
//   Button,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Paper,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
//   Tabs,
//   Tab
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// function AccountSettings({ user }) {
//   const [loading, setLoading] = useState(true);

//   // Firestoreから読み込むユーザー設定例
//   const [bannerEnabled, setBannerEnabled] = useState(false);
//   const [bannerUrl, setBannerUrl] = useState("");
//   const [picInsertEnabled, setPicInsertEnabled] = useState(false);
//   const [templateType, setTemplateType] = useState("template"); // "template" or "custom"
//   const [selectedTemplate, setSelectedTemplate] = useState("modern"); // ドロップダウンの値

//   // Snackbar(通知)用ステート
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "info",
//   });

//   // ホーム画面へ戻るナビゲーション
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!user) return;

//     const fetchUserSettings = async () => {
//       try {
//         const docRef = doc(db, "users", user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           const data = docSnap.data();

//           setBannerEnabled(data.bannerEnabled ?? false);
//           setBannerUrl(data.bannerUrl ?? "");
//           setPicInsertEnabled(data.picInsertEnabled ?? false);
//           // 例: data.templateType, data.selectedTemplate などもあれば読み込み
//           setTemplateType(data.templateType ?? "template");
//           setSelectedTemplate(data.selectedTemplate ?? "modern");
//         }
//       } catch (error) {
//         console.error("ユーザー設定の読み込み中にエラー:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserSettings();
//   }, [user]);

//   // 「保存」ボタン押下時の処理
//   const handleSave = async () => {
//     if (!user) return;
//     setLoading(true);

//     try {
//       const docRef = doc(db, "users", user.uid);
//       await setDoc(
//         docRef,
//         {
//           bannerEnabled,
//           bannerUrl,
//           picInsertEnabled,
//           templateType,
//           selectedTemplate
//         },
//         { merge: true }
//       );

//       setSnackbar({
//         open: true,
//         message: "設定を保存しました",
//         severity: "success",
//       });
//     } catch (error) {
//       console.error("ユーザー設定の保存中にエラー:", error);
//       setSnackbar({
//         open: true,
//         message: `設定の保存に失敗しました: ${error.message}`,
//         severity: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (!user) {
//     return <Typography>ログインしてください。</Typography>;
//   }

//   if (loading) {
//     return (
//       <Box sx={{ textAlign: "center", mt: 4 }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   const handleTabChange = (event, newValue) => {
//     setTemplateType(newValue === 0 ? "template" : "custom");
//   };

//   return (
//     <Paper sx={{ maxWidth: 700, margin: "0 auto", p: 3 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         アカウント設定
//       </Typography>

//       {/* ===== 基本情報 ===== */}
//       <Box sx={{ mb: 3 }}>
//         <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
//           基本情報
//         </Typography>
//         <Box sx={{ mb: 1 }}>
//           <Typography variant="body2" color="text.secondary">
//             メールアドレス
//           </Typography>
//           <Typography variant="body1">{user.email || "(未取得)"}</Typography>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <Typography variant="body2" color="text.secondary">
//             ユーザーID (UID)
//           </Typography>
//           <Typography variant="body1">{user.uid}</Typography>
//         </Box>
//       </Box>

//       {/* ===== バナー設定 ===== */}
//       <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
//         バナー設定
//       </Typography>

//       {/* テンプレート or カスタム をタブで切り替え */}
//       <Tabs
//         value={templateType === "template" ? 0 : 1}
//         onChange={handleTabChange}
//         sx={{ mb: 2 }}
//       >
//         <Tab label="テンプレート" />
//         <Tab label="カスタム" />
//       </Tabs>

//       {templateType === "template" && (
//         <Box sx={{ mb: 2 }}>
//           {/* テンプレートを選択 */}
//           <FormControl fullWidth>
//             <InputLabel>テンプレートを選択</InputLabel>
//             <Select
//               value={selectedTemplate}
//               label="テンプレートを選択"
//               onChange={(e) => setSelectedTemplate(e.target.value)}
//             >
//               <MenuItem value="modern">モダン</MenuItem>
//               <MenuItem value="professional">プロフェッショナル</MenuItem>
//               <MenuItem value="simple">シンプル</MenuItem>
//               <MenuItem value="minimalist">ミニマリスト</MenuItem>
//               <MenuItem value="elegant">エレガント</MenuItem>
//               <MenuItem value="sakuraUmbrella">さくらアンブレラ</MenuItem>
//             </Select>
//           </FormControl>
//         </Box>
//       )}

//       {templateType === "custom" && (
//         <Box sx={{ mb: 2 }}>
//           {/* カスタムテンプレート内容を入れる場合 */}
//           <Typography variant="body2" color="text.secondary">
//             ここにカスタムテンプレートの設定UIなどを追加
//           </Typography>
//         </Box>
//       )}

//       {/* バナー画像URL */}
//       <TextField
//         label="バナー画像URL"
//         fullWidth
//         value={bannerUrl}
//         onChange={(e) => setBannerUrl(e.target.value)}
//         placeholder="https://example.com/banner.jpg"
//         sx={{ mt: 2, mb: 3 }}
//       />

//       {/* ===== 表示設定 ===== */}
//       <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
//         表示設定
//       </Typography>

//       <FormControlLabel
//         control={
//           <Switch
//             checked={bannerEnabled}
//             onChange={(e) => setBannerEnabled(e.target.checked)}
//           />
//         }
//         label="バナー画像を先頭に表示"
//       />
//       <Typography variant="body2" color="text.secondary" sx={{ ml: 4, mb: 2 }}>
//         ページの最上部にバナー画像を表示します
//       </Typography>

//       <FormControlLabel
//         control={
//           <Switch
//             checked={picInsertEnabled}
//             onChange={(e) => setPicInsertEnabled(e.target.checked)}
//           />
//         }
//         label="複数商品画像をDescriptionに挿入"
//       />
//       <Typography variant="body2" color="text.secondary" sx={{ ml: 4, mb: 3 }}>
//         商品説明文に複数の画像を表示します
//       </Typography>

//       {/* ===== 設定を保存ボタン ===== */}
//       <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
//         <Button variant="contained" onClick={handleSave}>
//           設定を保存
//         </Button>

//         {/* ホームに戻る */}
//         <Button variant="outlined" color="secondary" onClick={() => navigate("/")}>
//           ホームへ戻る
//         </Button>
//       </Box>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           severity={snackbar.severity}
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           sx={{ width: "100%" }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }

// export default AccountSettings;




// // src/AccountSettings.js

// import React, { useState, useEffect } from "react";
// import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from "./firebaseConfig";
// import {
//   Box,
//   Typography,
//   Switch,
//   FormControlLabel,
//   TextField,
//   Button,
//   CircularProgress,
//   Snackbar,
//   Alert,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom"; // ★ 追加

// function AccountSettings({ user }) {
//   const [loading, setLoading] = useState(true);

//   // Firestoreから読み込むユーザー設定例
//   const [bannerEnabled, setBannerEnabled] = useState(false);
//   const [bannerUrl, setBannerUrl] = useState("");
//   const [picInsertEnabled, setPicInsertEnabled] = useState(false);

//   // Snackbar(通知)用ステート
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "info",
//   });

//   // ★ ホーム画面へ戻るナビゲーション
//   const navigate = useNavigate();

//   // Firestoreからユーザー設定を読み込む
//   useEffect(() => {
//     if (!user) return;

//     const fetchUserSettings = async () => {
//       try {
//         const docRef = doc(db, "users", user.uid);
//         const docSnap = await getDoc(docRef);
//         if (docSnap.exists()) {
//           const data = docSnap.data();
//           setBannerEnabled(data.bannerEnabled ?? false);
//           setBannerUrl(data.bannerUrl ?? "");
//           setPicInsertEnabled(data.picInsertEnabled ?? false);
//         }
//       } catch (error) {
//         console.error("ユーザー設定の読み込み中にエラー:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserSettings();
//   }, [user]);

//   // 「保存」ボタン押下時の処理
//   const handleSave = async () => {
//     if (!user) return;
//     setLoading(true);

//     try {
//       const docRef = doc(db, "users", user.uid);
//       await setDoc(
//         docRef,
//         {
//           bannerEnabled,
//           bannerUrl,
//           picInsertEnabled,
//         },
//         { merge: true } // 既存ドキュメントに上書き保存
//       );

//       setSnackbar({
//         open: true,
//         message: "設定を保存しました",
//         severity: "success",
//       });
//     } catch (error) {
//       console.error("ユーザー設定の保存中にエラー:", error);
//       setSnackbar({
//         open: true,
//         message: `設定の保存に失敗しました: ${error.message}`,
//         severity: "error",
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (!user) {
//     return <Typography>ログインしてください。</Typography>;
//   }

//   if (loading) {
//     return (
//       <Box sx={{ textAlign: "center", mt: 4 }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ maxWidth: 600, margin: "0 auto", p: 2 }}>
//       <Typography variant="h5" sx={{ mb: 2 }}>
//         アカウント設定
//       </Typography>

//       {/* === ここからユーザー情報を表示 === */}
//       <Box sx={{ mb: 2 }}>
//         <Typography variant="body1">
//           <strong>メールアドレス:</strong> {user.email || "(未取得)"}
//         </Typography>
//         <Typography variant="body1">
//           <strong>ユーザーID (UID):</strong> {user.uid}
//         </Typography>
//       </Box>
//       {/* === ここまでユーザー情報を表示 === */}

//       {/* バナーON/OFF */}
//       <FormControlLabel
//         control={
//           <Switch
//             checked={bannerEnabled}
//             onChange={(e) => setBannerEnabled(e.target.checked)}
//           />
//         }
//         label="バナー画像を先頭に表示"
//       />

//       {/* バナー画像URL */}
//       {bannerEnabled && (
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             label="バナー画像URL"
//             fullWidth
//             value={bannerUrl}
//             onChange={(e) => setBannerUrl(e.target.value)}
//             sx={{ mt: 2 }}
//           />
//         </Box>
//       )}

//       {/* 画像挿入ON/OFF */}
//       <FormControlLabel
//         control={
//           <Switch
//             checked={picInsertEnabled}
//             onChange={(e) => setPicInsertEnabled(e.target.checked)}
//           />
//         }
//         label="複数商品画像をDescriptionに挿入"
//       />

//       {/* ボタンコンテナ */}
//       <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
//         <Button variant="contained" onClick={handleSave}>
//           設定を保存
//         </Button>

//         {/* ホームに戻る */}
//         <Button variant="outlined" color="secondary" onClick={() => navigate("/")}>
//           ホームへ戻る
//         </Button>
//       </Box>

//       {/* Snackbarによる通知 */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert
//           severity={snackbar.severity}
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           sx={{ width: "100%" }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// }

// export default AccountSettings;
