// LowCostAIGenerator.js

import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import designTemplates from './designTemplates';

const BATCH_SIZE = 5;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 5;

// generateProductFeatures 関数をコンポーネントの外側で定義し、名前付きエクスポート
export const generateProductFeatures = async ({
  products,
  setProducts,
  selectedProducts,
  apiKey,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  setError,
  setMessage,
  setProgress,
  queue,
  selectedTemplate,
  applyCustomTransformations,
  sanitizeDescription,
  mandatoryKeywords, // 追加
}) => {
  let processedCount = 0;

  const selectedProductsData = products.filter(
    (product) => selectedProducts.includes(product.id) && !product.isUpdated // 未更新の商品に限定
  );

  if (selectedProductsData.length === 0) {
    setMessage('選択された商品はすでに更新されています');
    setSnackbarMessage('選択された商品はすでに更新されています');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
    return;
  }

  for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
    const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
    try {
      const updatedBatch = await processBatch(batch);
      setProducts((prevProducts) => {
        const newProducts = [...prevProducts];
        updatedBatch.forEach((updatedProduct) => {
          const index = newProducts.findIndex((p) => p.id === updatedProduct.id);
          if (index !== -1) {
            newProducts[index] = updatedProduct;
          }
        });
        return newProducts;
      });
      processedCount += batch.length;
      setProgress((processedCount / selectedProductsData.length) * 100);
    } catch (err) {
      console.error('バッチ処理エラー:', err);
      setError(`バッチ処理に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      throw err; // エラーが発生した場合は処理を中断
    }
  }

  setMessage('低コストAIでの商品特徴の生成が完了しました');
  setSnackbarMessage('低コストAIでの商品特徴の生成が完了しました');
  setSnackbarSeverity('success');
  setSnackbarOpen(true);

  // 内部関数: バッチ処理
  async function processBatch(batch) {
    return await Promise.all(
      batch.map(async (product) => {
        let generatedFeatures;

        // APIコールを行い、説明を生成
        generatedFeatures = await generateFeaturesWithRetry(product.editableJpDesc, 0, mandatoryKeywords);

        // 必要ならカスタム変換を適用
        if (applyCustomTransformations) {
          generatedFeatures = applyCustomTransformations(generatedFeatures);
        }

        const sanitizedDescription = sanitizeDescription(product.originalDescription);

        const updatedDescription = generateFormattedDescription(
          product.title,
          generatedFeatures,
          sanitizedDescription,
          product.specifications
        );

        return {
          ...product,
          generatedFeatures,
          editableGeneratedFeatures: generatedFeatures,
          updatedDescription,
          isUpdated: true,
        };
      })
    );
  }

  // 内部関数: リトライ付きの特徴生成
  async function generateFeaturesWithRetry(jpDesc, retryCount = 0, mandatoryKeywords) {
    try {
      return await queue.add(() => generateFeatures(jpDesc, 200, mandatoryKeywords));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter
          ? parseInt(retryAfter) * 1000
          : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(jpDesc, retryCount + 1, mandatoryKeywords);
      }
      throw error;
    }
  }

  // 内部関数: 特徴生成
  async function generateFeatures(jpDesc, maxTokens = 200, mandatoryKeywords = []) {
    // `mandatoryKeywords`を`user`メッセージに組み込む
    const mandatoryKeywordsPrompt =
      mandatoryKeywords.length > 0
        ? `\n必ず次のキーワードを含めてください: ${mandatoryKeywords.join(', ')}。`
        : '';

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini', // 低コストのモデルを使用
        messages: [
          {
            role: 'system',
            content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

1. product description (if applicable)
   - Brand name (if applicable)
   - Product type
   - Model or product name
2. detailed features (if applicable)
   - Color, material, size
3. condition of the item (if applicable)
   - Details of usage, scratches, stains, etc. (if used)

Notes:
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information
- If there is nothing in the Japanese product description, please do not output anything in particular. Only the title is acceptable.

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`,
          },
          {
            role: 'user',
            content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」${mandatoryKeywordsPrompt}`,
          },
        ],
        max_tokens: maxTokens,
        temperature: 0.1,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  }

  // 内部関数: フォーマットされた説明を生成
  function generateFormattedDescription(title, features, description, specifications) {
    const template = designTemplates[selectedTemplate];
    const html = template.generateHTML(title, features, description, specifications);
    return cleanHTML(html);
  }

  // 内部関数: HTMLをクリーンアップ
  function cleanHTML(html) {
    let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
    cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
    cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
    cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
    return cleanedHtml;
  }
};

// LowCostAIGenerator コンポーネント
const LowCostAIGenerator = ({
  open,
  handleClose,
  loading,
  setLoading,
  error, // 追加
  setError,
  message, // 追加
  setMessage,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  products,
  selectedProducts,
  apiKey,
  setProducts,
  setProgress,
  progress,
  queue,
  selectedTemplate,
  applyCustomTransformations,
  sanitizeDescription,
  mandatoryKeywords, // 追加
}) => {
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const handleGenerate = async () => {
    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    try {
      await generateProductFeatures({
        products,
        setProducts,
        selectedProducts,
        apiKey,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        setError,
        setMessage,
        setProgress,
        queue,
        selectedTemplate,
        applyCustomTransformations,
        sanitizeDescription,
        mandatoryKeywords, // 追加
      });
      setShowPreviewDialog(true);
    } catch (error) {
      console.error('低コストAI生成エラー:', error);
      setError(`低コストAI生成に失敗しました: ${error.message}`);
      setSnackbarMessage(`エラー: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewClose = () => {
    setShowPreviewDialog(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogTitle>低コストAI生成</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            低コストのAIモデルを使用して商品説明を生成します。精度は若干低下しますが、コストを抑えることができます。
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            disabled={loading || !apiKey || selectedProducts.length === 0}
            startIcon={<MoneyOffIcon />}
          >
            低コストAIで商品説明を生成する
          </Button>
          {/* 進捗状況表示 */}
          {loading && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="text.secondary" align="center">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
          {/* エラーメッセージ */}
          {error && (
            <Typography color="error" style={{ marginTop: '20px' }}>
              {error}
            </Typography>
          )}
          {/* 成功メッセージ */}
          {message && (
            <Typography color="primary" style={{ marginTop: '20px' }}>
              {message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* プレビューダイアログ */}
      <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
        <DialogTitle>商品説明のプレビュー</DialogTitle>
        <DialogContent>
          {/* ここにプレビュー内容を表示するコードを追加 */}
          <Typography>商品説明の生成が完了しました。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// LowCostAIGenerator コンポーネントをデフォルトエクスポート
export default LowCostAIGenerator;