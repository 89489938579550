// OpenAIDescriptionDialog.js

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  LinearProgress,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  Pagination,
  Snackbar,
  Alert,
  RadioGroup,
  Radio,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import OptionsPanel from "./OptionsPanel";
import DescriptionValidator from "./DescriptionValidator";
import designTemplates from "./designTemplates";
import DescriptionCustomPrompt from "./DescriptionCustomPrompt";
import ResetConfirmationDialog from "./ResetConfirmationDialog";

import { db } from "./firebaseConfig";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";

import { generateItemSpecificsFeatures } from "./ItemSpecificsGenerator";
import {
  generateFormattedDescription,
  sanitizeDescription,
} from "./OpenAIDescriptionGenerator";

const OpenAIDescriptionDialog = ({
  open,
  handleClose,
  products,
  setProducts,
  data,
  setData,
  apiKey,
  selectedProducts,
  setSelectedProducts,
  selectedTemplate,
  setSelectedTemplate,
  loading,
  setLoading,
  error,
  setError,
  message,
  setMessage,
  generateProductFeatures,
  applyUpdatedDescriptions,
  handleSelectAll,
  handleSelectProduct,
  handleFocusProduct,
  focusedProduct,
  setFocusedProduct,
  handleEditJpDesc,
  handleEditDescription,
  handleEditFeatures,
  handlePreviewOpen,
  currentProducts,
  itemsPerPage,
  setItemsPerPage,
  itemsPerPageOptions,
  currentPage,
  setCurrentPage,
  handleSelectAllOnPage,
  applyCustomTransformations,
  sentencesToDelete,
  setSentencesToDelete,
  wordsToReplace,
  setWordsToReplace,
  selectedFilters,
  setSelectedFilters,
  handleFilterChange,
  customFilterInput,
  setCustomFilterInput,
  customFilters,
  setCustomFilters,
  handleAddCustomFilter,
  handleDeleteCustomFilter,
  progress,
  lowCostLoading,
  handleLowCostAIGeneration,
  setCustomPromptDialogOpen,
  customPromptDialogOpen,
  setResetDialogOpen,
  resetDialogOpen,
  resetSelectedProductsToInitialCSVData,
  queue,
  applyTransformationsToSelectedProducts,
  clearCache,
  previewProduct,
  setPreviewProduct,
  showPreviewDialog,
  setShowPreviewDialog,
  handlePreviewClose,
  snackbarOpen,
  setSnackbarOpen,
  snackbarMessage,
  snackbarSeverity,
  setSnackbarMessage,
  setSnackbarSeverity,
  setProgress,
  mandatoryKeywords,
  setMandatoryKeywords,
  user,
  useImageDescription,
  setUseImageDescription,
}) => {
  const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState("");

  const handleAddMandatoryKeyword = () => {
    if (mandatoryKeywordInput.trim() !== "") {
      setMandatoryKeywords([
        ...mandatoryKeywords,
        mandatoryKeywordInput.trim(),
      ]);
      setMandatoryKeywordInput("");
    }
  };

  const handleDeleteMandatoryKeyword = (index) => {
    const updatedKeywords = [...mandatoryKeywords];
    updatedKeywords.splice(index, 1);
    setMandatoryKeywords(updatedKeywords);
  };

  // テンプレート管理の状態
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] =
    useState(false);
  const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] =
    useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [currentTemplateName, setCurrentTemplateName] = useState("");
  const [saveOption, setSaveOption] = useState("new");

  // テンプレートの保存
  const saveTemplate = async () => {
    if (!templateName.trim()) {
      setSnackbarMessage("テンプレート名を入力してください");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    const templateData = {
      name: templateName.trim(),
      customFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedFilters,
    };

    try {
      const userTemplatesRef = collection(db, "users", user.uid, "templates");

      if (saveOption === "overwrite" && selectedTemplateId) {
        const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
        await setDoc(templateDocRef, templateData);
        setSnackbarMessage("テンプレートが更新されました");
      } else {
        await addDoc(userTemplatesRef, templateData);
        setSnackbarMessage("新しいテンプレートが保存されました");
      }

      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setIsSaveTemplateDialogOpen(false);
      setTemplateName("");
      loadTemplates();
    } catch (error) {
      console.error("テンプレートの保存中にエラーが発生しました:", error);
      setSnackbarMessage("テンプレートの保存中にエラーが発生しました");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // テンプレートの読み込み
  const loadTemplates = async () => {
    try {
      const userTemplatesRef = collection(db, "users", user.uid, "templates");
      const templatesSnapshot = await getDocs(userTemplatesRef);
      const templatesData = templatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(templatesData);
    } catch (error) {
      console.error("テンプレートの読み込み中にエラーが発生しました:", error);
    }
  };

  useEffect(() => {
    if (user && user.uid) {
      loadTemplates();
    }
  }, [user]);

  // テンプレートの適用
  const handleLoadTemplate = async (templateId) => {
    if (!templateId) {
      setSelectedTemplateId("");
      setTemplateName("");
      setCurrentTemplateName("");
      return;
    }
    setSelectedTemplateId(templateId);
    try {
      const templateDocRef = doc(
        db,
        "users",
        user.uid,
        "templates",
        templateId
      );
      const templateDoc = await getDoc(templateDocRef);
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        setTemplateName(templateData.name || "");
        setCurrentTemplateName(templateData.name || "");
        setCustomFilters(templateData.customFilters || []);
        setSentencesToDelete(templateData.sentencesToDelete || []);
        setWordsToReplace(templateData.wordsToReplace || []);
        setMandatoryKeywords(templateData.mandatoryKeywords || []);
        setSelectedFilters(templateData.selectedFilters || []);
        setSnackbarMessage("テンプレートが適用されました");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage("テンプレートが見つかりません");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("テンプレートの読み込み中にエラーが発生しました:", error);
      setSnackbarMessage("テンプレートの読み込み中にエラーが発生しました");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // テンプレートの削除
  const handleDeleteTemplate = async (templateId) => {
    try {
      const templateDocRef = doc(
        db,
        "users",
        user.uid,
        "templates",
        templateId
      );
      await deleteDoc(templateDocRef);
      setSnackbarMessage("テンプレートが削除されました");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      loadTemplates();
    } catch (error) {
      console.error("テンプレートの削除中にエラーが発生しました:", error);
      setSnackbarMessage("テンプレートの削除中にエラーが発生しました");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // 新しいテンプレートを作成
  const handleCreateNewTemplate = () => {
    setSelectedTemplateId("");
    setTemplateName("");
    setCurrentTemplateName("");
    setCustomFilters([]);
    setSelectedFilters([]);
    setSentencesToDelete([]);
    setWordsToReplace([]);
    setMandatoryKeywords([]);
    setSnackbarMessage("新しいテンプレートを作成します");
    setSnackbarSeverity("info");
    setSnackbarOpen(true);
  };

  // itemspecifics専用ボタンのクリックハンドラー
  const handleItemSpecificsGeneration = async () => {
    if (!apiKey) {
      setError("OpenAI APIキーが設定されていません");
      setSnackbarMessage("OpenAI APIキーが必要です");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError("少なくとも一つの商品を選択してください");
      setSnackbarMessage("商品が選択されていません");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");
    setProgress(0);

    try {
      await generateItemSpecificsFeatures({
        products,
        setProducts,
        selectedProducts,
        apiKey,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        setError,
        setMessage,
        setProgress,
        queue,
        selectedTemplate,
        generateFormattedDescription,
        sanitizeDescription,
      });
      setShowPreviewDialog(true);
    } catch (error) {
      console.error("itemspecifics専用生成エラー:", error);
      setError(`itemspecifics専用生成に失敗しました: ${error.message}`);
      setSnackbarMessage(`エラー: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>AIで商品説明を生成する</DialogTitle>
      <DialogContent>
        {/* レイアウトを左右に分割 */}
        <Grid container spacing={2}>
          {/* 左側のセクション */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              maxHeight: "calc(100vh - 32px)",
              overflowY: "auto",
            }}
          >
            {/* テンプレート管理のUI */}
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Typography variant="subtitle1">テンプレートの管理</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsSaveTemplateDialogOpen(true);
                  setSaveOption(selectedTemplateId ? "overwrite" : "new");
                }}
              >
                テンプレートとして保存
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsManageTemplatesDialogOpen(true)}
                style={{ marginLeft: "10px" }}
              >
                テンプレートを管理
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCreateNewTemplate}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              >
                新しいテンプレートを作成
              </Button>

              {/* テンプレート選択 */}
              <FormControl fullWidth style={{ marginTop: "10px" }}>
                <InputLabel>テンプレートを選択</InputLabel>
                <Select
                  value={selectedTemplateId}
                  onChange={(e) => handleLoadTemplate(e.target.value)}
                  label="テンプレートを選択"
                >
                  <MenuItem value="">
                    <em>テンプレートを選択</em>
                  </MenuItem>
                  {templates.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* トグルスイッチ */}
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={useImageDescription}
                    onChange={(e) => setUseImageDescription(e.target.checked)}
                    name="useImageDescription"
                    color="primary"
                  />
                }
                label="画像解析の説明を使用"
              />
            </Grid>

            {/* OptionsPanel */}
            <OptionsPanel
              selectedFilters={selectedFilters}
              handleFilterChange={handleFilterChange}
              customFilterInput={customFilterInput}
              setCustomFilterInput={setCustomFilterInput}
              customFilters={customFilters}
              handleAddCustomFilter={handleAddCustomFilter}
              handleDeleteCustomFilter={handleDeleteCustomFilter}
              sentencesToDelete={sentencesToDelete}
              setSentencesToDelete={setSentencesToDelete}
              wordsToReplace={wordsToReplace}
              setWordsToReplace={setWordsToReplace}
            />

            {/* 必須キーワード */}
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Typography variant="subtitle1">
                必ず含めたいキーワード
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <TextField
                  label="キーワードを入力"
                  value={mandatoryKeywordInput}
                  onChange={(e) => setMandatoryKeywordInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMandatoryKeyword}
                  sx={{ ml: 1, height: "56px" }}
                >
                  追加
                </Button>
              </Box>
              <List>
                {mandatoryKeywords.map((keyword, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteMandatoryKeyword(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={keyword} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            {/* 選択オプション */}
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedProducts.length === products.length}
                      onChange={handleSelectAll}
                      indeterminate={
                        selectedProducts.length > 0 &&
                        selectedProducts.length < products.length
                      }
                    />
                  }
                  label="全商品の選択"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentProducts.every(
                        (product) => product.selected
                      )}
                      onChange={handleSelectAllOnPage}
                      indeterminate={
                        currentProducts.some((product) => product.selected) &&
                        !currentProducts.every((product) => product.selected)
                      }
                    />
                  }
                  label="表示されている商品の一括選択"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>デザインテンプレートを選択</InputLabel>
                  <Select
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                    fullWidth
                    label="デザインテンプレートを選択"
                  >
                    {Object.entries(designTemplates).map(([key, template]) => (
                      <MenuItem key={key} value={key}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* 表示件数選択 */}
              <Grid item xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="items-per-page-label">表示件数</InputLabel>
                  <Select
                    labelId="items-per-page-label"
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(e.target.value);
                      setCurrentPage(1);
                    }}
                    label="表示件数"
                  >
                    {itemsPerPageOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option === "All" ? "全て" : `${option}件ずつ`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* 商品リスト */}
            <List>
              {currentProducts.map((product) => (
                <ListItem
                  key={product.id}
                  button
                  onClick={() => handleFocusProduct(product.id)}
                  selected={focusedProduct === product.id}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={product.selected}
                      tabIndex={-1}
                      disableRipple
                      onChange={() => handleSelectProduct(product.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={product.title}
                    secondary={product.isUpdated ? "更新済み" : ""}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handlePreviewOpen(product)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            {/* ページネーション */}
            {itemsPerPage !== "All" && (
              <Pagination
                count={Math.ceil(products.length / itemsPerPage)}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="primary"
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            )}
          </Grid>

          {/* 右側のセクション */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              maxHeight: "calc(100vh - 32px)",
              overflowY: "auto",
            }}
          >
            {/* アクションボタン */}
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateProductFeatures}
                  disabled={loading || !apiKey || selectedProducts.length === 0}
                >
                  自動itemspecifics生成用
                </Button>
              </Grid>
              {/* 低コストAI生成ボタン */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLowCostAIGeneration}
                  startIcon={<MoneyOffIcon />}
                  disabled={
                    lowCostLoading || !apiKey || selectedProducts.length === 0
                  }
                >
                  低コストAI生成をする
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={applyUpdatedDescriptions}
                  disabled={loading || selectedProducts.length === 0}
                >
                  商品説明をデータに更新する
                </Button>
              </Grid>

              {/* カスタムプロンプトボタン */}
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setCustomPromptDialogOpen(true)}
                  startIcon={<EditIcon />}
                  disabled={loading || selectedProducts.length === 0}
                >
                  カスタムプロンプトで生成
                </Button>
              </Grid>
              {/* 変換を適用するボタン */}
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={applyTransformationsToSelectedProducts}
                  disabled={loading || selectedProducts.length === 0}
                >
                  変換を適用
                </Button>
              </Grid>
              {/* 初期値にリセットボタン */}
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setResetDialogOpen(true)}
                  startIcon={<ReplayIcon />}
                  disabled={loading}
                >
                  初期値にリセット
                </Button>
              </Grid>
              {/* itemspecifics専用ボタン */}
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleItemSpecificsGeneration}
                  startIcon={<EditIcon />}
                  disabled={loading || selectedProducts.length === 0}
                >
                  日本語整理→英語AI(2段階処理)
                </Button>
              </Grid>
            </Grid>

            {/* 進捗状況表示 */}
            {(loading || lowCostLoading) && (
              <Box sx={{ width: "100%", mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            )}

            {/* エラーメッセージ */}
            {error && (
              <Typography color="error" style={{ marginTop: "20px" }}>
                {error}
              </Typography>
            )}
            {/* 成功メッセージ */}
            {message && (
              <Typography color="primary" style={{ marginTop: "20px" }}>
                {message}
              </Typography>
            )}

            {/* 選択された商品の詳細情報 */}
            {focusedProduct !== null ? (
              (() => {
                const product = products.find((p) => p.id === focusedProduct);
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{product.title}</Typography>
                    </Grid>
                    {/* 編集可能なjpDescフィールド */}
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        参照する日本語の商品説明
                      </Typography>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={product.editableJpDesc}
                        onChange={(e) =>
                          handleEditJpDesc(product.id, e.target.value)
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        最終的なdescriptionに入るHTML
                      </Typography>
                      <TextField
                        multiline
                        rows={10}
                        fullWidth
                        variant="outlined"
                        value={
                          product.updatedDescription ||
                          product.editableDescription ||
                          product.description
                        }
                        onChange={(e) =>
                          handleEditDescription(product.id, e.target.value)
                        }
                      />
                    </Grid>

                    {/* 生成されたFeatures */}
                    {product.generatedFeatures && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          AI生成された商品説明
                        </Typography>
                        <TextField
                          multiline
                          rows={8}
                          fullWidth
                          variant="outlined"
                          value={product.editableGeneratedFeatures}
                          onChange={(e) =>
                            handleEditFeatures(product.id, e.target.value)
                          }
                        />
                      </Grid>
                    )}
                    {/* 更新されたDescription */}
                    {product.updatedDescription && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            アップデートされるDescriptionプレビュー
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.updatedDescription,
                            }}
                          />
                        </Grid>
                        {/* DescriptionValidator */}
                        <Grid item xs={12}>
                          <DescriptionValidator
                            htmlContent={
                              product.updatedDescription || product.description
                            }
                            onUpdate={(updatedHtml) => {
                              setProducts((prevProducts) =>
                                prevProducts.map((p) =>
                                  p.id === product.id
                                    ? { ...p, updatedDescription: updatedHtml }
                                    : p
                                )
                              );
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                );
              })()
            ) : (
              <Typography variant="body1">商品を選択してください</Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>閉じる</Button>
      </DialogActions>

      {/* カスタムプロンプトダイアログ */}
      <DescriptionCustomPrompt
        open={customPromptDialogOpen}
        handleClose={() => setCustomPromptDialogOpen(false)}
        products={products}
        setProducts={setProducts}
        selectedProducts={selectedProducts}
        apiKey={apiKey}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarOpen={setSnackbarOpen}
        loading={loading}
        setLoading={setLoading}
        setError={setError}
        setMessage={setMessage}
        setProgress={setProgress}
        queue={queue}
        selectedTemplate={selectedTemplate}
        designTemplates={designTemplates}
        applyCustomTransformations={applyCustomTransformations}
        progress={progress}
        mandatoryKeywords={mandatoryKeywords}
      />

      {/* リセット確認ダイアログ */}
      <ResetConfirmationDialog
        open={resetDialogOpen}
        handleClose={() => setResetDialogOpen(false)}
        handleConfirm={() => {
          resetSelectedProductsToInitialCSVData();
          setResetDialogOpen(false);
        }}
        selectedCount={selectedProducts.length}
      />

      {/* プレビューダイアログ */}
      <Dialog
        open={showPreviewDialog}
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>eBay Preview</DialogTitle>
        <DialogContent>
          {previewProduct ? (
            <div
              dangerouslySetInnerHTML={{
                __html: previewProduct.updatedDescription,
              }}
            />
          ) : (
            "商品説明の生成が完了しました"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート保存ダイアログ */}
      <Dialog
        open={isSaveTemplateDialogOpen}
        onClose={() => setIsSaveTemplateDialogOpen(false)}
      >
        <DialogTitle>テンプレートの保存</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={saveOption}
              onChange={(e) => setSaveOption(e.target.value)}
            >
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="新しいテンプレートとして保存"
              />
              {selectedTemplateId && (
                <FormControlLabel
                  value="overwrite"
                  control={<Radio />}
                  label="既存のテンプレートを上書き保存"
                />
              )}
            </RadioGroup>
          </FormControl>
          <TextField
            label="テンプレート名"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            fullWidth
            style={{ marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>
            キャンセル
          </Button>
          <Button onClick={saveTemplate} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート管理ダイアログ */}
      <Dialog
        open={isManageTemplatesDialogOpen}
        onClose={() => setIsManageTemplatesDialogOpen(false)}
      >
        <DialogTitle>テンプレートの管理</DialogTitle>
        <DialogContent>
          <List>
            {templates.map((template) => (
              <ListItem key={template.id}>
                <ListItemText primary={template.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteTemplate(template.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* スナックバー通知 */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default OpenAIDescriptionDialog;