// designTemplates.js

const designTemplates = {
  // ================== SIMPLE ==================
  simple: {
    name: 'Simple',
    generateHTML: (title, features, description, specifications, userSettings) => {
      // バナーHTML
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner" 
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      // 複数画像HTML (1行最大8枚)
      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => {
              return `
                <td style="padding:5px;">
                  <img 
                    src="${url}"
                    alt="Product Image"
                    style="max-width:120px; height:auto;"
                  />
                </td>
              `;
            })
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="display: flex; flex-wrap: wrap;">
          ${bannerHtml}
          ${picHtml}
          <div style="flex: 1; min-width: 300px; padding: 10px;">
            <h1>${title}</h1>
            <h2>Features</h2>
            <ul>
              ${features
                .split('\n')
                .map(feature => `<li>${feature}</li>`)
                .join('')}
            </ul>
          </div>
          <div style="flex: 1; min-width: 300px; padding: 10px;">
            <h2>Specifications</h2>
            <table>
              ${Object.entries(specifications)
                .filter(([key, value]) => value !== 'NA' && value !== '')
                .map(
                  ([key, value]) => `
                    <tr>
                      <td>${key.replace(/^C:/, '')}</td>
                      <td>${value}</td>
                    </tr>
                  `
                )
                .join('')}
            </table>
          </div>
        </div>
        <div style="margin-top: 20px;">
          ${description}
        </div>
      `;
    },
  },

  // ================== PROFESSIONAL ==================
  professional: {
    name: 'Professional',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }

        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content">
          <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
            ${bannerHtml}
            ${picHtml}
            <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">
              ${title}
            </h1>
            <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
              <div style="flex: 1; min-width: 300px; padding: 10px;">
                <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
                <ul style="list-style-type: disc; padding-left: 20px;">
                  ${features
                    .split('\n')
                    .map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`)
                    .join('')}
                </ul>
              </div>
              <div style="flex: 1; min-width: 300px; padding: 10px;">
                <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
                <table style="width: 100%; border-collapse: collapse;">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(
                      ([key, value]) => `
                        <tr>
                          <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
                            ${key.replace(/^C:/, '')}
                          </td>
                          <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
                            ${value}
                          </td>
                        </tr>
                      `
                    )
                    .join('')}
                </table>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
              <p style="line-height: 1.6;">
                ${description}
              </p>
            </div>  
          </div>
        </div>
      `;
    },
  },

  // ================== MODERN ==================
  modern: {
    name: 'Modern',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner" 
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content">
          <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
            ${bannerHtml}
            ${picHtml}
            <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">
              ${title}
            </h1>
            <div style="margin-bottom: 30px;">
              <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
              <ul style="list-style-type: disc; padding-left: 20px;">
                ${features
                  .split('\n')
                  .map(feature => `<li style="margin-bottom: 12px;">${feature}</li>`)
                  .join('')}
              </ul>
            </div>
            <div style="margin-bottom: 30px;">
              <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(
                    ([key, value]) => `
                      <tr>
                        <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
                          ${key.replace(/^C:/, '')}
                        </td>
                        <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
                          ${value}
                        </td>
                      </tr>
                    `
                  )
                  .join('')}
              </table>
            </div>
            <div style="margin-bottom: 30px;">
              <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
              <p style="line-height: 1.7;">
                ${description}
              </p>
            </div>
          </div>
        </div>
      `;
    },
  },

  // ================== ELEGANT ==================
  elegant: {
    name: 'Elegant',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin:0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content">
          <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
            ${bannerHtml}
            ${picHtml}
            <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">
              ${title}
            </h1>
            <div style="margin-bottom: 40px;">
              <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
              <ul style="list-style-type: disc; padding-left: 20px;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 15px;">${feature}</li>`).join('')}
              </ul>
            </div>
            <div style="margin-bottom: 40px;">
              <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
                      <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">
                        ${key.replace(/^C:/, '')}
                      </td>
                      <td style="padding: 12px; border: 1px solid #ecc94b;">
                        ${value}
                      </td>
                    </tr>
                  `).join('')}
              </table>
            </div>
            <div style="margin-bottom: 40px;">
              <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
              <p style="line-height: 1.8; text-align: justify;">${description}</p>
            </div>
          </div>
        </div>
      `;
    },
  },

  // ================== MINIMALIST ==================
  minimalist: {
    name: 'Minimalist',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img
              src="${userSettings.bannerUrl}"
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin:0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content">
          <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
            ${bannerHtml}
            ${picHtml}

            <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">
              ${title}
            </h1>
            <div>
              <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
              <ul style="list-style-type: disc; padding-left: 20px;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
              </ul>
            </div>
            <div style="margin-top: 30px;">
              <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 8px; border-bottom: 1px solid #ccc;">
                        ${key.replace(/^C:/, '')}
                      </td>
                      <td style="padding: 8px; border-bottom: 1px solid #ccc;">
                        ${value}
                      </td>
                    </tr>
                  `)
                  .join('')}
              </table>
            </div>
            <div style="margin-top: 30px;">
              <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
              <p style="line-height: 1.6;">${description}</p>
            </div>
          </div>
        </div>
      `;
    },
  },

  // ================== SAKURA UMBRELLA ==================
  sakuraUmbrella: {
    name: 'SakuraUmbrella',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img
              src="${userSettings.bannerUrl}"
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin:0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="
          font-family: 'Trebuchet MS', sans-serif;
          color: #fff;
          background: linear-gradient(60deg, #d0021b, #35a79c, #ef5b5b);
          background-size: 180% 180%;
          animation: gradientBG 10s ease infinite;
          padding: 30px;
        ">
          <style>
            @keyframes gradientBG {
              0% {
                background-position: 0% 50%;
              }
              50% {
                background-position: 100% 50%;
              }
              100% {
                background-position: 0% 50%;
              }
            }
          </style>
          <div style="
            max-width: 1000px;
            margin: 0 auto;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 20px;
            box-shadow: 0 0 20px rgba(0,0,0,0.3);
          ">
            ${bannerHtml}
            ${picHtml}
            <h1 style="
              font-size: 36px;
              text-align: center;
              margin-bottom: 30px;
              text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            ">
              ${title}
            </h1>
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <!-- Features -->
              <div style="
                flex: 1;
                min-width: 300px;
                background-color: rgba(255,255,255,0.2);
                padding: 20px;
                border-radius: 8px;
              ">
                <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
                  Key Features
                </h2>
                <ul style="list-style-type: none; padding: 0;">
                  ${features
                    .split('\n')
                    .map(feature => `
                      <li style="
                        margin-bottom: 10px;
                        padding: 8px;
                        background-color: rgba(0,0,0,0.2);
                        border-radius: 4px;
                      ">
                        ${feature}
                      </li>
                    `)
                    .join('')}
                </ul>
              </div>
              <!-- Specifications -->
              <div style="
                flex: 1;
                min-width: 300px;
                background-color: rgba(255,255,255,0.2);
                padding: 20px;
                border-radius: 8px;
              ">
                <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
                  Specifications
                </h2>
                <table style="
                  width: 100%;
                  border-collapse: collapse;
                  background-color: rgba(255,255,255,0.1);
                  border-radius: 4px;
                ">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(([key, value], index) => `
                      <tr style="
                        background-color: ${
                          index % 2 === 0 
                            ? 'rgba(255,255,255,0.15)' 
                            : 'rgba(255,255,255,0.3)'
                        };
                      ">
                        <td style="
                          padding: 8px;
                          font-weight: bold;
                          border: 1px solid rgba(255,255,255,0.2);
                        ">
                          ${key.replace(/^C:/, '')}
                        </td>
                        <td style="
                          padding: 8px;
                          border: 1px solid rgba(255,255,255,0.2);
                        ">
                          ${value}
                        </td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </div>
            <!-- Product Description -->
            <div style="
              background-color: rgba(255,255,255,0.15);
              margin-top: 30px;
              padding: 20px;
              border-radius: 8px;
            ">
              <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
                Product Description
              </h2>
              <p style="line-height: 1.6; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">
                ${description}
              </p>
            </div>
          </div>
        </div>
      `;
    },
  },

  // ================== ここから追加したいデザイン（バナー画像・商品画像対応版） ==================
  ninagawaStyle: {
    name: 'NinagawaStyle',
    generateHTML: (title, features, description, specifications, userSettings) => {
      // バナーHTML
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner" 
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      // 複数画像HTML (1行最大8枚)
      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="font-family: 'Trebuchet MS', sans-serif; padding: 20px; background: linear-gradient(45deg, #ff8ec7, #ffd747); color: #fff;">
          <div style="max-width: 1000px; margin: 0 auto;">
            ${bannerHtml}
            ${picHtml}
            <h1 style="font-size: 36px; font-weight: bold; text-align: center; margin-bottom: 30px; text-shadow: 2px 2px #ff4791;">
              ${title}
            </h1>
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.2); padding: 20px; border-radius: 10px;">
                <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Key Features</h2>
                <ul style="list-style: none; padding: 0;">
                  ${features.split('\n').map(feature => `
                    <li style="margin-bottom: 10px; background-color: rgba(255,255,255,0.15); padding: 8px; border-radius: 5px;">
                      <span style="color:#fff;">${feature}</span>
                    </li>
                  `).join('')}
                </ul>
              </div>

              <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.2); padding: 20px; border-radius: 10px;">
                <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Specifications</h2>
                <table style="width: 100%; border-collapse: collapse;">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(([key, value], index) => `
                      <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)'};">
                        <td style="padding: 8px; font-weight: bold; color: #fff;">${key.replace(/^C:/, '')}</td>
                        <td style="padding: 8px; color: #fffee0;">${value}</td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </div>

            <div style="background-color: rgba(255,255,255,0.15); margin-top: 30px; padding: 20px; border-radius: 10px;">
              <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Product Description</h2>
              <p style="line-height: 1.8;">${description}</p>
            </div>
          </div>
        </div>
      `;
    },
  },

  kyaryPop: {
    name: 'KyaryPop',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img
              src="${userSettings.bannerUrl}"
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; border: 2px dotted #ff1493; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="font-family: 'Comic Sans MS', cursive, sans-serif; background: repeating-linear-gradient(-45deg, #ffa6e6, #ffa6e6 20px, #a6f9ff 20px, #a6f9ff 40px); padding: 30px; color: #333;">
          <div style="max-width: 1000px; margin: 0 auto; background-color: rgba(255, 255, 255, 0.8); border-radius: 10px; padding: 20px;">
            ${bannerHtml}
            ${picHtml}

            <h1 style="text-align: center; font-size: 32px; margin-bottom: 30px; color: #ff1493; text-shadow: 2px 2px #fdfd96;">
              ${title}
            </h1>

            <div style="margin-bottom: 30px;">
              <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Features</h2>
              <ul style="padding-left: 20px;">
                ${features.split('\n').map(feature => `
                  <li style="margin-bottom: 10px; list-style: '⭐';">${feature}</li>
                `).join('')}
              </ul>
            </div>

            <div style="margin-bottom: 30px;">
              <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse; border: 2px dotted #ff1493;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#ffd1ec' : '#d1faff'};">
                      <td style="padding: 10px; border: 1px dashed #ff1493; font-weight: bold;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 10px; border: 1px dashed #ff1493;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </div>

            <div style="margin-bottom: 30px;">
              <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Description</h2>
              <p style="line-height: 1.6; background-color: #fff; padding: 10px; border-radius: 5px;">
                ${description}
              </p>
            </div>
          </div>
        </div>
      `;
    },
  },

  shinakoDream: {
    name: 'ShinakoDream',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img
              src="${userSettings.bannerUrl}"
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="font-family: 'Verdana', sans-serif; background-color: #fceaff; padding: 20px; color: #5b3f5e;">
          <div style="max-width: 1000px; margin: 0 auto; border-radius: 10px; background: linear-gradient(135deg, #fff0fa 30%, #fceaff 70%); box-shadow: 0 0 15px rgba(255,182,193,0.3); padding: 30px;">
            ${bannerHtml}
            ${picHtml}

            <h1 style="text-align: center; font-size: 34px; color: #d14eb6; text-shadow: 1px 1px 0 #fedbf2; margin-bottom: 30px;">
              ${title}
            </h1>

            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.5); border-radius: 8px; padding: 20px;">
                <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Features</h2>
                <ul style="list-style-type: circle; padding-left: 20px;">
                  ${features.split('\n').map(feature => `
                    <li style="margin-bottom: 8px;">${feature}</li>
                  `).join('')}
                </ul>
              </div>

              <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.5); border-radius: 8px; padding: 20px;">
                <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Specifications</h2>
                <table style="width: 100%; border-collapse: collapse;">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(([key, value], index) => `
                      <tr style="background-color: ${index % 2 === 0 ? '#f9daf3' : '#fedbf2'};">
                        <td style="padding: 8px; font-weight: bold; border: 1px solid #e9badf;">${key.replace(/^C:/, '')}</td>
                        <td style="padding: 8px; border: 1px solid #e9badf;">${value}</td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </div>

            <div style="background-color: rgba(255,255,255,0.5); margin-top: 30px; padding: 20px; border-radius: 8px;">
              <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Product Description</h2>
              <p style="line-height: 1.6;">${description}</p>
            </div>
          </div>
        </div>
      `;
    },
  },

  wafuSakura: {
    name: 'WafuSakura',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner" 
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="
          font-family: 'Hiragino Mincho ProN', '游明朝', serif;
          background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%);
          color: #4c3b3b;
          padding: 30px;
        ">
          <div style="
            max-width: 1000px;
            margin: 0 auto;
            background-color: rgba(255,255,255,0.8);
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
          ">
            ${bannerHtml}
            ${picHtml}
            <h1 style="
              text-align: center;
              font-size: 32px;
              margin-bottom: 25px;
              color: #8e4a49;
              border-bottom: 2px solid #f8bac6;
              padding-bottom: 10px;
            ">
              ${title}
            </h1>

            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <!-- Features -->
              <div style="
                flex: 1;
                min-width: 300px;
                background: url('https://cdn.pixabay.com/photo/2017/03/20/08/52/sakura-2156305_1280.jpg') no-repeat center/cover;
                border-radius: 8px;
                padding: 20px;
                color: #4A3A3A;
              ">
                <h2 style="
                  font-size: 24px;
                  margin-bottom: 15px;
                  text-shadow: 1px 1px 4px rgba(0,0,0,0.3);
                ">
                  Key Features
                </h2>
                <ul style="list-style-type: none; padding: 0;">
                  ${features.split('\n').map(feature => `
                    <li style="
                      margin-bottom: 8px;
                      background-color: rgba(255, 192, 203, 0.2);
                      border-radius: 4px;
                      padding: 6px;
                    ">
                      ${feature}
                    </li>
                  `).join('')}
                </ul>
              </div>

              <!-- Specifications -->
              <div style="
                flex: 1;
                min-width: 300px;
                background-color: rgba(255,255,255,0.6);
                border-radius: 8px;
                padding: 20px;
              ">
                <h2 style="
                  font-size: 24px;
                  margin-bottom: 15px;
                  color: #8e4a49;
                ">
                  Specifications
                </h2>
                <table style="
                  width: 100%;
                  border-collapse: collapse;
                  background-color: rgba(255,255,255,0.8);
                  border-radius: 4px;
                ">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(([key, value], index) => `
                      <tr style="
                        background-color: ${
                          index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'
                        };
                      ">
                        <td style="
                          padding: 8px;
                          font-weight: bold;
                          border: 1px solid rgba(140,80,80,0.2);
                        ">
                          ${key.replace(/^C:/, '')}
                        </td>
                        <td style="
                          padding: 8px;
                          border: 1px solid rgba(140,80,80,0.2);
                        ">
                          ${value}
                        </td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </div>

            <div style="
              margin-top: 30px;
              background-color: rgba(255,255,255,0.6);
              border-radius: 8px;
              padding: 20px;
            ">
              <h2 style="
                font-size: 24px;
                margin-bottom: 15px;
                color: #8e4a49;
              ">
                Product Description
              </h2>
              <p style="line-height: 1.6;">
                ${description}
              </p>
            </div>
          </div>
        </div>
      `;
    },
  },

  cyberNeon: {
    name: 'CyberNeon',
    generateHTML: (title, features, description, specifications, userSettings) => {
      let bannerHtml = '';
      if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
        bannerHtml = `
          <div style="text-align:center; margin-bottom:20px;">
            <img 
              src="${userSettings.bannerUrl}" 
              alt="Banner"
              style="max-width:100%; height:auto;"
            />
          </div>
        `;
      }

      let picHtml = '';
      if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
        const chunkSize = 8;
        const rows = [];
        for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
          const chunk = userSettings.picUrls.slice(i, i + chunkSize);
          const cols = chunk
            .map((url) => `
              <td style="padding:5px;">
                <img 
                  src="${url}"
                  alt="Product Image"
                  style="max-width:120px; height:auto;"
                />
              </td>
            `)
            .join('');
          rows.push(`<tr>${cols}</tr>`);
        }
        picHtml = `
          <div style="overflow-x:auto; margin-bottom:20px;">
            <table style="border-collapse: collapse; margin: 0 auto;">
              ${rows.join('')}
            </table>
          </div>
        `;
      }

      return `
        <div id="ai-generated-content" style="
          font-family: 'Roboto', 'Arial', sans-serif;
          background: #0f0f0f;
          color: #fff;
          padding: 30px;
          position: relative;
          overflow: hidden;
        ">

          <!-- 背景アニメーション用 -->
          <style>
            @keyframes floatingLines {
              0% {
                transform: translateX(-100%) skewX(-15deg);
              }
              100% {
                transform: translateX(100%) skewX(-15deg);
              }
            }
          </style>

          <!-- 背景のネオンライン -->
          <div style="
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 2px;
            background: linear-gradient(90deg, #d800ff, #00fff9, #ff8c00);
            animation: floatingLines 3s linear infinite;
            opacity: 0.5;
          "></div>

          <div style="
            max-width: 1000px;
            margin: 0 auto;
            background: rgba(0, 0, 0, 0.7);
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 0 30px rgba(255, 0, 255, 0.3);
            position: relative;
            z-index: 1;
          ">
            ${bannerHtml}
            ${picHtml}

            <!-- タイトル -->
            <h1 style="
              text-align: center;
              font-size: 34px;
              margin-bottom: 30px;
              color: #00e6e6;
              text-shadow: 0 0 5px #00fff9, 0 0 10px #00fff9;
            ">
              ${title}
            </h1>

            <!-- Key Features & Specifications -->
            <div style="display: flex; flex-wrap: wrap; gap: 20px;">
              <!-- Features -->
              <div style="
                flex: 1;
                min-width: 300px;
                background-color: rgba(255,255,255,0.05);
                border: 1px solid rgba(255,255,255,0.1);
                border-radius: 8px;
                padding: 20px;
              ">
                <h2 style="
                  font-size: 24px;
                  margin-bottom: 15px;
                  color: #ff00ff;
                  text-shadow: 0 0 3px #ff00ff;
                ">
                  Key Features
                </h2>
                <ul style="list-style-type: none; padding: 0;">
                  ${features.split('\n').map(feature => `
                    <li style="
                      margin-bottom: 10px;
                      padding: 6px;
                      background: rgba(255,255,255,0.05);
                      border-left: 4px solid #ff00ff;
                    ">
                      ${feature}
                    </li>
                  `).join('')}
                </ul>
              </div>

              <!-- Specifications -->
              <div style="
                flex: 1;
                min-width: 300px;
                background-color: rgba(255,255,255,0.05);
                border: 1px solid rgba(255,255,255,0.1);
                border-radius: 8px;
                padding: 20px;
              ">
                <h2 style="
                  font-size: 24px;
                  margin-bottom: 15px;
                  color: #ff00ff;
                  text-shadow: 0 0 3px #ff00ff;
                ">
                  Specifications
                </h2>
                <table style="
                  width: 100%;
                  border-collapse: collapse;
                  color: #fff;
                ">
                  ${Object.entries(specifications)
                    .filter(([key, value]) => value !== "NA" && value !== "")
                    .map(([key, value], index) => `
                      <tr style="
                        background-color: ${
                          index % 2 === 0 ? 'rgba(255,255,255,0.07)' : 'rgba(255,255,255,0.05)'
                        };
                      ">
                        <td style="
                          padding: 8px;
                          font-weight: bold;
                          border: 1px solid rgba(255,255,255,0.1);
                        ">
                          ${key.replace(/^C:/, '')}
                        </td>
                        <td style="
                          padding: 8px;
                          border: 1px solid rgba(255,255,255,0.1);
                        ">
                          ${value}
                        </td>
                      </tr>
                    `).join('')}
                </table>
              </div>
            </div>

            <!-- Product Description -->
            <div style="
              margin-top: 30px;
              background-color: rgba(255,255,255,0.05);
              border: 1px solid rgba(255,255,255,0.1);
              border-radius: 8px;
              padding: 20px;
            ">
              <h2 style="
                font-size: 24px;
                margin-bottom: 15px;
                color: #00fff9;
                text-shadow: 0 0 3px #00fff9;
              ">
                Product Description
              </h2>
              <p style="line-height: 1.7;">
                ${description}
              </p>
            </div>
          </div>
        </div>
      `;
    },
  },
};

export default designTemplates;




// // designTemplates.js

// const designTemplates = {
//   // ================== SIMPLE ==================
//   simple: {
//     name: 'Simple',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       // バナーHTML
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       // 複数画像HTML (1行最大8枚)
//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         // 1) picUrlsを8枚ごとに分割
//         const chunkSize = 8;
//         const rows = [];

//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => {
//               return `
//                 <td style="padding:5px;">
//                   <img 
//                     src="${url}"
//                     alt="Product Image"
//                     style="max-width:120px; height:auto;"
//                   />
//                 </td>
//               `;
//             })
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }

//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       // 既存レイアウトを維持しつつ、バナーと画像を先頭に挿入
//       return `
//         <div id="ai-generated-content" style="display: flex; flex-wrap: wrap;">
//           ${bannerHtml}
//           ${picHtml}
//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h1>${title}</h1>
//             <h2>Features</h2>
//             <ul>
//               ${features
//                 .split('\n')
//                 .map(feature => `<li>${feature}</li>`)
//                 .join('')}
//             </ul>
//           </div>
//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h2>Specifications</h2>
//             <table>
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== 'NA' && value !== '')
//                 .map(
//                   ([key, value]) => `
//                     <tr>
//                       <td>${key.replace(/^C:/, '')}</td>
//                       <td>${value}</td>
//                     </tr>
//                   `
//                 )
//                 .join('')}
//             </table>
//           </div>
//         </div>
//         <div style="margin-top: 20px;">
//           ${description}
//         </div>
//       `;
//     },
//   },

//   // ================== PROFESSIONAL ==================
//   professional: {
//     name: 'Professional',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       // 1行最大8枚で折り返し
//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];

//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }

//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">
//               ${title}
//             </h1>
//             <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
//                 <ul style="list-style-type: disc; padding-left: 20px;">
//                   ${features
//                     .split('\n')
//                     .map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`)
//                     .join('')}
//                 </ul>
//               </div>
//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
//                 <table style="width: 100%; border-collapse: collapse;">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(
//                       ([key, value]) => `
//                         <tr>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                             ${key.replace(/^C:/, '')}
//                           </td>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                             ${value}
//                           </td>
//                         </tr>
//                       `
//                     )
//                     .join('')}
//                 </table>
//               </div>
//             </div>
//             <div style="margin-top: 20px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
//               <p style="line-height: 1.6;">
//                 ${description}
//               </p>
//             </div>  
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MODERN ==================
//   modern: {
//     name: 'Modern',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">
//               ${title}
//             </h1>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features
//                   .split('\n')
//                   .map(feature => `<li style="margin-bottom: 12px;">${feature}</li>`)
//                   .join('')}
//               </ul>
//             </div>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(
//                     ([key, value]) => `
//                       <tr>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                           ${value}
//                         </td>
//                       </tr>
//                     `
//                   )
//                   .join('')}
//               </table>
//             </div>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
//               <p style="line-height: 1.7;">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== ELEGANT ==================
//   elegant: {
//     name: 'Elegant',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">
//               ${title}
//             </h1>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
//                       <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 12px; border: 1px solid #ecc94b;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//               <p style="line-height: 1.8; text-align: justify;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MINIMALIST ==================
//   minimalist: {
//     name: 'Minimalist',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">
//               ${title}
//             </h1>
//             <div>
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value]) => `
//                     <tr>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `)
//                   .join('')}
//               </table>
//             </div>
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
//               <p style="line-height: 1.6;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== SAKURA UMBRELLA ==================
//   sakuraUmbrella: {
//     name: 'SakuraUmbrella',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content" style="
//           font-family: 'Trebuchet MS', sans-serif;
//           color: #fff;
//           background: linear-gradient(60deg, #d0021b, #35a79c, #ef5b5b);
//           background-size: 180% 180%;
//           animation: gradientBG 10s ease infinite;
//           padding: 30px;
//         ">
//           <style>
//             @keyframes gradientBG {
//               0% {
//                 background-position: 0% 50%;
//               }
//               50% {
//                 background-position: 100% 50%;
//               }
//               100% {
//                 background-position: 0% 50%;
//               }
//             }
//           </style>
//           <div style="
//             max-width: 1000px;
//             margin: 0 auto;
//             border-radius: 10px;
//             background-color: rgba(255, 255, 255, 0.1);
//             padding: 20px;
//             box-shadow: 0 0 20px rgba(0,0,0,0.3);
//           ">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="
//               font-size: 36px;
//               text-align: center;
//               margin-bottom: 30px;
//               text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
//             ">
//               ${title}
//             </h1>
//             <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//               <!-- Features -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Key Features
//                 </h2>
//                 <ul style="list-style-type: none; padding: 0;">
//                   ${features
//                     .split('\n')
//                     .map(feature => `
//                       <li style="
//                         margin-bottom: 10px;
//                         padding: 8px;
//                         background-color: rgba(0,0,0,0.2);
//                         border-radius: 4px;
//                       ">
//                         ${feature}
//                       </li>
//                     `)
//                     .join('')}
//                 </ul>
//               </div>
//               <!-- Specifications -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Specifications
//                 </h2>
//                 <table style="
//                   width: 100%;
//                   border-collapse: collapse;
//                   background-color: rgba(255,255,255,0.1);
//                   border-radius: 4px;
//                 ">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(([key, value], index) => `
//                       <tr style="
//                         background-color: ${
//                           index % 2 === 0 
//                             ? 'rgba(255,255,255,0.15)' 
//                             : 'rgba(255,255,255,0.3)'
//                         };
//                       ">
//                         <td style="
//                           padding: 8px;
//                           font-weight: bold;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="
//                           padding: 8px;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${value}
//                         </td>
//                       </tr>
//                     `).join('')}
//                 </table>
//               </div>
//             </div>
//             <!-- Product Description -->
//             <div style="
//               background-color: rgba(255,255,255,0.15);
//               margin-top: 30px;
//               padding: 20px;
//               border-radius: 8px;
//             ">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                 Product Description
//               </h2>
//               <p style="line-height: 1.6; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== ここから追加したいデザイン ==================
//   ninagawaStyle: {
//     name: 'NinagawaStyle',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content" style="font-family: 'Trebuchet MS', sans-serif; padding: 20px; background: linear-gradient(45deg, #ff8ec7, #ffd747); color: #fff;">
//         <div style="max-width: 1000px; margin: 0 auto;">
//           <h1 style="font-size: 36px; font-weight: bold; text-align: center; margin-bottom: 30px; text-shadow: 2px 2px #ff4791;">
//             ${title}
//           </h1>
//           <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//             <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.2); padding: 20px; border-radius: 10px;">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Key Features</h2>
//               <ul style="list-style: none; padding: 0;">
//                 ${features.split('\n').map(feature => `
//                   <li style="margin-bottom: 10px; background-color: rgba(255,255,255,0.15); padding: 8px; border-radius: 5px;">
//                     <span style="color:#fff;">${feature}</span>
//                   </li>
//                 `).join('')}
//               </ul>
//             </div>

//             <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.2); padding: 20px; border-radius: 10px;">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="background-color: ${index % 2 === 0 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.25)'};">
//                       <td style="padding: 8px; font-weight: bold; color: #fff;">${key.replace(/^C:/, '')}</td>
//                       <td style="padding: 8px; color: #fffee0;">${value}</td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>

//           <div style="background-color: rgba(255,255,255,0.15); margin-top: 30px; padding: 20px; border-radius: 10px;">
//             <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffee0;">Product Description</h2>
//             <p style="line-height: 1.8;">${description}</p>
//           </div>
//         </div>
//       </div>
//     `,
//   },

//   // 2. きゃりーぱみゅぱみゅのようなカラフルでポップなイメージ
//   kyaryPop: {
//     name: 'KyaryPop',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content" style="font-family: 'Comic Sans MS', cursive, sans-serif; background: repeating-linear-gradient(-45deg, #ffa6e6, #ffa6e6 20px, #a6f9ff 20px, #a6f9ff 40px); padding: 30px; color: #333;">
//         <div style="max-width: 1000px; margin: 0 auto; background-color: rgba(255, 255, 255, 0.8); border-radius: 10px; padding: 20px;">
//           <h1 style="text-align: center; font-size: 32px; margin-bottom: 30px; color: #ff1493; text-shadow: 2px 2px #fdfd96;">
//             ${title}
//           </h1>

//           <div style="margin-bottom: 30px;">
//             <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Features</h2>
//             <ul style="padding-left: 20px;">
//               ${features.split('\n').map(feature => `
//                 <li style="margin-bottom: 10px; list-style: '⭐';">${feature}</li>
//               `).join('')}
//             </ul>
//           </div>

//           <div style="margin-bottom: 30px;">
//             <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Specifications</h2>
//             <table style="width: 100%; border-collapse: collapse; border: 2px dotted #ff1493;">
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== "NA" && value !== "")
//                 .map(([key, value], index) => `
//                   <tr style="background-color: ${index % 2 === 0 ? '#ffd1ec' : '#d1faff'};">
//                     <td style="padding: 10px; border: 1px dashed #ff1493; font-weight: bold;">${key.replace(/^C:/, '')}</td>
//                     <td style="padding: 10px; border: 1px dashed #ff1493;">${value}</td>
//                   </tr>
//                 `).join('')}
//             </table>
//           </div>

//           <div style="margin-bottom: 30px;">
//             <h2 style="font-size: 24px; margin-bottom: 15px; color: #ff69b4;">Description</h2>
//             <p style="line-height: 1.6; background-color: #fff; padding: 10px; border-radius: 5px;">
//               ${description}
//             </p>
//           </div>
//         </div>
//       </div>
//     `,
//   },

//   // 3. しなこのようなカラフルポップ＆ゆめかわいいイメージ
//   shinakoDream: {
//     name: 'ShinakoDream',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content" style="font-family: 'Verdana', sans-serif; background-color: #fceaff; padding: 20px; color: #5b3f5e;">
//         <div style="max-width: 1000px; margin: 0 auto; border-radius: 10px; background: linear-gradient(135deg, #fff0fa 30%, #fceaff 70%); box-shadow: 0 0 15px rgba(255,182,193,0.3); padding: 30px;">
          
//           <h1 style="text-align: center; font-size: 34px; color: #d14eb6; text-shadow: 1px 1px 0 #fedbf2; margin-bottom: 30px;">
//             ${title}
//           </h1>

//           <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//             <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.5); border-radius: 8px; padding: 20px;">
//               <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Features</h2>
//               <ul style="list-style-type: circle; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `
//                   <li style="margin-bottom: 8px;">${feature}</li>
//                 `).join('')}
//               </ul>
//             </div>

//             <div style="flex: 1; min-width: 300px; background-color: rgba(255,255,255,0.5); border-radius: 8px; padding: 20px;">
//               <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="background-color: ${index % 2 === 0 ? '#f9daf3' : '#fedbf2'};">
//                       <td style="padding: 8px; font-weight: bold; border: 1px solid #e9badf;">${key.replace(/^C:/, '')}</td>
//                       <td style="padding: 8px; border: 1px solid #e9badf;">${value}</td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>

//           <div style="background-color: rgba(255,255,255,0.5); margin-top: 30px; padding: 20px; border-radius: 8px;">
//             <h2 style="font-size: 22px; margin-bottom: 15px; color: #c63b9f;">Product Description</h2>
//             <p style="line-height: 1.6;">${description}</p>
//           </div>
//         </div>
//       </div>
//     `,
//   },

//   wafuSakura: {
//     name: 'WafuSakura',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content" style="
//         font-family: 'Hiragino Mincho ProN', '游明朝', serif;
//         background: linear-gradient(135deg, #ffffff 30%, #fce4ec 100%);
//         color: #4c3b3b;
//         padding: 30px;
//       ">
//         <div style="
//           max-width: 1000px;
//           margin: 0 auto;
//           background-color: rgba(255,255,255,0.8);
//           border-radius: 10px;
//           padding: 20px;
//           box-shadow: 0 0 10px rgba(0,0,0,0.1);
//         ">
//           <h1 style="
//             text-align: center;
//             font-size: 32px;
//             margin-bottom: 25px;
//             color: #8e4a49;
//             border-bottom: 2px solid #f8bac6;
//             padding-bottom: 10px;
//           ">
//             ${title}
//           </h1>

//           <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//             <!-- Features -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background: url('https://cdn.pixabay.com/photo/2017/03/20/08/52/sakura-2156305_1280.jpg') no-repeat center/cover;
//               border-radius: 8px;
//               padding: 20px;
//               color: #4A3A3A;
//             ">
//               <h2 style="
//                 font-size: 24px;
//                 margin-bottom: 15px;
//                 text-shadow: 1px 1px 4px rgba(0,0,0,0.3);
//               ">
//                 Key Features
//               </h2>
//               <ul style="list-style-type: none; padding: 0;">
//                 ${features.split('\n').map(feature => `
//                   <li style="
//                     margin-bottom: 8px;
//                     background-color: rgba(255, 192, 203, 0.2);
//                     border-radius: 4px;
//                     padding: 6px;
//                   ">
//                     ${feature}
//                   </li>
//                 `).join('')}
//               </ul>
//             </div>

//             <!-- Specifications -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background-color: rgba(255,255,255,0.6);
//               border-radius: 8px;
//               padding: 20px;
//             ">
//               <h2 style="
//                 font-size: 24px;
//                 margin-bottom: 15px;
//                 color: #8e4a49;
//               ">
//                 Specifications
//               </h2>
//               <table style="
//                 width: 100%;
//                 border-collapse: collapse;
//                 background-color: rgba(255,255,255,0.8);
//                 border-radius: 4px;
//               ">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="
//                       background-color: ${
//                         index % 2 === 0 ? 'rgba(248,186,198,0.3)' : 'rgba(248,186,198,0.15)'
//                       };
//                     ">
//                       <td style="
//                         padding: 8px;
//                         font-weight: bold;
//                         border: 1px solid rgba(140,80,80,0.2);
//                       ">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="
//                         padding: 8px;
//                         border: 1px solid rgba(140,80,80,0.2);
//                       ">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>

//           <div style="
//             margin-top: 30px;
//             background-color: rgba(255,255,255,0.6);
//             border-radius: 8px;
//             padding: 20px;
//           ">
//             <h2 style="
//               font-size: 24px;
//               margin-bottom: 15px;
//               color: #8e4a49;
//             ">
//               Product Description
//             </h2>
//             <p style="line-height: 1.6;">
//               ${description}
//             </p>
//           </div>
//         </div>
//       </div>
//     `,
//   },

//   /**
//    * 「CyberNeon」:
//    * 近未来的なサイバーパンクの世界観。秋葉原や新宿のネオン街をイメージし、
//    * ブラックライト風の背景やコントラストの強いネオンカラーを活用。
//    */
//   cyberNeon: {
//     name: 'CyberNeon',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content" style="
//         font-family: 'Roboto', 'Arial', sans-serif;
//         background: #0f0f0f;
//         color: #fff;
//         padding: 30px;
//         position: relative;
//         overflow: hidden;
//       ">

//         <!-- 背景アニメーション用 -->
//         <style>
//           @keyframes floatingLines {
//             0% {
//               transform: translateX(-100%) skewX(-15deg);
//             }
//             100% {
//               transform: translateX(100%) skewX(-15deg);
//             }
//           }
//         </style>

//         <!-- 背景のネオンライン -->
//         <div style="
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 200%;
//           height: 2px;
//           background: linear-gradient(90deg, #d800ff, #00fff9, #ff8c00);
//           animation: floatingLines 3s linear infinite;
//           opacity: 0.5;
//         "></div>

//         <div style="
//           max-width: 1000px;
//           margin: 0 auto;
//           background: rgba(0, 0, 0, 0.7);
//           border-radius: 10px;
//           padding: 20px;
//           box-shadow: 0 0 30px rgba(255, 0, 255, 0.3);
//           position: relative;
//           z-index: 1;
//         ">

//           <!-- タイトル -->
//           <h1 style="
//             text-align: center;
//             font-size: 34px;
//             margin-bottom: 30px;
//             color: #00e6e6;
//             text-shadow: 0 0 5px #00fff9, 0 0 10px #00fff9;
//           ">
//             ${title}
//           </h1>

//           <!-- Key Features & Specifications -->
//           <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//             <!-- Features -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background-color: rgba(255,255,255,0.05);
//               border: 1px solid rgba(255,255,255,0.1);
//               border-radius: 8px;
//               padding: 20px;
//             ">
//               <h2 style="
//                 font-size: 24px;
//                 margin-bottom: 15px;
//                 color: #ff00ff;
//                 text-shadow: 0 0 3px #ff00ff;
//               ">
//                 Key Features
//               </h2>
//               <ul style="list-style-type: none; padding: 0;">
//                 ${features.split('\n').map(feature => `
//                   <li style="
//                     margin-bottom: 10px;
//                     padding: 6px;
//                     background: rgba(255,255,255,0.05);
//                     border-left: 4px solid #ff00ff;
//                   ">
//                     ${feature}
//                   </li>
//                 `).join('')}
//               </ul>
//             </div>

//             <!-- Specifications -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background-color: rgba(255,255,255,0.05);
//               border: 1px solid rgba(255,255,255,0.1);
//               border-radius: 8px;
//               padding: 20px;
//             ">
//               <h2 style="
//                 font-size: 24px;
//                 margin-bottom: 15px;
//                 color: #ff00ff;
//                 text-shadow: 0 0 3px #ff00ff;
//               ">
//                 Specifications
//               </h2>
//               <table style="
//                 width: 100%;
//                 border-collapse: collapse;
//                 color: #fff;
//               ">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="
//                       background-color: ${
//                         index % 2 === 0 ? 'rgba(255,255,255,0.07)' : 'rgba(255,255,255,0.05)'
//                       };
//                     ">
//                       <td style="
//                         padding: 8px;
//                         font-weight: bold;
//                         border: 1px solid rgba(255,255,255,0.1);
//                       ">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="
//                         padding: 8px;
//                         border: 1px solid rgba(255,255,255,0.1);
//                       ">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>

//           <!-- Product Description -->
//           <div style="
//             margin-top: 30px;
//             background-color: rgba(255,255,255,0.05);
//             border: 1px solid rgba(255,255,255,0.1);
//             border-radius: 8px;
//             padding: 20px;
//           ">
//             <h2 style="
//               font-size: 24px;
//               margin-bottom: 15px;
//               color: #00fff9;
//               text-shadow: 0 0 3px #00fff9;
//             ">
//               Product Description
//             </h2>
//             <p style="line-height: 1.7;">
//               ${description}
//             </p>
//           </div>

//         </div>
//       </div>
//     `,
//   },
// };

// export default designTemplates;





// // designTemplates.js

// const designTemplates = {
//   // ================== SIMPLE ==================
//   simple: {
//     name: 'Simple',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       // バナーHTML
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       // 複数画像HTML (1行最大8枚)
//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         // 1) picUrlsを8枚ごとに分割
//         const chunkSize = 8;
//         const rows = [];

//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => {
//               return `
//                 <td style="padding:5px;">
//                   <img 
//                     src="${url}"
//                     alt="Product Image"
//                     style="max-width:120px; height:auto;"
//                   />
//                 </td>
//               `;
//             })
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }

//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       // 既存レイアウトを維持しつつ、バナーと画像を先頭に挿入
//       return `
//         <div id="ai-generated-content" style="display: flex; flex-wrap: wrap;">
//           ${bannerHtml}
//           ${picHtml}
//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h1>${title}</h1>
//             <h2>Features</h2>
//             <ul>
//               ${features
//                 .split('\n')
//                 .map(feature => `<li>${feature}</li>`)
//                 .join('')}
//             </ul>
//           </div>
//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h2>Specifications</h2>
//             <table>
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== 'NA' && value !== '')
//                 .map(
//                   ([key, value]) => `
//                     <tr>
//                       <td>${key.replace(/^C:/, '')}</td>
//                       <td>${value}</td>
//                     </tr>
//                   `
//                 )
//                 .join('')}
//             </table>
//           </div>
//         </div>
//         <div style="margin-top: 20px;">
//           ${description}
//         </div>
//       `;
//     },
//   },

//   // ================== PROFESSIONAL ==================
//   professional: {
//     name: 'Professional',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       // 1行最大8枚で折り返し
//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];

//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }

//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">
//               ${title}
//             </h1>
//             <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
//                 <ul style="list-style-type: disc; padding-left: 20px;">
//                   ${features
//                     .split('\n')
//                     .map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`)
//                     .join('')}
//                 </ul>
//               </div>
//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
//                 <table style="width: 100%; border-collapse: collapse;">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(
//                       ([key, value]) => `
//                         <tr>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                             ${key.replace(/^C:/, '')}
//                           </td>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                             ${value}
//                           </td>
//                         </tr>
//                       `
//                     )
//                     .join('')}
//                 </table>
//               </div>
//             </div>
//             <div style="margin-top: 20px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
//               <p style="line-height: 1.6;">
//                 ${description}
//               </p>
//             </div>  
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MODERN ==================
//   modern: {
//     name: 'Modern',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">
//               ${title}
//             </h1>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features
//                   .split('\n')
//                   .map(feature => `<li style="margin-bottom: 12px;">${feature}</li>`)
//                   .join('')}
//               </ul>
//             </div>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(
//                     ([key, value]) => `
//                       <tr>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                           ${value}
//                         </td>
//                       </tr>
//                     `
//                   )
//                   .join('')}
//               </table>
//             </div>
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
//               <p style="line-height: 1.7;">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== ELEGANT ==================
//   elegant: {
//     name: 'Elegant',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">
//               ${title}
//             </h1>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
//                       <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 12px; border: 1px solid #ecc94b;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//               <p style="line-height: 1.8; text-align: justify;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MINIMALIST ==================
//   minimalist: {
//     name: 'Minimalist',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">
//               ${title}
//             </h1>
//             <div>
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value]) => `
//                     <tr>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `)
//                   .join('')}
//               </table>
//             </div>
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
//               <p style="line-height: 1.6;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== SAKURA UMBRELLA ==================
//   sakuraUmbrella: {
//     name: 'SakuraUmbrella',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         const chunkSize = 8;
//         const rows = [];
//         for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//           const chunk = userSettings.picUrls.slice(i, i + chunkSize);
//           const cols = chunk
//             .map((url) => `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `)
//             .join('');
//           rows.push(`<tr>${cols}</tr>`);
//         }
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               ${rows.join('')}
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content" style="
//           font-family: 'Trebuchet MS', sans-serif;
//           color: #fff;
//           background: linear-gradient(60deg, #d0021b, #35a79c, #ef5b5b);
//           background-size: 180% 180%;
//           animation: gradientBG 10s ease infinite;
//           padding: 30px;
//         ">
//           <style>
//             @keyframes gradientBG {
//               0% {
//                 background-position: 0% 50%;
//               }
//               50% {
//                 background-position: 100% 50%;
//               }
//               100% {
//                 background-position: 0% 50%;
//               }
//             }
//           </style>
//           <div style="
//             max-width: 1000px;
//             margin: 0 auto;
//             border-radius: 10px;
//             background-color: rgba(255, 255, 255, 0.1);
//             padding: 20px;
//             box-shadow: 0 0 20px rgba(0,0,0,0.3);
//           ">
//             ${bannerHtml}
//             ${picHtml}
//             <h1 style="
//               font-size: 36px;
//               text-align: center;
//               margin-bottom: 30px;
//               text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
//             ">
//               ${title}
//             </h1>
//             <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//               <!-- Features -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Key Features
//                 </h2>
//                 <ul style="list-style-type: none; padding: 0;">
//                   ${features
//                     .split('\n')
//                     .map(feature => `
//                       <li style="
//                         margin-bottom: 10px;
//                         padding: 8px;
//                         background-color: rgba(0,0,0,0.2);
//                         border-radius: 4px;
//                       ">
//                         ${feature}
//                       </li>
//                     `)
//                     .join('')}
//                 </ul>
//               </div>
//               <!-- Specifications -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Specifications
//                 </h2>
//                 <table style="
//                   width: 100%;
//                   border-collapse: collapse;
//                   background-color: rgba(255,255,255,0.1);
//                   border-radius: 4px;
//                 ">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(([key, value], index) => `
//                       <tr style="
//                         background-color: ${
//                           index % 2 === 0 
//                             ? 'rgba(255,255,255,0.15)' 
//                             : 'rgba(255,255,255,0.3)'
//                         };
//                       ">
//                         <td style="
//                           padding: 8px;
//                           font-weight: bold;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="
//                           padding: 8px;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${value}
//                         </td>
//                       </tr>
//                     `).join('')}
//                 </table>
//               </div>
//             </div>
//             <!-- Product Description -->
//             <div style="
//               background-color: rgba(255,255,255,0.15);
//               margin-top: 30px;
//               padding: 20px;
//               border-radius: 8px;
//             ">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                 Product Description
//               </h2>
//               <p style="line-height: 1.6; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },
// };

// export default designTemplates;





// // designTemplates.js

// const designTemplates = {
//   // ================== SIMPLE ==================
//   simple: {
//     name: 'Simple',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       // バナーHTML
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       // 複数画像HTML
//       // ===== ここがポイント：複数画像を8枚ごとに並べる =====
//     let picHtml = '';
//     if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//       // 1) picUrlsを8枚ごとに分割
//       const chunkSize = 8;
//       const rows = [];

//       for (let i = 0; i < userSettings.picUrls.length; i += chunkSize) {
//         // 配列の一部を切り出す（最大8枚分）
//         const chunk = userSettings.picUrls.slice(i, i + chunkSize);

//         // 8枚分の画像を<td>で並べる
//         const cols = chunk
//           .map((url) => {
//             return `
//               <td style="padding:5px;">
//                 <img 
//                   src="${url}"
//                   alt="Product Image"
//                   style="max-width:120px; height:auto;"
//                 />
//               </td>
//             `;
//           })
//           .join('');

//         // 1行ぶんの<tr>を組み立て
//         rows.push(`<tr>${cols}</tr>`);
//       }
//       // 2) 生成した<tr>を全部結合して<table>に入れる
//       picHtml = `
//         <div style="overflow-x:auto; margin-bottom:20px;">
//           <table style="border-collapse: collapse; margin: 0 auto;">
//             ${rows.join('')}
//           </table>
//         </div>
//       `;
//     }
//       // 既存レイアウトを維持しつつ、バナーと画像を先頭に挿入
//       return `
//         <div id="ai-generated-content" style="display: flex; flex-wrap: wrap;">
//           <!-- バナー挿入 -->
//           ${bannerHtml}
//           <!-- 複数画像挿入 -->
//           ${picHtml}

//           <!-- もとの構造を残す -->
//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h1>${title}</h1>
//             <h2>Features</h2>
//             <ul>
//               ${features
//                 .split('\n')
//                 .map(feature => `<li>${feature}</li>`)
//                 .join('')}
//             </ul>
//           </div>

//           <div style="flex: 1; min-width: 300px; padding: 10px;">
//             <h2>Specifications</h2>
//             <table>
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== 'NA' && value !== '')
//                 .map(
//                   ([key, value]) => `
//                     <tr>
//                       <td>${key.replace(/^C:/, '')}</td>
//                       <td>${value}</td>
//                     </tr>
//                   `
//                 )
//                 .join('')}
//             </table>
//           </div>
//         </div>
//         <div style="margin-top: 20px;">
//           ${description}
//         </div>
//       `;
//     },
//   },

//   // ================== PROFESSIONAL ==================
//   professional: {
//     name: 'Professional',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               <tr>
//                 ${userSettings.picUrls
//                   .map(
//                     (url) => `
//                       <td style="padding:5px;">
//                         <img 
//                           src="${url}"
//                           alt="Product Image"
//                           style="max-width:120px; height:auto;"
//                         />
//                       </td>
//                     `
//                   )
//                   .join('')}
//               </tr>
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">
//               ${title}
//             </h1>
            
//             <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
//                 <ul style="list-style-type: disc; padding-left: 20px;">
//                   ${features
//                     .split('\n')
//                     .map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`)
//                     .join('')}
//                 </ul>
//               </div>

//               <div style="flex: 1; min-width: 300px; padding: 10px;">
//                 <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
//                 <table style="width: 100%; border-collapse: collapse;">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(
//                       ([key, value]) => `
//                         <tr>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                             ${key.replace(/^C:/, '')}
//                           </td>
//                           <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                             ${value}
//                           </td>
//                         </tr>
//                       `
//                     )
//                     .join('')}
//                 </table>
//               </div>
//             </div>

//             <div style="margin-top: 20px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
//               <p style="line-height: 1.6;">
//                 ${description}
//               </p>
//             </div>  
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MODERN ==================
//   modern: {
//     name: 'Modern',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner" 
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin: 0 auto;">
//               <tr>
//                 ${userSettings.picUrls
//                   .map(
//                     (url) => `
//                       <td style="padding:5px;">
//                         <img
//                           src="${url}"
//                           alt="Product Image"
//                           style="max-width:120px; height:auto;"
//                         />
//                       </td>
//                     `
//                   )
//                   .join('')}
//               </tr>
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">
//               ${title}
//             </h1>
            
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features
//                   .split('\n')
//                   .map(feature => `<li style="margin-bottom: 12px;">${feature}</li>`)
//                   .join('')}
//               </ul>
//             </div>
            
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(
//                     ([key, value]) => `
//                       <tr>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">
//                           ${value}
//                         </td>
//                       </tr>
//                     `
//                   )
//                   .join('')}
//               </table>
//             </div>
            
//             <div style="margin-bottom: 30px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
//               <p style="line-height: 1.7;">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== ELEGANT ==================
//   elegant: {
//     name: 'Elegant',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img 
//               src="${userSettings.bannerUrl}" 
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               <tr>
//                 ${userSettings.picUrls
//                   .map(
//                     (url) => `
//                       <td style="padding:5px;">
//                         <img 
//                           src="${url}"
//                           alt="Product Image"
//                           style="max-width:120px; height:auto;"
//                         />
//                       </td>
//                     `
//                   )
//                   .join('')}
//               </tr>
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">
//               ${title}
//             </h1>
            
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
//                       <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 12px; border: 1px solid #ecc94b;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
            
//             <div style="margin-bottom: 40px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//               <p style="line-height: 1.8; text-align: justify;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== MINIMALIST ==================
//   minimalist: {
//     name: 'Minimalist',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               <tr>
//                 ${userSettings.picUrls
//                   .map(
//                     (url) => `
//                       <td style="padding:5px;">
//                         <img 
//                           src="${url}"
//                           alt="Product Image"
//                           style="max-width:120px; height:auto;"
//                         />
//                       </td>
//                     `
//                   )
//                   .join('')}
//               </tr>
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content">
//           <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">
//               ${title}
//             </h1>

//             <div>
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
      
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value]) => `
//                     <tr>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="padding: 8px; border-bottom: 1px solid #ccc;">
//                         ${value}
//                       </td>
//                     </tr>
//                   `)
//                   .join('')}
//               </table>
//             </div>
      
//             <div style="margin-top: 30px;">
//               <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
//               <p style="line-height: 1.6;">${description}</p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },

//   // ================== SAKURA UMBRELLA ==================
//   sakuraUmbrella: {
//     name: 'SakuraUmbrella',
//     generateHTML: (title, features, description, specifications, userSettings) => {
//       let bannerHtml = '';
//       if (userSettings?.bannerEnabled && userSettings?.bannerUrl) {
//         bannerHtml = `
//           <div style="text-align:center; margin-bottom:20px;">
//             <img
//               src="${userSettings.bannerUrl}"
//               alt="Banner"
//               style="max-width:100%; height:auto;"
//             />
//           </div>
//         `;
//       }

//       let picHtml = '';
//       if (userSettings?.picInsertEnabled && userSettings?.picUrls?.length > 0) {
//         picHtml = `
//           <div style="overflow-x:auto; margin-bottom:20px;">
//             <table style="border-collapse: collapse; margin:0 auto;">
//               <tr>
//                 ${userSettings.picUrls
//                   .map(
//                     (url) => `
//                       <td style="padding:5px;">
//                         <img 
//                           src="${url}"
//                           alt="Product Image"
//                           style="max-width:120px; height:auto;"
//                         />
//                       </td>
//                     `
//                   )
//                   .join('')}
//               </tr>
//             </table>
//           </div>
//         `;
//       }

//       return `
//         <div id="ai-generated-content" style="
//           font-family: 'Trebuchet MS', sans-serif;
//           color: #fff;
//           background: linear-gradient(60deg, #d0021b, #35a79c, #ef5b5b);
//           background-size: 180% 180%;
//           animation: gradientBG 10s ease infinite;
//           padding: 30px;
//         ">
//           <!-- 背景のアニメーション -->
//           <style>
//             @keyframes gradientBG {
//               0% {
//                 background-position: 0% 50%;
//               }
//               50% {
//                 background-position: 100% 50%;
//               }
//               100% {
//                 background-position: 0% 50%;
//               }
//             }
//           </style>

//           <div style="
//             max-width: 1000px;
//             margin: 0 auto;
//             border-radius: 10px;
//             background-color: rgba(255, 255, 255, 0.1);
//             padding: 20px;
//             box-shadow: 0 0 20px rgba(0,0,0,0.3);
//           ">
//             ${bannerHtml}
//             ${picHtml}

//             <h1 style="
//               font-size: 36px;
//               text-align: center;
//               margin-bottom: 30px;
//               text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
//             ">
//               ${title}
//             </h1>

//             <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//               <!-- Features -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Key Features
//                 </h2>
//                 <ul style="list-style-type: none; padding: 0;">
//                   ${features.split('\n').map(feature => `
//                     <li style="
//                       margin-bottom: 10px;
//                       padding: 8px;
//                       background-color: rgba(0,0,0,0.2);
//                       border-radius: 4px;
//                     ">
//                       ${feature}
//                     </li>
//                   `).join('')}
//                 </ul>
//               </div>

//               <!-- Specifications -->
//               <div style="
//                 flex: 1;
//                 min-width: 300px;
//                 background-color: rgba(255,255,255,0.2);
//                 padding: 20px;
//                 border-radius: 8px;
//               ">
//                 <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                   Specifications
//                 </h2>
//                 <table style="
//                   width: 100%;
//                   border-collapse: collapse;
//                   background-color: rgba(255,255,255,0.1);
//                   border-radius: 4px;
//                 ">
//                   ${Object.entries(specifications)
//                     .filter(([key, value]) => value !== "NA" && value !== "")
//                     .map(([key, value], index) => `
//                       <tr style="
//                         background-color: ${
//                           index % 2 === 0 
//                             ? 'rgba(255,255,255,0.15)' 
//                             : 'rgba(255,255,255,0.3)'
//                         };
//                       ">
//                         <td style="
//                           padding: 8px;
//                           font-weight: bold;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${key.replace(/^C:/, '')}
//                         </td>
//                         <td style="
//                           padding: 8px;
//                           border: 1px solid rgba(255,255,255,0.2);
//                         ">
//                           ${value}
//                         </td>
//                       </tr>
//                     `).join('')}
//                 </table>
//               </div>
//             </div>

//             <!-- Product Description -->
//             <div style="
//               background-color: rgba(255,255,255,0.15);
//               margin-top: 30px;
//               padding: 20px;
//               border-radius: 8px;
//             ">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                 Product Description
//               </h2>
//               <p style="line-height: 1.6; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">
//                 ${description}
//               </p>
//             </div>
//           </div>
//         </div>
//       `;
//     },
//   },
// };

// export default designTemplates;





// // designTemplates.js

// const designTemplates = {
//   simple: {
//     name: 'Simple',
//     generateHTML: (title, features, description, specifications, userSettings, picUrls) => `
//       <div id="ai-generated-content" style="display: flex; flex-wrap: wrap;">
//       <div style="flex: 1; min-width: 300px; padding: 10px;">
//         <h1>${title}</h1>
//         ${picUrls.map(url => `<img src="${url}" alt="Product Image" style="width: 100%; height: auto;">`).join('')}
//         <h2>Features</h2>
//         <ul>
//           ${features
//             .split('\n')
//             .map(feature => `<li>${feature}</li>`)
//             .join('')}
//         </ul>
//       </div>
//       <div style="flex: 1; min-width: 300px; padding: 10px;">
//         <h2>Specifications</h2>
//         <table>
//           ${Object.entries(specifications)
//             .filter(([key, value]) => value !== 'NA' && value !== '')
//             .map(
//               ([key, value]) => `
//                 <tr>
//                   <td>${key.replace(/^C:/, '')}</td>
//                   <td>${value}</td>
//                 </tr>
//               `
//             )
//             .join('')}
//         </table>
//       </div>
//     </div>
//     ${description}
//     `,
//   },
//   professional: {
//     name: 'Professional',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content">
//         <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
//           <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
//             <div style="flex: 1; min-width: 300px; padding: 10px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
//               <ul style="list-style-type: disc; padding-left: 20px;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
//               </ul>
//             </div>
//             <div style="flex: 1; min-width: 300px; padding: 10px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value]) => `
//                     <tr>
//                       <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">${key.replace(/^C:/, '')}</td>
//                       <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">${value}</td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>
//           <div style="margin-top: 20px;">
//             <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
//             <p style="line-height: 1.6;">${description}</p>
//           </div>  
//         </div>
//       </div>
//     `,
//   },
//   modern: {
//     name: 'Modern',
//     generateHTML: (title, features, description, specifications, userSettings) => `
//       <div id="ai-generated-content">
//         <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
//           <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">${title}</h1>
          
//           <div style="margin-bottom: 30px;">
//             <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
//             <ul style="list-style-type: disc; padding-left: 20px;">
//               ${features.split('\n').map(feature => `<li style="margin-bottom: 12px;">${feature}</li>`).join('')}
//             </ul>
//           </div>
          
//           <div style="margin-bottom: 30px;">
//             <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
//             <table style="width: 100%; border-collapse: collapse;">
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== "NA" && value !== "")
//                 .map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">${key.replace(/^C:/, '')}</td>
//                     <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">${value}</td>
//                   </tr>
//                 `).join('')}
//             </table>
//           </div>
          
//           <div style="margin-bottom: 30px;">
//             <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
//             <p style="line-height: 1.7;">${description}</p>
//           </div>
        
//         </div>
//       </div>
//     `,
//   },
//   elegant: {
//     name: 'Elegant',
//     generateHTML: (title, features, description, specifications) => `
//       <div id="ai-generated-content">
//         <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
//           <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">${title}</h1>
          
//           <div style="margin-bottom: 40px;">
//             <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//             <ul style="list-style-type: disc; padding-left: 20px;">
//               ${features.split('\n').map(feature => `<li style="margin-bottom: 15px;">${feature}</li>`).join('')}
//             </ul>
//           </div>
          
//           <div style="margin-bottom: 40px;">
//             <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//             <table style="width: 100%; border-collapse: collapse;">
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== "NA" && value !== "")
//                 .map(([key, value], index) => `
//                   <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
//                     <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">${key.replace(/^C:/, '')}</td>
//                     <td style="padding: 12px; border: 1px solid #ecc94b;">${value}</td>
//                   </tr>
//                 `).join('')}
//             </table>
//           </div>
          
//           <div style="margin-bottom: 40px;">
//             <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//             <p style="line-height: 1.8; text-align: justify;">${description}</p>
//           </div>
          
//         </div>
//       </div>
//     `,
//   },
//   minimalist: {
//     name: 'Minimalist',
//     generateHTML: (title, features, description, specifications, userSettings) => `
//       <div id="ai-generated-content">
//         <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
//           <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">${title}</h1>
    
//           <div>
//             <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
//             <ul style="list-style-type: disc; padding-left: 20px;">
//               ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
//             </ul>
//           </div>
    
//           <div style="margin-top: 30px;">
//             <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
//             <table style="width: 100%; border-collapse: collapse;">
//               ${Object.entries(specifications)
//                 .filter(([key, value]) => value !== "NA" && value !== "")
//                 .map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 8px; border-bottom: 1px solid #ccc;">${key.replace(/^C:/, '')}</td>
//                     <td style="padding: 8px; border-bottom: 1px solid #ccc;">${value}</td>
//                   </tr>
//                 `).join('')}
//             </table>
//           </div>
    
//           <div style="margin-top: 30px;">
//             <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
//             <p style="line-height: 1.6;">${description}</p>
//           </div>
//         </div>
//       </div>
//     `,
//   },
//   // 他のテンプレートも同様に修正します
//   sakuraUmbrella: {
//     name: 'SakuraUmbrella',
//     generateHTML: (title, features, description, specifications, userSettings) => `
//       <div id="ai-generated-content" style="
//         font-family: 'Trebuchet MS', sans-serif;
//         color: #fff;
//         background: linear-gradient(60deg, #d0021b, #35a79c, #ef5b5b);
//         background-size: 180% 180%;
//         animation: gradientBG 10s ease infinite;
//         padding: 30px;
//       ">
//         <!-- 背景に動きのあるグラデーションを演出 -->
//         <style>
//           @keyframes gradientBG {
//             0% {
//               background-position: 0% 50%;
//             }
//             50% {
//               background-position: 100% 50%;
//             }
//             100% {
//               background-position: 0% 50%;
//             }
//           }
//         </style>

//         <div style="
//           max-width: 1000px;
//           margin: 0 auto;
//           border-radius: 10px;
//           background-color: rgba(255, 255, 255, 0.1);
//           padding: 20px;
//           box-shadow: 0 0 20px rgba(0,0,0,0.3);
//         ">
//           <!-- タイトル -->
//           <h1 style="
//             font-size: 36px;
//             text-align: center;
//             margin-bottom: 30px;
//             text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
//           ">
//             ${title}
//           </h1>

//           <div style="display: flex; flex-wrap: wrap; gap: 20px;">
//             <!-- Features -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background-color: rgba(255,255,255,0.2);
//               padding: 20px;
//               border-radius: 8px;
//             ">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                 Key Features
//               </h2>
//               <ul style="list-style-type: none; padding: 0;">
//                 ${features.split('\n').map(feature => `
//                   <li style="
//                     margin-bottom: 10px;
//                     padding: 8px;
//                     background-color: rgba(0,0,0,0.2);
//                     border-radius: 4px;
//                   ">
//                     ${feature}
//                   </li>
//                 `).join('')}
//               </ul>
//             </div>

//             <!-- Specifications -->
//             <div style="
//               flex: 1;
//               min-width: 300px;
//               background-color: rgba(255,255,255,0.2);
//               padding: 20px;
//               border-radius: 8px;
//             ">
//               <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//                 Specifications
//               </h2>
//               <table style="
//                 width: 100%;
//                 border-collapse: collapse;
//                 background-color: rgba(255,255,255,0.1);
//                 border-radius: 4px;
//               ">
//                 ${Object.entries(specifications)
//                   .filter(([key, value]) => value !== "NA" && value !== "")
//                   .map(([key, value], index) => `
//                     <tr style="
//                       background-color: ${
//                         index % 2 === 0 ? 'rgba(255,255,255,0.15)' : 'rgba(255,255,255,0.3)'
//                       };
//                     ">
//                       <td style="
//                         padding: 8px;
//                         font-weight: bold;
//                         border: 1px solid rgba(255,255,255,0.2);
//                       ">
//                         ${key.replace(/^C:/, '')}
//                       </td>
//                       <td style="
//                         padding: 8px;
//                         border: 1px solid rgba(255,255,255,0.2);
//                       ">
//                         ${value}
//                       </td>
//                     </tr>
//                   `).join('')}
//               </table>
//             </div>
//           </div>

//           <!-- Product Description -->
//           <div style="
//             background-color: rgba(255,255,255,0.15);
//             margin-top: 30px;
//             padding: 20px;
//             border-radius: 8px;
//           ">
//             <h2 style="font-size: 24px; margin-bottom: 15px; color: #fffded;">
//               Product Description
//             </h2>
//             <p style="line-height: 1.6; text-shadow: 1px 1px 2px rgba(0,0,0,0.3);">
//               ${description}
//             </p>
//           </div>
//         </div>
//       </div>
//     `,
//   },
// };

// export default designTemplates;