// applyAIProductDescriptions.js

import axios from 'axios';
import PQueue from 'p-queue';
import designTemplates from './designTemplates';
import { applyFilters } from './filters'; // フィルター適用関数
import Papa from 'papaparse'; // ファイル内で直接使用するためにインポート

// キャッシュ機能
const descriptionCache = {};

const getCachedDescription = (key) => descriptionCache[key];
const setCachedDescription = (key, value) => {
  descriptionCache[key] = value;
};

// テキスト変換を適用する関数
function applyCustomTransformations(text, sentencesToDelete, wordsToReplace) {
  let transformedText = text;

  // 文章の削除
  sentencesToDelete.forEach((sentence) => {
    if (sentence.trim() !== '') {
      const escapedSentence = sentence.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedSentence, 'g');
      transformedText = transformedText.replace(regex, '');
    }
  });

  // 単語の置換
  wordsToReplace.forEach(({ from, to }) => {
    if (from.trim() !== '') {
      const escapedFrom = from.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedFrom, 'g');
      transformedText = transformedText.replace(regex, to);
    }
  });

  return transformedText;
}

// HTMLをクリーンアップする関数
const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

// フォーマットされた商品説明を生成する関数
const generateFormattedDescription = (item, selectedTemplate) => {
  let template = designTemplates[selectedTemplate];

  if (!template || typeof template.generateHTML !== 'function') {
    console.error(
      `デザインテンプレート "${selectedTemplate}" が見つからないか、generateHTMLメソッドが定義されていません。デフォルトテンプレートを使用します。`
    );
    // デフォルトをProfessionalに変更（任意）
    template = designTemplates['Professional'];
    if (!template || typeof template.generateHTML !== 'function') {
      throw new Error('有効なデフォルトテンプレートが見つかりません。');
    }
  }

  const title = item.Title || '';
  const features = item.Features || '';
  const description = item.Description || '';
  const specifications = item.Specifications || {};

  const html = template.generateHTML(title, features, description, specifications);
  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};

// メインのAI商品説明適用関数
export const applyAIProductDescriptions = async (
  currentData,
  apiKey,
  progressCallback,
  selectedDesignTemplate,
  userSettings
) => {
  if (!apiKey) {
    throw new Error('OpenAI APIキーが設定されていません');
  }

  // p-queueの設定
  const CONCURRENCY = 2;
  const INTERVAL = 1000;
  const INTERVAL_CAP = 5;
  const queue = new PQueue({
    concurrency: CONCURRENCY,
    interval: INTERVAL,
    intervalCap: INTERVAL_CAP,
  });

  let totalItems = 0;
  let completedItems = 0;
  const errorItems = [];

  // 全ファイルに対して処理
  const updatedData = await Promise.all(
    currentData.map(async (file) => {
      const parsedResult = Papa.parse(file.content, { header: true });
      const data = parsedResult.data;

      totalItems += data.length;

      // 商品説明生成タスクの配列
      const generateDescriptionTasks = data.map((item, index) => async () => {
        const originalTitle = item.Title || '';
        const jpDesc = item.jp_desc || item.JP_Description || '';

        // ユーザー設定に基づいてjpDescをフィルタリング
        const filteredJpDesc = applyFilters(
          jpDesc,
          userSettings.selectedFilters || [],
          userSettings.customFilters || []
        );

        // 文章の削除や単語の置換を適用
        const transformedJpDesc = applyCustomTransformations(
          filteredJpDesc,
          userSettings.sentencesToDelete || [],
          userSettings.wordsToReplace || []
        );

        // キャッシュキー
        const cacheKey =
          originalTitle + transformedJpDesc.slice(0, 150) + selectedDesignTemplate;
        const cachedDescription = getCachedDescription(cacheKey);
        if (cachedDescription) {
          item.Description = cachedDescription;
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
          return;
        }

        try {
          await generateSingleDescription(
            item,
            transformedJpDesc,
            apiKey,
            selectedDesignTemplate,
            userSettings
          );
          setCachedDescription(cacheKey, item.Description);
        } catch (error) {
          console.error(`商品説明生成エラー（行 ${index + 1}）:`, error);
          errorItems.push({
            fileName: file.name,
            rowIndex: index + 1,
            error: error.message || error,
          });
        } finally {
          completedItems++;
          if (progressCallback) {
            progressCallback({ totalItems, completedItems });
          }
        }
      });

      // タスクをキューに追加して実行
      await queue.addAll(generateDescriptionTasks);

      // CSVに変換
      const csvContent = Papa.unparse(data);

      return {
        ...file,
        content: csvContent,
      };
    })
  );

  return { updatedData, errorItems };
};

// 単一の商品説明を生成する関数
async function generateSingleDescription(
  item,
  transformedJpDesc,
  apiKey,
  selectedDesignTemplate,
  userSettings
) {
  const originalTitle = item.Title || '';

  // 必須キーワードのプロンプト作成
  const mandatoryKeywordsPrompt =
    userSettings.mandatoryKeywords && userSettings.mandatoryKeywords.length > 0
      ? `You must include all of the following keywords at least once each: ${userSettings.mandatoryKeywords.join(', ')}.`
      : '';

  // AIへのプロンプトをよりシンプルなHTML出力を求める指示を追加
  const requestData = {
    model: 'gpt-4o-mini',
    messages: [
      {
        role: 'system',
        content: `You are eBay's AI assistant specializing in product description optimization.
- Only use these HTML tags: <b>, <i>, <p>, <br>, <ul>, <li>, <table>, <tr>, <td>.
- Do not use any Markdown syntax like ### or **.
- Do not use CSS, style attributes, or complex formatting. Keep it simple.
- You can use <b> for headings or highlights, <ul><li> for bullet points, <p> for paragraphs, <br> for line breaks, and <table><tr><td> for tables.
- No shipping or sales details.
- If no useful info, return only the title.
- Keep under 500 tokens.
- Be clear, concise, and accurate.

1. **Product Information Analysis:**
   - Analyze the provided Japanese product details.
   
2. **HTML Structure:**
   - Use headings and lists as needed.
   - Include item specifics if available (brand, product type, model, color, material, size, condition, etc.).

3. **Clarity and Accuracy:**
   - Use clear, concise English.
   - If information is unclear, omit it.
   - Use only reliable information.

4. **Length Constraint:**
   - Keep the entire English description under 500 tokens.

5. **No Extra Content:**
   - Do not include speculative or unrelated information.
   - No shipping or sales details.

6. **If No Useful Information:**
   - If the Japanese description is essentially empty, you may output only the title or nothing at all.

Focus on producing a high-quality, buyer-oriented description that accurately reflects the product.

${mandatoryKeywordsPrompt}`
      },
      {
        role: 'user',
        content: `Original Title: "${originalTitle}"
Japanese Product Description: "${transformedJpDesc.slice(0, 500)}"
Generate an optimized English product description using only <b>, <i>, <p>, <br>, <ul>, <li>, <table>, <tr>, <td>. No Markdown or CSS. Just simple HTML.`
      },
    ],
    max_tokens: 500,
    temperature: 0.1,
  };

  const response = await axios.post('https://api.openai.com/v1/chat/completions', requestData, {
    headers: { Authorization: `Bearer ${apiKey}` },
  });

  let generatedContent = response.data.choices[0].message.content.trim();

  // 後処理: Markdown的な表記が残っていないかチェックし、余分な改行処理などを行う
  generatedContent = generatedContent.replace(/###\s*/g, '<b>').replace(/^(<b>)/, '<b>'); 
  // 長い改行を<br><br>に変換し、単一改行は<br>へ
  generatedContent = generatedContent.replace(/\n\n+/g, '<br><br>').replace(/\n/g, '<br>');

  // ★ 既存のDescriptionを退避・AI説明を追記 ★
  const originalDescription = item.Description || '';
  const mergedDescription = generatedContent + (originalDescription ? `<br><br>${originalDescription}` : '');


  // とりあえず、AI生成 + オリジナルを合体した内容を item.Description に設定
  item.Description = mergedDescription;

  // 必要に応じてFeatures, Specificationsなどを設定
  if (!item.Features) {
    // 実際には詳細ロジックで抽出するのが望ましい
    item.Features = '';
  }
  if (!item.Specifications) {
    item.Specifications = {};
  }

  // テンプレート適用
  // item.Description はすでに「元の説明 + AI生成文」を含む
  const formattedDescription = generateFormattedDescription(item, selectedDesignTemplate);
  item.Description = formattedDescription;

  return item;
}