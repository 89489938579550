// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   Paper,
//   Snackbar,
//   Alert,
//   List,
//   ListItem,
//   Checkbox,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Tabs,
//   Tab,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   LinearProgress,
//   Grid,
//   Switch,
//   ListItemText,
//   RadioGroup,
//   Radio,
//   FormControlLabel,
//   Chip,
//   Drawer,
//   Pagination,
//   ListItemSecondaryAction,
//   Collapse,
// } from '@mui/material';

// import MenuIcon from '@mui/icons-material/Menu';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';

// import { useNavigate } from 'react-router-dom';
// import Papa from 'papaparse';
// import CryptoJS from 'crypto-js';

// // ---- Firebaseや外部モジュール（例）
// // import { auth, db } from './firebaseConfig';
// // import { getFirestore, doc, getDoc, collection, getDocs, addDoc, setDoc, deleteDoc } from 'firebase/firestore';

// // ---- 外部関数のインポート
// import { applyTemplate } from './functions';
// import { applyAITitles } from './applyAITitles';
// import { applyAIProductDescriptions } from './applyAIProductDescriptions';
// import { calculatePrices } from './calculatePrices';
// import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
// import designTemplates from './designTemplates';

// // =============================
// // ダミーの presetFilters など
// // =============================
// const presetFilters = [
//   {
//     label: '絵文字削除',
//     patterns: ['😀', '😁', '😂'],
//   },
//   {
//     label: '数字削除',
//     patterns: ['0-9の数字を削除'],
//   },
// ];

// // =============================
// // OptionsPanel コンポーネント
// // =============================
// const OptionsPanel = ({
//   // フィルタリングオプションのプロップス
//   selectedFilters,
//   handleFilterChange,
//   customFilterInput,
//   setCustomFilterInput,
//   customFilters,
//   handleAddCustomFilter,
//   handleDeleteCustomFilter,
//   // テキスト変換のプロップス
//   sentencesToDelete = [],
//   setSentencesToDelete = () => {},
//   wordsToReplace = [],
//   setWordsToReplace = () => {},
// }) => {
//   const [openFilterDetails, setOpenFilterDetails] = useState({});

//   const toggleFilterDetails = (label) => {
//     setOpenFilterDetails((prev) => ({
//       ...prev,
//       [label]: !prev[label],
//     }));
//   };

//   return (
//     <Grid container spacing={2}>
//       {/* フィルタリングオプション */}
//       <Grid item xs={12}>
//         <Typography variant="h6">フィルタリングオプション</Typography>
//         {presetFilters.map((filter, index) => (
//           <div key={index}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={selectedFilters.includes(filter.label)}
//                   onChange={() => handleFilterChange(filter.label)}
//                 />
//               }
//               label={
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                   {filter.label}
//                   <IconButton
//                     size="small"
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       toggleFilterDetails(filter.label);
//                     }}
//                   >
//                     {openFilterDetails[filter.label] ? (
//                       <ExpandLessIcon fontSize="small" />
//                     ) : (
//                       <ExpandMoreIcon fontSize="small" />
//                     )}
//                   </IconButton>
//                 </div>
//               }
//             />
//             <Collapse in={openFilterDetails[filter.label]} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 {filter.patterns.map((pattern, idx) => (
//                   <ListItem key={idx} dense>
//                     <ListItemText primary={`• ${pattern}`} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Collapse>
//           </div>
//         ))}
//       </Grid>

//       {/* カスタムフィルター入力 */}
//       <Grid item xs={12}>
//         <TextField
//           label="カスタムフィルターを追加"
//           type="text"
//           fullWidth
//           value={customFilterInput}
//           onChange={(e) => setCustomFilterInput(e.target.value)}
//           variant="outlined"
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleAddCustomFilter}
//           disabled={!customFilterInput.trim()}
//           sx={{ mt: 1 }}
//         >
//           フィルターを追加
//         </Button>
//       </Grid>

//       {/* カスタムフィルター一覧 */}
//       {customFilters.length > 0 && (
//         <Grid item xs={12}>
//           <Typography variant="subtitle1">カスタムフィルター一覧</Typography>
//           <List>
//             {customFilters.map((filter, index) => (
//               <ListItem key={index} dense>
//                 <ListItemText primary={filter} />
//                 <ListItemSecondaryAction>
//                   <IconButton edge="end" onClick={() => handleDeleteCustomFilter(index)}>
//                     <DeleteIcon />
//                   </IconButton>
//                 </ListItemSecondaryAction>
//               </ListItem>
//             ))}
//           </List>
//         </Grid>
//       )}

//       {/* 生成後文章のカスタマイズ */}
//       <Grid item xs={12}>
//         <Typography variant="h6" sx={{ mt: 2 }}>
//           生成された文章のカスタマイズ
//         </Typography>
//       </Grid>

//       {/* 指定した文章の削除 */}
//       <Grid item xs={12}>
//         <Typography variant="subtitle1">削除したい文章（1行に1つ）</Typography>
//         <TextField
//           multiline
//           rows={4}
//           fullWidth
//           variant="outlined"
//           placeholder="削除したい文章を入力..."
//           value={sentencesToDelete.join('\n')}
//           onChange={(e) => setSentencesToDelete(e.target.value.split('\n'))}
//         />
//       </Grid>

//       {/* 指定したワードの置換 */}
//       <Grid item xs={12}>
//         <Typography variant="subtitle1">置換したいワード</Typography>
//         {wordsToReplace.map((item, index) => (
//           <Grid container spacing={1} key={index} alignItems="center">
//             <Grid item xs={5}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="置換前"
//                 value={item.from}
//                 onChange={(e) => {
//                   const newWordsToReplace = [...wordsToReplace];
//                   newWordsToReplace[index].from = e.target.value;
//                   setWordsToReplace(newWordsToReplace);
//                 }}
//               />
//             </Grid>
//             <Grid item xs={5}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="置換後"
//                 value={item.to}
//                 onChange={(e) => {
//                   const newWordsToReplace = [...wordsToReplace];
//                   newWordsToReplace[index].to = e.target.value;
//                   setWordsToReplace(newWordsToReplace);
//                 }}
//               />
//             </Grid>
//             <Grid item xs={2}>
//               <IconButton
//                 onClick={() => {
//                   const newWordsToReplace = [...wordsToReplace];
//                   newWordsToReplace.splice(index, 1);
//                   setWordsToReplace(newWordsToReplace);
//                 }}
//               >
//                 <DeleteIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//         ))}
//         <Button
//           variant="outlined"
//           startIcon={<AddIcon />}
//           onClick={() =>
//             setWordsToReplace([...wordsToReplace, { from: '', to: '' }])
//           }
//           sx={{ mt: 1 }}
//         >
//           置換ペアを追加
//         </Button>
//       </Grid>
//     </Grid>
//   );
// };

// // =============================
// // CsvSchedulerUploader コンポーネント
// // =============================
// function CsvSchedulerUploader({ user }) {
//   const navigate = useNavigate();

//   // CSVファイルアップロード系
//   const [csvFiles, setCsvFiles] = useState([]);
//   const [fileContents, setFileContents] = useState([]);
//   const [parsedData, setParsedData] = useState([]);
//   const [processedData, setProcessedData] = useState([]);
//   const [processedParsedData, setProcessedParsedData] = useState([]);
//   const [activeTab, setActiveTab] = useState(0);

//   // スナックバー
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const showSnackbar = (message, severity = 'info') => {
//     setSnackbar({ open: true, message, severity });
//   };

//   // 機能選択
//   const [availableFunctions, setAvailableFunctions] = useState([
//     {
//       name: 'applyTemplate',
//       displayName: 'テンプレートを実行する',
//       func: applyTemplate,
//     },
//     {
//       name: 'applyPriceChanges',
//       displayName: '価格の変更を適用する',
//       func: 'applyPriceChanges',
//     },
//     {
//       name: 'applyAITitles',
//       displayName: 'AIタイトルを適用する',
//       func: 'applyAITitles',
//     },
//     {
//       name: 'applyAIProductDescriptions',
//       displayName: 'AI商品説明を適用する',
//       func: 'applyAIProductDescriptions',
//     },
//     {
//       name: 'applyItemSpecifics',
//       displayName: 'Item Specificsの適用をする',
//       func: 'applyItemSpecifics',
//     },
//   ]);
//   const [selectedFunctions, setSelectedFunctions] = useState([]);
//   const [functionDialogOpen, setFunctionDialogOpen] = useState(false);

//   // パターン管理
//   const [patterns, setPatterns] = useState([]);
//   const [selectedPatternName, setSelectedPatternName] = useState('');
//   const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
//   const [newPatternName, setNewPatternName] = useState('');
//   const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

//   // 実行スケジュール
//   const [isImmediate, setIsImmediate] = useState(true);
//   const [scheduledTime, setScheduledTime] = useState('');
//   const [isScheduling, setIsScheduling] = useState(false);

//   // 進捗・エラー
//   const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
//   const [errorItems, setErrorItems] = useState([]);

//   // Drawer / 画像拡大
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [selectedProduct, setSelectedProduct] = useState(null);
//   const [isImageModalOpen, setIsImageModalOpen] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   // AIタイトル用
//   const [titleDeleteStrings, setTitleDeleteStrings] = useState([]);
//   const [titleReplacePairs, setTitleReplacePairs] = useState([]);
//   const [titlePrependText, setTitlePrependText] = useState('');
//   const [titleAppendText, setTitleAppendText] = useState('');
//   const [limitTitleLength, setLimitTitleLength] = useState(false);

//   // AI商品説明用
//   const [descDeleteStrings, setDescDeleteStrings] = useState([]);
//   const [descReplacePairs, setDescReplacePairs] = useState([]);
//   const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
//   const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [customFilters, setCustomFilters] = useState([]);
//   const [customFilterInput, setCustomFilterInput] = useState([]);
//   const [useImageDescription, setUseImageDescription] = useState(false);

//   // 価格機能
//   const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
//   const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');
//   const [exchangeRate, setExchangeRate] = useState(0);
//   const [targetProfitMargin, setTargetProfitMargin] = useState(0);
//   const [fees, setFees] = useState({});
//   const [bestOfferSettings, setBestOfferSettings] = useState({});
//   const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);

//   // デザインテンプレート
//   const [designTemplateNames, setDesignTemplateNames] = useState([]);
//   const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

//   // AI説明テンプレート
//   const [aiDescriptionTemplates, setAiDescriptionTemplates] = useState({});
//   const [aiDescriptionTemplateNames, setAIDescriptionTemplateNames] = useState([]);
//   const [selectedAIDescriptionTemplate, setSelectedAIDescriptionTemplate] = useState('');

//   // ItemSpecifics
//   const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});
//   const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
//   const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
//   const [overwriteExisting, setOverwriteExisting] = useState(false);
//   const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
//     caseSensitive: false,
//     partialMatch: true,
//     matchSource: 'title',
//     matchingOption: 'priority',
//   });

//   // テンプレート管理
//   const [templates, setTemplates] = useState([]);
//   const [selectedTemplateId, setSelectedTemplateId] = useState('');
//   const [templateName, setTemplateName] = useState('');
//   const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
//   const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
//   const [saveOption, setSaveOption] = useState('new');

//   // ユーザーAPIキー関連
//   const [userApiKey, setUserApiKey] = useState('');
//   const generateEncryptionKey = (uid) => {
//     const salt = 'your-fixed-salt-value';
//     return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
//   };

//   // マウント時の処理（ダミー）
//   useEffect(() => {
//     if (!user) {
//       navigate('/login');
//       return;
//     }
//     // ここで実際には Firestore等からユーザー設定を取得
//     // 下記はダミー初期化
//     setDesignTemplateNames(Object.keys(designTemplates));
//     setSelectedDesignTemplate('Professional'); // デフォルト

//     setSelectedPriceSettings({
//       exchangeRate: 130,
//       // ほか
//     });
//     setExchangeRate(130);
//     setShippingRateTemplates([{ id: 'templateA', name: '送料テンプレA' }]);
//     setSelectedShippingTemplateId('templateA');

//     setAiDescriptionTemplates({
//       templateA: { name: 'AI説明テンプレA' },
//     });
//     setAIDescriptionTemplateNames(['templateA']);
//     setSelectedAIDescriptionTemplate('templateA');

//     setPatterns([
//       {
//         name: 'パターン1',
//         settings: {
//           applyTemplate: { selectedTemplateId: 'tpl1' },
//           // ...
//         },
//       },
//     ]);
//     setItemSpecificsCategories({
//       Clothing: {
//         columns: {
//           'C:Color': {},
//           'C:Brand': {},
//         },
//       },
//     });
//     setTemplates([
//       { id: 'tpl1', name: 'テストテンプレ1' },
//       { id: 'tpl2', name: 'テストテンプレ2' },
//     ]);
//     setUserApiKey('sk-xxxxxxx'); // ダミー

//   }, [user, navigate]);

//   // CSVファイルハンドリング
//   const handleFileChange = (event) => {
//     const files = Array.from(event.target.files).slice(0, 5);
//     setCsvFiles(files);
//     Promise.all(files.map((file) => file.text())).then((contents) => {
//       setFileContents(contents);
//     });
//   };
//   useEffect(() => {
//     if (fileContents.length > 0) {
//       const data = fileContents.map((content) => {
//         const parsedResult = Papa.parse(content, { header: true });
//         return {
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setParsedData(data);
//     } else {
//       setParsedData([]);
//     }
//   }, [fileContents]);

//   // 処理結果CSVをパース
//   useEffect(() => {
//     if (processedData.length > 0) {
//       const data = processedData.map((file) => {
//         const parsedResult = Papa.parse(file.content, { header: true });
//         return {
//           name: file.name,
//           data: parsedResult.data,
//           columns: parsedResult.meta.fields,
//         };
//       });
//       setProcessedParsedData(data);
//     } else {
//       setProcessedParsedData([]);
//     }
//   }, [processedData]);

//   // 価格変更
//   const applyPriceChanges = useCallback(
//     async (currentData) => {
//       if (!selectedPriceSettings) {
//         throw new Error('価格設定がありません');
//       }
//       try {
//         const updatedData = await Promise.all(
//           currentData.map(async (file) => {
//             const parsedResult = Papa.parse(file.content, { header: true });
//             const data = parsedResult.data;

//             const newPrices = calculatePrices(
//               data,
//               selectedPriceSettings,
//               selectedShippingTemplateId
//             );
//             const updatedItems = data.map((item, idx) => {
//               const calculatedPrice = newPrices[idx];
//               if (calculatedPrice) {
//                 return {
//                   ...item,
//                   StartPrice: calculatedPrice.discountedPrice,
//                   BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
//                   MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
//                 };
//               }
//               return item;
//             });
//             const csvContent = Papa.unparse(updatedItems);
//             return { ...file, content: csvContent };
//           })
//         );
//         return updatedData;
//       } catch (error) {
//         console.error('価格適用中エラー:', error);
//         showSnackbar('価格適用中にエラーが発生しました', 'error');
//         throw error;
//       }
//     },
//     [selectedPriceSettings, selectedShippingTemplateId, showSnackbar]
//   );

//   // AIタイトル
//   const applyAITitlesWrapper = useCallback(
//     async (currentData) => {
//       if (!userApiKey) {
//         showSnackbar('OpenAI APIキーが設定されていません', 'error');
//         throw new Error('APIキー未設定');
//       }
//       setProgress({ totalItems: 0, completedItems: 0 });
//       setErrorItems([]);

//       const customizationOptions = {
//         deleteStrings: titleDeleteStrings,
//         replacePairs: titleReplacePairs,
//         prependText: titlePrependText,
//         appendText: titleAppendText,
//         limitTitleLength,
//       };

//       try {
//         const { updatedData, errorItems: errors } = await applyAITitles(
//           currentData,
//           userApiKey,
//           (prog) => setProgress(prog),
//           customizationOptions
//         );
//         setErrorItems(errors);
//         return updatedData;
//       } catch (error) {
//         console.error('AIタイトル適用中エラー:', error);
//         showSnackbar('AIタイトル適用中にエラーが発生しました', 'error');
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       titleDeleteStrings,
//       titleReplacePairs,
//       titlePrependText,
//       titleAppendText,
//       limitTitleLength,
//       showSnackbar,
//     ]
//   );

//   // AI商品説明
//   const applyAIProductDescriptionsWrapper = useCallback(
//     async (currentData) => {
//       if (!userApiKey) {
//         showSnackbar('OpenAI APIキーが設定されていません', 'error');
//         throw new Error('APIキー未設定');
//       }
//       setProgress({ totalItems: 0, completedItems: 0 });
//       setErrorItems([]);

//       const selectedTemplateData =
//         selectedAIDescriptionTemplate && aiDescriptionTemplates[selectedAIDescriptionTemplate]
//           ? aiDescriptionTemplates[selectedAIDescriptionTemplate]
//           : null;

//       // userSettingsとして必要な要素をまとめる
//       const userSettings = {
//         selectedFilters,
//         customFilters,
//         sentencesToDelete: descDeleteStrings,  // applyCustomTransformations内で使う
//         wordsToReplace: descReplacePairs,      // 同上
//         mandatoryKeywords,
//       };

//       try {
//         const { updatedData, errorItems: errors } = await applyAIProductDescriptions(
//           currentData,
//           userApiKey,
//           (prog) => setProgress(prog),
//           selectedDesignTemplate,
//           {
//             ...userSettings,
//             selectedAIDescriptionTemplateData: selectedTemplateData,
//             useImageDescription,
//           }
//         );
//         setErrorItems(errors);
//         return updatedData;
//       } catch (error) {
//         console.error('AI商品説明適用中エラー:', error);
//         showSnackbar('AI商品説明適用中にエラーが発生しました', 'error');
//         throw error;
//       }
//     },
//     [
//       userApiKey,
//       selectedAIDescriptionTemplate,
//       aiDescriptionTemplates,
//       selectedDesignTemplate,
//       descDeleteStrings,
//       descReplacePairs,
//       mandatoryKeywords,
//       selectedFilters,
//       customFilters,
//       useImageDescription,
//       showSnackbar,
//     ]
//   );

//   // Item Specifics
//   const applyItemSpecificsWrapper = useCallback(
//     async (currentData) => {
//       if (!selectedItemSpecificsCategory) {
//         showSnackbar('Item Specificsのカテゴリーを選択してください', 'warning');
//         throw new Error('Item Specificsカテゴリー未選択');
//       }
//       setProgress({ totalItems: 0, completedItems: 0 });

//       try {
//         // applyItemSpecificsProcessor への引数
//         const updatedData = await applyItemSpecificsProcessor(
//           currentData,
//           user?.uid,
//           {
//             selectedCategory: selectedItemSpecificsCategory,
//             selectedColumns: selectedItemSpecificsColumns,
//             matchingOptions: {
//               ...itemSpecificsMatchingOptions,
//               overwriteExisting, // ここで上書きフラグを渡す
//             },
//           },
//           (prog) => setProgress(prog)
//         );
//         return updatedData;
//       } catch (error) {
//         console.error('Item Specifics適用中エラー:', error);
//         showSnackbar('Item Specifics適用中にエラーが発生しました', 'error');
//         throw error;
//       }
//     },
//     [
//       user,
//       selectedItemSpecificsCategory,
//       selectedItemSpecificsColumns,
//       itemSpecificsMatchingOptions,
//       overwriteExisting,
//       showSnackbar,
//       setProgress,
//     ]
//   );

//   // 各機能を差し替え
//   useEffect(() => {
//     setAvailableFunctions((prev) =>
//       prev.map((func) => {
//         if (func.name === 'applyPriceChanges') {
//           return { ...func, func: applyPriceChanges };
//         }
//         if (func.name === 'applyAITitles') {
//           return { ...func, func: applyAITitlesWrapper };
//         }
//         if (func.name === 'applyAIProductDescriptions') {
//           return { ...func, func: applyAIProductDescriptionsWrapper };
//         }
//         if (func.name === 'applyItemSpecifics') {
//           return { ...func, func: applyItemSpecificsWrapper };
//         }
//         return func;
//       })
//     );
//   }, [
//     applyPriceChanges,
//     applyAITitlesWrapper,
//     applyAIProductDescriptionsWrapper,
//     applyItemSpecificsWrapper,
//   ]);

//   // テンプレート管理（ダミー実装）
//   const handleLoadTemplate = async (templateId) => {
//     if (!templateId) {
//       setSelectedTemplateId('');
//       setTemplateName('');
//       return;
//     }
//     setSelectedTemplateId(templateId);
//     const found = templates.find((t) => t.id === templateId);
//     if (found) {
//       setTemplateName(found.name);
//       showSnackbar(`テンプレート "${found.name}" を読み込みました`, 'success');
//       // 実際にはfoundにある設定をAIタイトル/AI説明用ステートに反映
//     }
//   };
//   const saveTemplate = async () => {
//     if (!templateName.trim()) {
//       showSnackbar('テンプレート名を入力してください', 'warning');
//       return;
//     }
//     showSnackbar('テンプレートを保存しました（ダミー）', 'success');
//     setIsSaveTemplateDialogOpen(false);
//     setTemplateName('');
//   };
//   const handleDeleteTemplate = async (tplId) => {
//     showSnackbar(`テンプレート "${tplId}" を削除しました（ダミー）`, 'info');
//   };

//   // パターン管理（ダミー実装）
//   const applyPatternSettings = (pattern) => {
//     if (!pattern || !pattern.settings) {
//       showSnackbar('選択されたパターンに設定がありません', 'error');
//       return;
//     }
//     showSnackbar(`パターン "${pattern.name}" を適用しました`, 'success');
//     // 実際には pattern.settings.applyTemplate などを参照して state 反映
//   };
//   const saveCurrentSettingsAsPattern = async () => {
//     if (!newPatternName.trim()) {
//       showSnackbar('パターン名を入力してください', 'warning');
//       return;
//     }
//     showSnackbar(`パターン "${newPatternName}" を保存（ダミー）`, 'success');
//     setIsSavePatternDialogOpen(false);
//     setNewPatternName('');
//   };
//   const handleDeletePattern = async (patternName) => {
//     showSnackbar(`パターン "${patternName}" を削除（ダミー）`, 'info');
//   };

//   // 実行
//   const handleUploadAndExecute = async () => {
//     if (!user) {
//       showSnackbar('ユーザーが認証されていません', 'error');
//       return;
//     }
//     if (csvFiles.length === 0) {
//       showSnackbar('CSVファイルを選択してください', 'warning');
//       return;
//     }
//     if (selectedFunctions.length === 0) {
//       showSnackbar('実行する機能を選択してください', 'warning');
//       return;
//     }
//     setIsScheduling(true);

//     try {
//       const filesData = csvFiles.map((file, i) => ({
//         name: file.name,
//         content: fileContents[i],
//       }));

//       const executeFunctions = async () => {
//         let currentData = filesData;
//         for (const func of selectedFunctions) {
//           try {
//             const actualFunc = func.func;
//             if (actualFunc.constructor.name === 'AsyncFunction') {
//               currentData = await actualFunc(currentData);
//             } else {
//               currentData = actualFunc(currentData);
//             }
//           } catch (error) {
//             console.error(`機能 "${func.displayName}" 実行中エラー:`, error);
//             showSnackbar(`機能 "${func.displayName}" 実行中にエラー: ${error.message}`, 'error');
//             setIsScheduling(false);
//             return;
//           }
//         }
//         setProcessedData(currentData);
//         showSnackbar('選択した機能をすべて実行しました', 'success');
//         setIsScheduling(false);
//       };

//       if (isImmediate) {
//         await executeFunctions();
//       } else {
//         const now = new Date();
//         const scheduledDateTime = new Date(scheduledTime);
//         const delay = scheduledDateTime - now;
//         if (delay > 0) {
//           showSnackbar('タスクがスケジュールされました', 'success');
//           setTimeout(async () => {
//             await executeFunctions();
//           }, delay);
//         } else {
//           showSnackbar('指定された時間は過去です。正しい時間を設定してください', 'error');
//           setIsScheduling(false);
//         }
//       }
//     } catch (error) {
//       console.error('処理中エラー:', error);
//       showSnackbar('処理中にエラーが発生しました', 'error');
//       setIsScheduling(false);
//     }
//   };

//   // タブ変更
//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // ページネーション
//   const [itemsPerPageForPreview, setItemsPerPageForPreview] = useState(50);
//   const [previewCurrentPage, setPreviewCurrentPage] = useState(1);

//   // Drawer 内表示
//   const handleOpenImageModal = (url) => {
//     setCurrentImageUrl(url);
//     setIsImageModalOpen(true);
//   };
//   const handleCloseImageModal = () => {
//     setIsImageModalOpen(false);
//     setCurrentImageUrl('');
//   };
//   const getFirstImageUrl = (picUrlString) => {
//     if (!picUrlString) return '';
//     const urls = picUrlString.split('|');
//     return urls[0] || '';
//   };
//   const ImageGallery = ({ picUrlString }) => {
//     if (!picUrlString) return null;
//     const urls = picUrlString.split('|');
//     return (
//       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
//         {urls.map((url, index) => (
//           <Box key={index} sx={{ position: 'relative' }}>
//             <img
//               src={url}
//               alt={`商品画像 ${index + 1}`}
//               style={{
//                 width: '100px',
//                 height: '100px',
//                 objectFit: 'cover',
//                 cursor: 'pointer',
//               }}
//               onClick={() => handleOpenImageModal(url)}
//             />
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   // フィルター
//   const handleFilterChange = (label) => {
//     setSelectedFilters((prev) =>
//       prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
//     );
//   };
//   const handleAddCustomFilter = () => {
//     if (typeof customFilterInput === 'string' && customFilterInput.trim() !== '') {
//       setCustomFilters([...customFilters, customFilterInput.trim()]);
//       setCustomFilterInput('');
//     }
//   };
//   const handleDeleteCustomFilter = (index) => {
//     const updated = [...customFilters];
//     updated.splice(index, 1);
//     setCustomFilters(updated);
//   };

//   // 必須キーワード
//   const handleAddMandatoryKeyword = () => {
//     if (mandatoryKeywordInput.trim() !== '') {
//       setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
//       setMandatoryKeywordInput('');
//     }
//   };
//   const handleDeleteMandatoryKeyword = (index) => {
//     const updated = [...mandatoryKeywords];
//     updated.splice(index, 1);
//     setMandatoryKeywords(updated);
//   };

//   // 機能選択ダイアログ (Drag & Drop 簡略)
//   const onDragEnd = (result) => {
//     if (!result.destination) return;
//     const newFunctions = Array.from(selectedFunctions);
//     const [removed] = newFunctions.splice(result.source.index, 1);
//     newFunctions.splice(result.destination.index, 0, removed);
//     setSelectedFunctions(newFunctions);
//   };
//   const handleFunctionDialogOpen = () => setFunctionDialogOpen(true);
//   const handleFunctionDialogClose = () => setFunctionDialogOpen(false);
//   const handleFunctionToggle = (func) => () => {
//     const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
//     const newSelected = [...selectedFunctions];
//     if (currentIndex === -1) {
//       newSelected.push(func);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }
//     setSelectedFunctions(newSelected);
//   };

//   // ItemSpecifics 用
//   const getColumnsForSelectedCategory = () => {
//     if (selectedItemSpecificsCategory && itemSpecificsCategories[selectedItemSpecificsCategory]) {
//       return Object.keys(itemSpecificsCategories[selectedItemSpecificsCategory].columns || {});
//     }
//     return [];
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
//       <Typography variant="h6" gutterBottom>
//         CSVファイルのアップロードとタスクのスケジュール
//       </Typography>

//       {/* CSVファイル選択 */}
//       <Button variant="contained" component="label">
//         ファイルを選択（最大5つまで）
//         <input
//           type="file"
//           accept=".csv"
//           multiple
//           hidden
//           onChange={handleFileChange}
//         />
//       </Button>
//       {csvFiles.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="subtitle1">選択されたファイル:</Typography>
//           <List>
//             {csvFiles.map((file, index) => (
//               <ListItem key={index}>
//                 <Typography>{file.name}</Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* 機能選択ダイアログを開くボタン */}
//       <Button
//         variant="contained"
//         color="primary"
//         sx={{ mt: 2 }}
//         onClick={handleFunctionDialogOpen}
//         startIcon={<MenuIcon />}
//       >
//         実行する機能を選択
//       </Button>

//       {/* パターン選択 */}
//       <Box sx={{ mt: 2 }}>
//         <FormControl fullWidth>
//           <InputLabel>パターンを選択</InputLabel>
//           <Select
//             value={selectedPatternName}
//             onChange={(e) => {
//               const patternName = e.target.value;
//               setSelectedPatternName(patternName);
//               const pattern = patterns.find((p) => p.name === patternName);
//               applyPatternSettings(pattern);
//             }}
//           >
//             <MenuItem value="">
//               <em>パターンを選択</em>
//             </MenuItem>
//             {patterns.map((pattern) => (
//               <MenuItem key={pattern.name} value={pattern.name}>
//                 {pattern.name}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsSavePatternDialogOpen(true)}
//           sx={{ mt: 1 }}
//         >
//           現在の設定をパターンとして保存
//         </Button>
//         <Button
//           variant="outlined"
//           color="primary"
//           onClick={() => setIsManagePatternsDialogOpen(true)}
//           sx={{ mt: 1, ml: 1 }}
//         >
//           パターンを管理
//         </Button>
//       </Box>

//       {/* 1) テンプレート適用 */}
//       {selectedFunctions.some((f) => f.name === 'applyTemplate') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">1. テンプレート適用</Typography>
//           <Box sx={{ mt: 2 }}>
//             {templates.length > 0 && (
//               <FormControl fullWidth>
//                 <InputLabel>テンプレートを選択</InputLabel>
//                 <Select
//                   value={selectedTemplateId}
//                   onChange={(e) => handleLoadTemplate(e.target.value)}
//                 >
//                   <MenuItem value="">
//                     <em>選択なし</em>
//                   </MenuItem>
//                   {templates.map((tpl) => (
//                     <MenuItem key={tpl.id} value={tpl.id}>
//                       {tpl.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             )}
//             {selectedTemplateId && (
//               <Box sx={{ mt: 1 }}>
//                 <Typography variant="subtitle2">
//                   選択中のテンプレート: {templateName}
//                 </Typography>
//               </Box>
//             )}
//           </Box>
//         </Paper>
//       )}

//       {/* 2) 価格機能 */}
//       {selectedFunctions.some((f) => f.name === 'applyPriceChanges') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">2. 価格機能</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {shippingRateTemplates.length > 0 && (
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>送料設定を選択</InputLabel>
//                   <Select
//                     value={selectedShippingTemplateId}
//                     onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
//                   >
//                     {shippingRateTemplates.map((template) => (
//                       <MenuItem key={template.id} value={template.id}>
//                         {template.name}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             )}
//           </Grid>
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">現在の価格設定 (ダミー)</Typography>
//             <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
//             <Typography>目標利益率: {targetProfitMargin}%</Typography>
//             <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
//             <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
//             <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
//             <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
//             <Typography>
//               ベストオファー自動承認価格: {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
//             </Typography>
//             <Typography>
//               最小ベストオファー価格: {bestOfferSettings.minimumBestOfferPercentage || 0}%
//             </Typography>
//           </Box>
//         </Paper>
//       )}

//       {/* 3) AIタイトル */}
//       {selectedFunctions.some((f) => f.name === 'applyAITitles') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">3. AIタイトル</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12}>
//               <TextField
//                 label="削除したい単語 (カンマ区切り)"
//                 value={titleDeleteStrings.join(', ')}
//                 onChange={(e) =>
//                   setTitleDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
//                 }
//                 fullWidth
//                 sx={{ mt: 1 }}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Typography variant="subtitle1" sx={{ mt: 2 }}>
//                 置換ペア（タイトル用）
//               </Typography>
//               {titleReplacePairs.map((pair, index) => (
//                 <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//                   <TextField
//                     label="置換前"
//                     value={pair.from}
//                     onChange={(e) => {
//                       const newPairs = [...titleReplacePairs];
//                       newPairs[index].from = e.target.value;
//                       setTitleReplacePairs(newPairs);
//                     }}
//                     sx={{ mr: 1 }}
//                   />
//                   <TextField
//                     label="置換後"
//                     value={pair.to}
//                     onChange={(e) => {
//                       const newPairs = [...titleReplacePairs];
//                       newPairs[index].to = e.target.value;
//                       setTitleReplacePairs(newPairs);
//                     }}
//                   />
//                   <IconButton
//                     onClick={() => {
//                       const newPairs = [...titleReplacePairs];
//                       newPairs.splice(index, 1);
//                       setTitleReplacePairs(newPairs);
//                     }}
//                   >
//                     <DeleteIcon />
//                   </IconButton>
//                 </Box>
//               ))}
//               <Button
//                 variant="outlined"
//                 onClick={() =>
//                   setTitleReplacePairs([...titleReplacePairs, { from: '', to: '' }])
//                 }
//                 sx={{ mt: 1 }}
//               >
//                 置換ペアを追加
//               </Button>
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="先頭に追加"
//                 value={titlePrependText}
//                 onChange={(e) => setTitlePrependText(e.target.value)}
//                 fullWidth
//                 sx={{ mt: 2 }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 label="末尾に追加"
//                 value={titleAppendText}
//                 onChange={(e) => setTitleAppendText(e.target.value)}
//                 fullWidth
//                 sx={{ mt: 2 }}
//               />
//             </Grid>
//             <Grid item xs={12} sx={{ mt: 1 }}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={limitTitleLength}
//                     onChange={(e) => setLimitTitleLength(e.target.checked)}
//                   />
//                 }
//                 label="80文字に制限する"
//               />
//             </Grid>
//           </Grid>
//         </Paper>
//       )}

//       {/* 4) AI商品説明 */}
//       {selectedFunctions.some((f) => f.name === 'applyAIProductDescriptions') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">4. AI商品説明</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             {designTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>デザインテンプレート</InputLabel>
//                   <Select
//                     value={selectedDesignTemplate}
//                     onChange={(e) => setSelectedDesignTemplate(e.target.value)}
//                   >
//                     {designTemplateNames.map((dn) => (
//                       <MenuItem key={dn} value={dn}>
//                         {dn}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             )}
//             {aiDescriptionTemplateNames.length > 0 && (
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel>AI説明テンプレート</InputLabel>
//                   <Select
//                     value={selectedAIDescriptionTemplate}
//                     onChange={(e) => setSelectedAIDescriptionTemplate(e.target.value)}
//                   >
//                     <MenuItem value="">
//                       <em>選択なし</em>
//                     </MenuItem>
//                     {aiDescriptionTemplateNames.map((tn) => (
//                       <MenuItem key={tn} value={tn}>
//                         {tn}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </Grid>
//             )}
//             <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Switch
//                     checked={useImageDescription}
//                     onChange={(e) => setUseImageDescription(e.target.checked)}
//                   />
//                 }
//                 label="画像説明を使用"
//               />
//             </Grid>
//           </Grid>

//           <OptionsPanel
//             selectedFilters={selectedFilters}
//             handleFilterChange={handleFilterChange}
//             customFilterInput={customFilterInput}
//             setCustomFilterInput={setCustomFilterInput}
//             customFilters={customFilters}
//             handleAddCustomFilter={handleAddCustomFilter}
//             handleDeleteCustomFilter={handleDeleteCustomFilter}
//             sentencesToDelete={descDeleteStrings}
//             setSentencesToDelete={setDescDeleteStrings}
//             wordsToReplace={descReplacePairs}
//             setWordsToReplace={setDescReplacePairs}
//           />

//           {/* 必須キーワード */}
//           <Box sx={{ mt: 2 }}>
//             <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
//             <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
//               <TextField
//                 label="キーワードを入力"
//                 value={mandatoryKeywordInput}
//                 onChange={(e) => setMandatoryKeywordInput(e.target.value)}
//                 fullWidth
//               />
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleAddMandatoryKeyword}
//                 sx={{ ml: 1, height: '56px' }}
//               >
//                 追加
//               </Button>
//             </Box>
//             <List>
//               {mandatoryKeywords.map((kw, idx) => (
//                 <ListItem
//                   key={idx}
//                   secondaryAction={
//                     <IconButton onClick={() => handleDeleteMandatoryKeyword(idx)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   }
//                 >
//                   <ListItemText primary={kw} />
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         </Paper>
//       )}

//       {/* 5) Item Specifics */}
//       {selectedFunctions.some((f) => f.name === 'applyItemSpecifics') && (
//         <Paper sx={{ p: 2, mt: 2 }}>
//           <Typography variant="h6">5. Item Specifics適用</Typography>
//           <Grid container spacing={2} sx={{ mt: 1 }}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>適用するカテゴリー</InputLabel>
//                 <Select
//                   value={selectedItemSpecificsCategory}
//                   onChange={(e) => {
//                     setSelectedItemSpecificsCategory(e.target.value);
//                     setSelectedItemSpecificsColumns([]);
//                   }}
//                 >
//                   {Object.keys(itemSpecificsCategories).map((cat) => (
//                     <MenuItem key={cat} value={cat}>
//                       {cat}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//           </Grid>
//           {selectedItemSpecificsCategory && (
//             <>
//               <Grid container spacing={2} sx={{ mt: 2 }}>
//                 <Grid item xs={12}>
//                   <FormControl fullWidth>
//                     <InputLabel>適用するカラム</InputLabel>
//                     <Select
//                       multiple
//                       value={selectedItemSpecificsColumns}
//                       onChange={(e) => {
//                         const value = e.target.value;
//                         if (value.includes('all')) {
//                           const allCols = getColumnsForSelectedCategory();
//                           if (selectedItemSpecificsColumns.length === allCols.length) {
//                             setSelectedItemSpecificsColumns([]);
//                           } else {
//                             setSelectedItemSpecificsColumns(allCols);
//                           }
//                         } else {
//                           setSelectedItemSpecificsColumns(value);
//                         }
//                       }}
//                       renderValue={(selected) => {
//                         if (selected.length === 0) {
//                           return '適用するカラムを選択';
//                         }
//                         const displayedValues = selected.slice(0, 5);
//                         const hiddenCount = selected.length - displayedValues.length;
//                         return (
//                           <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                             {displayedValues.map((v) => (
//                               <Chip key={v} label={v} size="small" />
//                             ))}
//                             {hiddenCount > 0 && <Chip label={`+${hiddenCount}個`} size="small" />}
//                           </Box>
//                         );
//                       }}
//                     >
//                       <MenuItem value="all">
//                         <Checkbox
//                           checked={
//                             selectedItemSpecificsColumns.length ===
//                               getColumnsForSelectedCategory().length &&
//                             selectedItemSpecificsColumns.length > 0
//                           }
//                           indeterminate={
//                             selectedItemSpecificsColumns.length > 0 &&
//                             selectedItemSpecificsColumns.length <
//                               getColumnsForSelectedCategory().length
//                           }
//                         />
//                         全選択
//                       </MenuItem>
//                       {getColumnsForSelectedCategory().map((col) => (
//                         <MenuItem key={col} value={col}>
//                           <Checkbox checked={selectedItemSpecificsColumns.indexOf(col) > -1} />
//                           {col}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>
//               <Box sx={{ mt: 2 }}>
//                 <Typography>マッチングの基準</Typography>
//                 <RadioGroup
//                   value={itemSpecificsMatchingOptions.matchSource}
//                   onChange={(e) =>
//                     setItemSpecificsMatchingOptions((prev) => ({
//                       ...prev,
//                       matchSource: e.target.value,
//                     }))
//                   }
//                   row
//                 >
//                   <FormControlLabel value="title" control={<Radio />} label="タイトルのみ" />
//                   <FormControlLabel
//                     value="description"
//                     control={<Radio />}
//                     label="商品説明(AI生成部分)"
//                   />
//                   <FormControlLabel value="both" control={<Radio />} label="両方" />
//                 </RadioGroup>
//                 <Box sx={{ mt: 1 }}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={itemSpecificsMatchingOptions.caseSensitive}
//                         onChange={(e) =>
//                           setItemSpecificsMatchingOptions((prev) => ({
//                             ...prev,
//                             caseSensitive: e.target.checked,
//                           }))
//                         }
//                       />
//                     }
//                     label="大文字小文字を区別する"
//                   />
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={itemSpecificsMatchingOptions.partialMatch}
//                         onChange={(e) =>
//                           setItemSpecificsMatchingOptions((prev) => ({
//                             ...prev,
//                             partialMatch: e.target.checked,
//                           }))
//                         }
//                       />
//                     }
//                     label="部分一致を許可する"
//                   />
//                 </Box>
//                 <Box sx={{ mt: 2 }}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={overwriteExisting}
//                         onChange={(e) => setOverwriteExisting(e.target.checked)}
//                       />
//                     }
//                     label="既存の値を上書きする"
//                   />
//                 </Box>
//                 <Box sx={{ mt: 2 }}>
//                   <Typography variant="subtitle1">マッチングの動作</Typography>
//                   <RadioGroup
//                     value={itemSpecificsMatchingOptions.matchingOption}
//                     onChange={(e) =>
//                       setItemSpecificsMatchingOptions((prev) => ({
//                         ...prev,
//                         matchingOption: e.target.value,
//                       }))
//                     }
//                     row
//                   >
//                     <FormControlLabel
//                       value="priority"
//                       control={<Radio />}
//                       label="優先順位に基づく"
//                     />
//                     <FormControlLabel value="all" control={<Radio />} label="すべて適用" />
//                   </RadioGroup>
//                 </Box>
//               </Box>
//             </>
//           )}
//         </Paper>
//       )}

//       {/* 実行モード */}
//       <Box sx={{ mt: 2 }}>
//         <Typography variant="subtitle1">実行モード:</Typography>
//         <Button
//           variant={isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(true)}
//           sx={{ mr: 1 }}
//         >
//           即時実行
//         </Button>
//         <Button
//           variant={!isImmediate ? 'contained' : 'outlined'}
//           onClick={() => setIsImmediate(false)}
//         >
//           スケジュール実行
//         </Button>
//       </Box>
//       {!isImmediate && (
//         <Box sx={{ mt: 2 }}>
//           <TextField
//             label="実行開始時間"
//             type="datetime-local"
//             value={scheduledTime}
//             onChange={(e) => setScheduledTime(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             fullWidth
//           />
//         </Box>
//       )}

//       {/* 実行ボタン */}
//       <Button
//         variant="contained"
//         color="secondary"
//         sx={{ mt: 2 }}
//         onClick={handleUploadAndExecute}
//         disabled={isScheduling}
//       >
//         {isScheduling ? '処理中...' : 'アップロードして実行'}
//       </Button>

//       {/* 進捗 */}
//       {isScheduling && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理進捗:</Typography>
//           <Typography>
//             {progress.completedItems} / {progress.totalItems} 件が完了しました
//           </Typography>
//           <LinearProgress
//             variant="determinate"
//             value={
//               progress.totalItems > 0
//                 ? (progress.completedItems / progress.totalItems) * 100
//                 : 0
//             }
//           />
//         </Box>
//       )}

//       {/* 処理結果のプレビュー */}
//       {processedParsedData.length > 0 && (
//         <Box sx={{ mt: 4 }}>
//           <Typography variant="h6">処理結果のプレビュー:</Typography>
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons="auto"
//           >
//             {processedParsedData.map((file, index) => (
//               <Tab key={index} label={file.name} />
//             ))}
//           </Tabs>
//           {processedParsedData.map((file, index) => {
//             const isCurrentTab = activeTab === index;

//             const totalItems = file.data.length;
//             const parsedItemsPerPage =
//               itemsPerPageForPreview === 'All' ? totalItems : itemsPerPageForPreview;
//             const totalPages = Math.ceil(totalItems / parsedItemsPerPage);
//             const startIndex = (previewCurrentPage - 1) * parsedItemsPerPage;
//             const endIndex =
//               itemsPerPageForPreview === 'All'
//                 ? totalItems
//                 : startIndex + parsedItemsPerPage;
//             const displayData = file.data.slice(startIndex, endIndex);

//             return (
//               <div key={index} hidden={!isCurrentTab}>
//                 {isCurrentTab && (
//                   <Box sx={{ p: 2 }}>
//                     {/* ページネーションUI */}
//                     <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
//                       <FormControl size="small" sx={{ width: 120 }}>
//                         <InputLabel>表示件数</InputLabel>
//                         <Select
//                           value={itemsPerPageForPreview}
//                           label="表示件数"
//                           onChange={(e) => {
//                             setItemsPerPageForPreview(e.target.value);
//                             setPreviewCurrentPage(1);
//                           }}
//                         >
//                           <MenuItem value={50}>50件</MenuItem>
//                           <MenuItem value={100}>100件</MenuItem>
//                           <MenuItem value={200}>200件</MenuItem>
//                           <MenuItem value="All">All</MenuItem>
//                         </Select>
//                       </FormControl>
//                       {itemsPerPageForPreview !== 'All' && totalPages > 1 && (
//                         <Pagination
//                           count={totalPages}
//                           page={previewCurrentPage}
//                           onChange={(event, value) => setPreviewCurrentPage(value)}
//                           color="primary"
//                         />
//                       )}
//                     </Box>

//                     <Paper elevation={2} sx={{ overflowX: 'auto' }}>
//                       <TableContainer>
//                         <Table size="small" sx={{ tableLayout: 'auto', width: 'auto' }}>
//                           <TableHead>
//                             <TableRow>
//                               {file.columns.map((col, idx) => (
//                                 <TableCell
//                                   key={idx}
//                                   sx={{
//                                     maxWidth: '150px',
//                                     whiteSpace: 'nowrap',
//                                     padding: '8px',
//                                     border: '1px solid rgba(224, 224, 224, 1)',
//                                     fontWeight: 'bold',
//                                   }}
//                                 >
//                                   {col}
//                                 </TableCell>
//                               ))}
//                             </TableRow>
//                           </TableHead>
//                           <TableBody>
//                             {displayData.map((row, rowIndex) => (
//                               <TableRow
//                                 key={rowIndex}
//                                 style={{ cursor: 'pointer' }}
//                                 onClick={() => {
//                                   setSelectedProduct(row);
//                                   setIsDrawerOpen(true);
//                                 }}
//                               >
//                                 {file.columns.map((col, colIndex) => (
//                                   <TableCell
//                                     key={colIndex}
//                                     sx={{
//                                       maxWidth: '150px',
//                                       whiteSpace: 'nowrap',
//                                       padding: '8px',
//                                       border: '1px solid rgba(224, 224, 224, 1)',
//                                     }}
//                                   >
//                                     {col === 'PicURL' ? (
//                                       row[col] ? (
//                                         <img
//                                           src={getFirstImageUrl(row[col])}
//                                           alt="商品画像"
//                                           style={{ width: '50px', height: '50px', objectFit: 'cover' }}
//                                         />
//                                       ) : (
//                                         'なし'
//                                       )
//                                     ) : (
//                                       row[col]
//                                     )}
//                                   </TableCell>
//                                 ))}
//                               </TableRow>
//                             ))}
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                     </Paper>

//                     {/* ページ下部のページネーション */}
//                     {itemsPerPageForPreview !== 'All' && totalPages > 1 && (
//                       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
//                         <Pagination
//                           count={totalPages}
//                           page={previewCurrentPage}
//                           onChange={(event, value) => setPreviewCurrentPage(value)}
//                           color="primary"
//                         />
//                       </Box>
//                     )}

//                     <Drawer
//                       anchor="right"
//                       open={isDrawerOpen}
//                       onClose={() => setIsDrawerOpen(false)}
//                     >
//                       <Box sx={{ width: 700, p: 2 }}>
//                         {selectedProduct ? (
//                           <>
//                             <Typography variant="h6">{selectedProduct['Title']}</Typography>
//                             <ImageGallery picUrlString={selectedProduct['PicURL']} />
//                             <Typography variant="body1" sx={{ mt: 2 }}>
//                               価格: {selectedProduct['StartPrice']}
//                             </Typography>
//                             <Typography variant="body1" sx={{ mt: 2 }}>
//                               説明:
//                             </Typography>
//                             <Box
//                               sx={{ mt: 2 }}
//                               dangerouslySetInnerHTML={{
//                                 __html: selectedProduct['Description'] || '',
//                               }}
//                             />
//                           </>
//                         ) : (
//                           <Typography variant="body1">商品が選択されていません</Typography>
//                         )}
//                       </Box>
//                     </Drawer>
//                   </Box>
//                 )}
//               </div>
//             );
//           })}
//         </Box>
//       )}

//       {/* エラーがある場合 */}
//       {errorItems.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6" color="error">
//             エラーが発生したアイテム:
//           </Typography>
//           <List>
//             {errorItems.map((item, index) => (
//               <ListItem key={index}>
//                 <Typography>
//                   ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
//                 </Typography>
//               </ListItem>
//             ))}
//           </List>
//         </Box>
//       )}

//       {/* ダウンロード */}
//       {processedData.length > 0 && (
//         <Box sx={{ mt: 2 }}>
//           <Typography variant="h6">処理結果のダウンロード:</Typography>
//           {processedData.map((file, index) => (
//             <Button
//               key={index}
//               variant="contained"
//               sx={{ mt: 1, mr: 1 }}
//               onClick={() => {
//                 const blob = new Blob([file.content], { type: 'text/csv;charset=utf-8;' });
//                 const link = document.createElement('a');
//                 link.href = URL.createObjectURL(blob);
//                 link.setAttribute('download', file.name);
//                 document.body.appendChild(link);
//                 link.click();
//                 document.body.removeChild(link);
//               }}
//             >
//               {file.name}
//             </Button>
//           ))}
//         </Box>
//       )}

//       {/* 機能選択ダイアログ */}
//       <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
//         <DialogTitle>実行する機能を選択</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             チェックをつけた機能が実行されます。ドラッグで並び替えも可能（ダミー）。
//           </Typography>
//           <List>
//             {availableFunctions.map((func, index) => (
//               <ListItem key={func.name}>
//                 <Checkbox
//                   checked={selectedFunctions.findIndex((f) => f.name === func.name) !== -1}
//                   onClick={handleFunctionToggle(func)}
//                 />
//                 <Typography>{func.displayName}</Typography>
//                 <IconButton edge="end">
//                   <DragHandleIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleFunctionDialogClose}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート保存ダイアログ */}
//       <Dialog open={isSaveTemplateDialogOpen} onClose={() => setIsSaveTemplateDialogOpen(false)}>
//         <DialogTitle>テンプレートの保存</DialogTitle>
//         <DialogContent>
//           <FormControl component="fieldset">
//             <RadioGroup
//               value={saveOption}
//               onChange={(e) => setSaveOption(e.target.value)}
//             >
//               <FormControlLabel
//                 value="new"
//                 control={<Radio />}
//                 label="新しいテンプレートとして保存"
//               />
//               {selectedTemplateId && (
//                 <FormControlLabel
//                   value="overwrite"
//                   control={<Radio />}
//                   label="既存のテンプレートを上書き"
//                 />
//               )}
//             </RadioGroup>
//           </FormControl>
//           <TextField
//             label="テンプレート名"
//             value={templateName}
//             onChange={(e) => setTemplateName(e.target.value)}
//             fullWidth
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveTemplate} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* テンプレート管理ダイアログ */}
//       <Dialog
//         open={isManageTemplatesDialogOpen}
//         onClose={() => setIsManageTemplatesDialogOpen(false)}
//       >
//         <DialogTitle>テンプレートの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {templates.map((tpl) => (
//               <ListItem key={tpl.id}>
//                 <ListItemText primary={tpl.name} />
//                 <IconButton onClick={() => handleDeleteTemplate(tpl.id)}>
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* パターン保存ダイアログ */}
//       <Dialog open={isSavePatternDialogOpen} onClose={() => setIsSavePatternDialogOpen(false)}>
//         <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="パターン名"
//             value={newPatternName}
//             onChange={(e) => setNewPatternName(e.target.value)}
//             fullWidth
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
//           <Button onClick={saveCurrentSettingsAsPattern} color="primary">
//             保存
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* パターン管理ダイアログ */}
//       <Dialog
//         open={isManagePatternsDialogOpen}
//         onClose={() => setIsManagePatternsDialogOpen(false)}
//       >
//         <DialogTitle>パターンの管理</DialogTitle>
//         <DialogContent>
//           <List>
//             {patterns.map((pattern) => (
//               <ListItem key={pattern.name}>
//                 <ListItemText primary={pattern.name} />
//                 <IconButton onClick={() => handleDeletePattern(pattern.name)}>
//                   <DeleteIcon />
//                 </IconButton>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
//       >
//         <Alert
//           onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
//           severity={snackbar.severity}
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* 画像拡大モーダル */}
//       <Dialog open={isImageModalOpen} onClose={handleCloseImageModal} maxWidth="lg">
//         <DialogContent>
//           <img
//             src={currentImageUrl}
//             alt="拡大画像"
//             style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseImageModal}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </Paper>
//   );
// }

// export default CsvSchedulerUploader;






import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Snackbar,
  Alert,
  List,
  ListItem,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Switch,
  ListItemText,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
  Drawer,
  Tooltip,
  Card,
  CardContent,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';

// Firebase のインポート
import { auth, db } from './firebaseConfig';
import {
  getFirestore,
  getDoc,
  doc,
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';

// 機能関数のインポート
import { applyTemplate, applyItemSpecifics } from './functions';
import Papa from 'papaparse';
import { calculatePrices } from './calculatePrices';
import { applyAITitles } from './applyAITitles';
import { applyAIProductDescriptions } from './applyAIProductDescriptions';
import CryptoJS from 'crypto-js';
import { applyItemSpecificsProcessor } from './ItemSpecificsProcessor';
import designTemplates from './designTemplates';

import OptionsPanel from './OptionsPanel';

function CsvSchedulerUploader({ user }) {
  const [csvFiles, setCsvFiles] = useState([]);
  const [fileContents, setFileContents] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedItemSpecificsCategory, setSelectedItemSpecificsCategory] = useState('');
  const [selectedItemSpecificsColumns, setSelectedItemSpecificsColumns] = useState([]);
  const [itemSpecificsMatchingOptions, setItemSpecificsMatchingOptions] = useState({
    caseSensitive: false,
    partialMatch: true,
    matchSource: 'title',
    matchingOption: 'priority',
  });
  const [itemSpecificsCategories, setItemSpecificsCategories] = useState({});

  const [overwriteExisting, setOverwriteExisting] = useState(false); // 既存値上書き用state


  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [isImmediate, setIsImmediate] = useState(true);
  const [scheduledTime, setScheduledTime] = useState('');
  const [functionDialogOpen, setFunctionDialogOpen] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');

  const [selectedPriceSettings, setSelectedPriceSettings] = useState(null);
  const [shippingRateTemplates, setShippingRateTemplates] = useState([]);
  const [selectedShippingTemplateId, setSelectedShippingTemplateId] = useState('');

  const [exchangeRate, setExchangeRate] = useState(0);
  const [targetProfitMargin, setTargetProfitMargin] = useState(0);
  const [fees, setFees] = useState({});
  const [bestOfferSettings, setBestOfferSettings] = useState({});

  const [userApiKey, setUserApiKey] = useState('');
  const [userSettings, setUserSettings] = useState({});

  const [designTemplateNames, setDesignTemplateNames] = useState([]);
  const [selectedDesignTemplate, setSelectedDesignTemplate] = useState('');

  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [isSaveTemplateDialogOpen, setIsSaveTemplateDialogOpen] = useState(false);
  const [isManageTemplatesDialogOpen, setIsManageTemplatesDialogOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [currentTemplateName, setCurrentTemplateName] = useState('');
  const [saveOption, setSaveOption] = useState('new');

  const [customCategories, setCustomCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [useImageDescription, setUseImageDescription] = useState(false);
  const [mandatoryKeywords, setMandatoryKeywords] = useState([]);
  const [mandatoryKeywordInput, setMandatoryKeywordInput] = useState('');

  const [sentenceToDeleteInput, setSentenceToDeleteInput] = useState('');
  const [wordToReplaceFrom, setWordToReplaceFrom] = useState('');
  const [wordToReplaceTo, setWordToReplaceTo] = useState('');

  // ページネーション用ステートを追加
const [itemsPerPageForPreview, setItemsPerPageForPreview] = useState(50); // 選択可能: 50/100/200/All
const [previewCurrentPage, setPreviewCurrentPage] = useState(1);

// itemsPerPageForPreviewが"All"の場合は全件表示
const parsedItemsPerPage = itemsPerPageForPreview === "All" ? Infinity : itemsPerPageForPreview;

  const navigate = useNavigate();

  const [patterns, setPatterns] = useState([]);
  const [selectedPatternName, setSelectedPatternName] = useState('');

  const generateEncryptionKey = (uid) => {
    const salt = 'your-fixed-salt-value';
    return CryptoJS.PBKDF2(uid, salt, { keySize: 256 / 32 }).toString();
  };

  const [progress, setProgress] = useState({ totalItems: 0, completedItems: 0 });
  const [errorItems, setErrorItems] = useState([]);

  const [availableFunctions, setAvailableFunctions] = useState([
    {
      name: 'applyTemplate',
      displayName: 'テンプレートを実行する',
      func: applyTemplate,
    },
    {
      name: 'applyPriceChanges',
      displayName: '価格の変更を適用する',
      func: 'applyPriceChanges',
    },
    {
      name: 'applyAITitles',
      displayName: 'AIタイトルを適用する',
      func: 'applyAITitles',
    },
    {
      name: 'applyAIProductDescriptions',
      displayName: 'AI商品説明を適用する',
      func: 'applyAIProductDescriptions',
    },
    {
      name: 'applyItemSpecifics',
      displayName: 'Item Specificsの適用をする',
      func: applyItemSpecifics,
    },
  ]);

  // AI商品説明用テンプレートを管理するステート
  const [aiDescriptionTemplateNames, setAIDescriptionTemplateNames] = useState([]);
  const [selectedAIDescriptionTemplate, setSelectedAIDescriptionTemplate] = useState('');
  const [aiDescriptionTemplates, setAiDescriptionTemplates] = useState({});

  useEffect(() => {
    const fetchItemSpecificsSettings = async () => {
      if (!user) return;
      try {
        const db = getFirestore();
        const docRef = doc(db, 'itemSpecificsSettings', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const settingsData = docSnap.data();
          setItemSpecificsCategories(settingsData.categories || {});
        } else {
          console.warn('Item Specificsの設定が見つかりませんでした。');
        }
      } catch (error) {
        console.error('Item Specificsの設定取得中にエラー:', error);
      }
    };

    fetchItemSpecificsSettings();
  }, [user]);

  const getColumnsForSelectedCategory = () => {
    if (
      selectedItemSpecificsCategory &&
      itemSpecificsCategories[selectedItemSpecificsCategory]
    ) {
      return Object.keys(
        itemSpecificsCategories[selectedItemSpecificsCategory].columns || {}
      );
    }
    return [];
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      const fetchTemplates = async () => {
        try {
          const docRef = doc(db, 'userTemplates', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            const userCategories = userData.categories || {};
            const templates = Object.values(userCategories)
              .flat()
              .map((template) => ({
                name: template.name,
                operations: template.operations,
              }));
            setTemplateList(templates);
            if (templates.length > 0) {
              setSelectedTemplateName(templates[0].name);
            }
          }
        } catch (error) {
          console.error('テンプレート取得エラー:', error);
          setSnackbar({
            open: true,
            message: 'テンプレートの取得に失敗しました。',
            severity: 'error',
          });
        }
      };
      fetchTemplates();

      const fetchUserSettings = async () => {
        try {
          const docRef = doc(db, 'userSettings', user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userSettingsData = docSnap.data();
            setSelectedPriceSettings(userSettingsData);
            setUserSettings(userSettingsData);

            if (userSettingsData.shippingRateTemplates) {
              setShippingRateTemplates(userSettingsData.shippingRateTemplates);
              setSelectedShippingTemplateId(
                userSettingsData.selectedShippingTemplateId ||
                  userSettingsData.shippingRateTemplates[0].id
              );
            }

            setExchangeRate(userSettingsData.exchangeRate || 0);
            setTargetProfitMargin(userSettingsData.targetProfitMargin || 0);
            setFees(userSettingsData.fees || {});
            setBestOfferSettings({
              bestOfferAutoAcceptPercentage:
                userSettingsData.bestOfferAutoAcceptPercentage || 0,
              minimumBestOfferPercentage:
                userSettingsData.minimumBestOfferPercentage || 0,
            });

            // デザインテンプレートはFirebaseではなくローカルから取得
            const templateNames = Object.keys(designTemplates);
            setDesignTemplateNames(templateNames);
            if (templateNames.length > 0) {
              setSelectedDesignTemplate(templateNames[0]);
            } else {
              setSelectedDesignTemplate('defaultTemplate');
            }

            setCustomFilters(userSettingsData.customFilters || []);
            setSentencesToDelete(userSettingsData.sentencesToDelete || []);
            setWordsToReplace(userSettingsData.wordsToReplace || []);
            setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

            setCustomFilters(userSettingsData.customFilters || []);
            setSentencesToDelete(userSettingsData.sentencesToDelete || []);
            setWordsToReplace(userSettingsData.wordsToReplace || []);
            setMandatoryKeywords(userSettingsData.mandatoryKeywords || []);

            if (userSettingsData.customCategories) {
              setCustomCategories(userSettingsData.customCategories);
            } else {
              await loadTitleCustomizationTemplates();
            }

            if (userSettingsData.patterns) {
              setPatterns(userSettingsData.patterns);
            } else {
              setPatterns([]);
            }

            // ここでAI商品説明テンプレートを取得してstateに反映
            if (userSettingsData.aiDescriptionTemplates) {
              const aiDescTemplateNames = Object.keys(userSettingsData.aiDescriptionTemplates);
              setAIDescriptionTemplateNames(aiDescTemplateNames);
              setAiDescriptionTemplates(userSettingsData.aiDescriptionTemplates);
              if (aiDescTemplateNames.length > 0) {
                setSelectedAIDescriptionTemplate(aiDescTemplateNames[0]);
              } else {
                setSelectedAIDescriptionTemplate('');
              }
            } else {
              setAIDescriptionTemplateNames([]);
              setSelectedAIDescriptionTemplate('');
              setAiDescriptionTemplates({});
            }

          } else {
            await loadTitleCustomizationTemplates();
          }

          const apiKeyDocRef = doc(db, 'userApiKeys', user.uid);
          const apiKeyDocSnap = await getDoc(apiKeyDocRef);

          if (apiKeyDocSnap.exists()) {
            const encryptedKey = apiKeyDocSnap.data().apiKey;
            const encryptionKey = generateEncryptionKey(user.uid);
            const bytes = CryptoJS.AES.decrypt(encryptedKey, encryptionKey);
            const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
            if (decryptedKey) {
              setUserApiKey(decryptedKey);
            } else {
              console.error('APIキーの復号に失敗');
            }
          } else {
            console.warn('APIキーが見つかりません');
          }

          loadTemplates();
        } catch (error) {
          console.error('ユーザー設定取得エラー:', error);
          setSnackbar({
            open: true,
            message: 'ユーザー設定の取得に失敗しました。',
            severity: 'error',
          });
        }
      };
      fetchUserSettings();

      const loadTitleCustomizationTemplates = async () => {
        if (!user) return;
        try {
          const docRef = doc(db, 'userTitleSettings', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setCustomCategories(data.customCategories || []);
          }
        } catch (error) {
          console.error('タイトルカスタマイズテンプレート取得エラー:', error);
        }
      };
    }
  }, [user, navigate]);

  const [customFilters, setCustomFilters] = useState([]);
  const [sentencesToDelete, setSentencesToDelete] = useState([]);
  const [wordsToReplace, setWordsToReplace] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customFilterInput, setCustomFilterInput] = useState('');

  const saveTemplate = async () => {
    if (!templateName.trim()) {
      setSnackbar({
        open: true,
        message: 'テンプレート名を入力してください',
        severity: 'warning',
      });
      return;
    }

    const templateData = {
      name: templateName.trim(),
      customFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedFilters,
    };

    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');

      if (saveOption === 'overwrite' && selectedTemplateId) {
        const templateDocRef = doc(userTemplatesRef, selectedTemplateId);
        await setDoc(templateDocRef, templateData);
        setSnackbar({
          open: true,
          message: 'テンプレートが更新されました',
          severity: 'success',
        });
      } else {
        await addDoc(userTemplatesRef, templateData);
        setSnackbar({
          open: true,
          message: '新しいテンプレートが保存されました',
          severity: 'success',
        });
      }

      setIsSaveTemplateDialogOpen(false);
      setTemplateName('');
      loadTemplates();
    } catch (error) {
      console.error('テンプレート保存中エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの保存中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const loadTemplates = async () => {
    try {
      const userTemplatesRef = collection(db, 'users', user.uid, 'templates');
      const templatesSnapshot = await getDocs(userTemplatesRef);
      const templatesData = templatesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTemplates(templatesData);
    } catch (error) {
      console.error('テンプレート読み込みエラー:', error);
    }
  };

  const handleLoadTemplate = async (templateId) => {
    if (!templateId) {
      setSelectedTemplateId('');
      setTemplateName('');
      setCurrentTemplateName('');
      return;
    }
    setSelectedTemplateId(templateId);
    try {
      const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
      const templateDoc = await getDoc(templateDocRef);
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        setTemplateName(templateData.name || '');
        setCurrentTemplateName(templateData.name || '');
        setCustomFilters(templateData.customFilters || []);
        setSentencesToDelete(templateData.sentencesToDelete || []);
        setWordsToReplace(templateData.wordsToReplace || []);
        setMandatoryKeywords(templateData.mandatoryKeywords || []);
        setSelectedFilters(templateData.selectedFilters || []);
        setSnackbar({
          open: true,
          message: 'テンプレートが適用されました',
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: 'テンプレートが見つかりません',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('テンプレート読み込みエラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの読み込み中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      const templateDocRef = doc(db, 'users', user.uid, 'templates', templateId);
      await deleteDoc(templateDocRef);
      setSnackbar({
        open: true,
        message: 'テンプレートが削除されました',
        severity: 'success',
      });
      loadTemplates();
    } catch (error) {
      console.error('テンプレート削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'テンプレートの削除中にエラーが発生しました',
        severity: 'error',
      });
    }
  };

  const handleCreateNewTemplate = () => {
    setSelectedTemplateId('');
    setTemplateName('');
    setCurrentTemplateName('');
    setCustomFilters([]);
    setSelectedFilters([]);
    setSentencesToDelete([]);
    setWordsToReplace([]);
    setMandatoryKeywords([]);
    setSnackbar({
      open: true,
      message: '新しいテンプレートを作成します',
      severity: 'info',
    });
  };

  const handleAddMandatoryKeyword = () => {
    if (mandatoryKeywordInput.trim() !== '') {
      setMandatoryKeywords([...mandatoryKeywords, mandatoryKeywordInput.trim()]);
      setMandatoryKeywordInput('');
    }
  };

  const handleDeleteMandatoryKeyword = (index) => {
    const updatedKeywords = [...mandatoryKeywords];
    updatedKeywords.splice(index, 1);
    setMandatoryKeywords(updatedKeywords);
  };

  const handleAddSentenceToDelete = () => {
    if (sentenceToDeleteInput.trim() !== '') {
      setSentencesToDelete([...sentencesToDelete, sentenceToDeleteInput.trim()]);
      setSentenceToDeleteInput('');
    }
  };

  const handleDeleteSentenceToDelete = (index) => {
    const updatedSentences = [...sentencesToDelete];
    updatedSentences.splice(index, 1);
    setSentencesToDelete(updatedSentences);
  };

  const handleAddWordToReplace = () => {
    if (wordToReplaceFrom.trim() !== '' && wordToReplaceTo.trim() !== '') {
      setWordsToReplace([
        ...wordsToReplace,
        { from: wordToReplaceFrom.trim(), to: wordToReplaceTo.trim() },
      ]);
      setWordToReplaceFrom('');
      setWordToReplaceTo('');
    }
  };

  const handleDeleteWordToReplace = (index) => {
    const updatedWords = [...wordsToReplace];
    updatedWords.splice(index, 1);
    setWordsToReplace(updatedWords);
  };

  const [deleteStrings, setDeleteStrings] = useState([]);
  const [replacePairs, setReplacePairs] = useState([]);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [limitTitleLength, setLimitTitleLength] = useState(false);

  const handleAddReplacePair = () => {
    setReplacePairs([...replacePairs, { from: '', to: '' }]);
  };

  const handleReplacePairChange = (index, field, value) => {
    const newPairs = [...replacePairs];
    newPairs[index][field] = value;
    setReplacePairs(newPairs);
  };

  const handleRemoveReplacePair = (index) => {
    const newPairs = [...replacePairs];
    newPairs.splice(index, 1);
    setReplacePairs(newPairs);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 5);
    setCsvFiles(files);
    Promise.all(files.map((file) => file.text())).then((contents) => {
      setFileContents(contents);
    });
  };

  const handleFunctionDialogOpen = () => {
    setFunctionDialogOpen(true);
  };

  const handleFunctionDialogClose = () => {
    setFunctionDialogOpen(false);
  };

  const handleFunctionToggle = (func) => () => {
    const currentIndex = selectedFunctions.findIndex((f) => f.name === func.name);
    const newSelected = [...selectedFunctions];

    if (currentIndex === -1) {
      newSelected.push(func);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedFunctions(newSelected);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newFunctions = Array.from(selectedFunctions);
    const [removed] = newFunctions.splice(result.source.index, 1);
    newFunctions.splice(result.destination.index, 0, removed);

    setSelectedFunctions(newFunctions);
  };

  const applyPriceChanges = useCallback(
    async (currentData) => {
      try {
        if (!selectedPriceSettings) {
          throw new Error('価格設定が取得されていません。');
        }

        const updatedPriceSettings = {
          ...selectedPriceSettings,
          selectedShippingTemplateId,
        };

        const updatedData = await Promise.all(
          currentData.map(async (file) => {
            const parsedResult = Papa.parse(file.content, { header: true });
            const data = parsedResult.data;
            const newPrices = calculatePrices(
              data,
              updatedPriceSettings,
              selectedShippingTemplateId
            );
            const updatedData = data.map((item, index) => {
              const calculatedPrice = newPrices[index];
              if (calculatedPrice) {
                return {
                  ...item,
                  StartPrice: calculatedPrice.discountedPrice,
                  BestOfferAutoAcceptPrice: calculatedPrice.bestOfferAutoAcceptPrice,
                  MinimumBestOfferPrice: calculatedPrice.minimumBestOfferPrice,
                };
              } else {
                return item;
              }
            });

            const csvContent = Papa.unparse(updatedData);
            return {
              ...file,
              content: csvContent,
            };
          })
        );

        return updatedData;
      } catch (error) {
        console.error('価格適用中エラー:', error);
        setSnackbar({
          open: true,
          message: '価格適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [selectedPriceSettings, selectedShippingTemplateId, setSnackbar]
  );

  const applyAITitlesWrapper = useCallback(
    async (currentData) => {
      try {
        if (!userApiKey) {
          setSnackbar({
            open: true,
            message:
              'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
            severity: 'error',
          });
          throw new Error('OpenAI APIキーが設定されていません。');
        }

        setProgress({ totalItems: 0, completedItems: 0 });
        setErrorItems([]);

        const customizationOptions = {
          deleteStrings,
          replacePairs,
          prependText,
          appendText,
          limitTitleLength,
        };

        const { updatedData, errorItems } = await applyAITitles(
          currentData,
          userApiKey,
          (progressData) => {
            setProgress(progressData);
          },
          customizationOptions
        );

        setErrorItems(errorItems);
        return updatedData;
      } catch (error) {
        console.error('AIタイトル適用中エラー:', error);
        setSnackbar({
          open: true,
          message: 'AIタイトル適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [
      userApiKey,
      setSnackbar,
      setProgress,
      setErrorItems,
      deleteStrings,
      replacePairs,
      prependText,
      appendText,
      limitTitleLength,
    ]
  );

  const applyAIProductDescriptionsWrapper = useCallback(
    async (currentData) => {
      try {
        if (!userApiKey) {
          setSnackbar({
            open: true,
            message: 'OpenAI APIキーが設定されていません。ユーザー設定でAPIキーを入力してください。',
            severity: 'error',
          });
          throw new Error('OpenAI APIキーが設定されていません。');
        }
  
        setProgress({ totalItems: 0, completedItems: 0 });
        setErrorItems([]);
  
        const selectedTemplateData =
          selectedAIDescriptionTemplate && aiDescriptionTemplates[selectedAIDescriptionTemplate]
            ? aiDescriptionTemplates[selectedAIDescriptionTemplate]
            : null;
  
        // ここでapplyAIProductDescriptionsが最終的なHTMLを返すように修正済みとして仮定
        const { updatedData, errorItems } = await applyAIProductDescriptions(
          currentData,
          userApiKey,
          (progressData) => {
            setProgress(progressData);
          },
          selectedDesignTemplate,
          {
            customFilters,
            selectedFilters,
            sentencesToDelete,
            wordsToReplace,
            mandatoryKeywords,
            selectedAIDescriptionTemplateData: selectedTemplateData,
          }
        );
  
        // ★ この下の再度updatedDataをmapして再生成する処理は削除します ★
        // updatedDataをそのまま返せばOK
  
        setErrorItems(errorItems);
        return updatedData;
  
      } catch (error) {
        console.error('AI商品説明適用中エラー:', error);
        setSnackbar({
          open: true,
          message: 'AI商品説明適用中にエラーが発生しました。',
          severity: 'error',
        });
        throw error;
      }
    },
    [
      userApiKey,
      setSnackbar,
      setProgress,
      setErrorItems,
      selectedDesignTemplate,
      customFilters,
      selectedFilters,
      sentencesToDelete,
      wordsToReplace,
      mandatoryKeywords,
      selectedAIDescriptionTemplate,
      aiDescriptionTemplates,
    ]
  );
  
  

// sanitizeDescription と cleanHTML 関数
const sanitizeDescription = (description) => {
  return description.replace(/<\/?(meta|link)(.|\s)*?>/gi, '');
};

const cleanHTML = (html) => {
  let cleanedHtml = html.replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '');
  cleanedHtml = cleanedHtml.replace(/\sclass="[^"]*"/gi, '');
  cleanedHtml = cleanedHtml.replace(/\s+/g, ' ').trim();
  cleanedHtml = cleanedHtml.replace(/>\s+</g, '><');
  return cleanedHtml;
};

// generateFormattedDescription 関数
const generateFormattedDescription = (
  title,
  features,
  description,
  specifications,
  selectedTemplate
) => {
  const template = designTemplates[selectedTemplate];
  const html = template.generateHTML(title, features, description, specifications);
  const cleanedHtml = cleanHTML(html);
  return cleanedHtml;
};


  // 変更箇所2: AI生成部分抽出用の関数を追加
// 1) AI生成部分の抽出
const extractAIGeneratedContent = (description) => {
  if (!description) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, "text/html");
  const aiContentDiv = doc.getElementById("ai-generated-content");
  return aiContentDiv ? aiContentDiv.innerHTML : "";
};

// 2) liタグから "C:何か: 値" または "何か: 値" 形式でKey:Valueを抽出
const extractKeyValuePairs = (htmlContent) => {
  const keyValuePairs = {};
  if (!htmlContent) return keyValuePairs;

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  const liElements = doc.querySelectorAll("li");

  liElements.forEach((li) => {
    let text = li.textContent.trim();

    // 先頭の記号・余分な空白を除去
    text = text
      .replace(/^\s*[-•✓]+[\s]*/, "")
      .replace(/\*\*/g, "")
      .replace(/\s+/g, " ");

    // 正規表現で Key:Value を取り出す。C: で始まるかどうかもチェック
    let match = text.match(/^(C:[^:]+):\s*(.*)$/);
    if (!match) {
      match = text.match(/^([^:]+):\s*(.*)$/);
    }

    if (match) {
      let key = match[1].trim().replace(/\*+$/g, "").replace(/^\*+/g, "");
      let value = match[2].trim();

      // key が "C:Something" の場合は、"C:" を取り除いた状態にする
      if (key.startsWith("C:")) {
        key = key.slice(2);
      }

      if (key && value) {
        keyValuePairs[key] = value;
      }
    }
  });

  return keyValuePairs;
};

// 3) 値を64文字以内にしつつ、単語の途中で切れないように調整する関数
const truncateValueByWords = (value, maxLength) => {
  if (value.length <= maxLength) return value;

  const words = value.split(" ");
  let truncatedValue = "";
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const separator = truncatedValue ? " " : "";
    if ((truncatedValue + separator + word).length > maxLength) {
      break;
    }
    truncatedValue += separator + word;
  }

  return truncatedValue;
};


// 変更箇所3: applyItemSpecificsWrapper関数内でAI生成部分からの値適用と既存値上書きロジックを追
// 既存のapplyItemSpecificsWrapper内で、applyItemSpecificsProcessor実行後に以下の処理を追加・変更

// ★ すでにCsvSchedulerUploader.jsにある場合は、ここを参考に修正／確認してください ★

const applyItemSpecificsWrapper = useCallback(
  async (currentData, options) => {
    const {
      selectedItemSpecificsCategory,
      selectedItemSpecificsColumns,
      itemSpecificsMatchingOptions,
    } = options;
    try {
      if (!user) {
        setSnackbar({
          open: true,
          message: 'ユーザー情報が取得できません。',
          severity: 'error',
        });
        throw new Error('ユーザー情報が取得できません。');
      }

      if (!selectedItemSpecificsCategory) {
        setSnackbar({
          open: true,
          message: '適用するカテゴリーを選択してください。',
          severity: 'warning',
        });
        throw new Error('適用するカテゴリーを選択してください。');
      }

      setProgress({ totalItems: 0, completedItems: 0 });

      // 既存のItemSpecificsProcessorによる適用処理
      let updatedData = await applyItemSpecificsProcessor(
        currentData,
        user.uid,
        {
          selectedCategory: selectedItemSpecificsCategory,
          selectedColumns: selectedItemSpecificsColumns,
          matchingOptions: itemSpecificsMatchingOptions,
        },
        (progressData) => {
          setProgress(progressData);
        }
      );

      // ここからが「C:~~」を抽出して適用する追加ロジック
      updatedData = await Promise.all(
        updatedData.map((file) => {
          const parsedResult = Papa.parse(file.content, { header: true });
          const data = parsedResult.data;
          const headers = parsedResult.meta.fields;

          // Title と Description カラムを取得
          const titleKey = headers.find((h) => h.toLowerCase() === 'title');
          const descriptionKey = headers.find(
            (h) => h.toLowerCase() === 'description'
          );

          const { caseSensitive, partialMatch, matchSource, matchingOption } =
            itemSpecificsMatchingOptions;

          const newData = data.map((item) => {
            const title = item[titleKey] || '';
            const description = item[descriptionKey] || '';

            // AI生成部分を抽出
            const aiContent = extractAIGeneratedContent(description);

            // matchSource によってソーステキストを決定 (title / description / both)
            let sourceText = '';
            if (matchSource === 'title') {
              sourceText = title;
            } else if (matchSource === 'description') {
              sourceText = aiContent;
            } else if (matchSource === 'both') {
              sourceText = `${title} ${aiContent}`;
            }

            const newItem = { ...item };

            // AI生成部分(aiContent)から "C:何か: 値" を取り出し
            const keyValuePairs = extractKeyValuePairs(aiContent);

            // カラム名とKeyを照合して該当する "C:..." カラムを更新
            const normalizeString = (str) =>
              str.toLowerCase().replace(/\s+/g, '').replace(/[^a-z0-9]/g, '');

            Object.keys(keyValuePairs).forEach((key) => {
              // 例: key="Color" -> カラム名"C:Color" があれば適用したい
              const normalizedKey = normalizeString(key);
              const matchingHeader = headers.find(
                (header) =>
                  header.startsWith('C:') &&
                  normalizeString(header.slice(2)) === normalizedKey
              );

              if (matchingHeader) {
                // 例: valueToApply="Red" (64文字以内で単語が途中で切れないように調整)
                let valueToApply = truncateValueByWords(keyValuePairs[key], 64);

                // overwriteExisting（既存値を上書きするかどうか）に従ってセット
                if (overwriteExisting || !newItem[matchingHeader]) {
                  newItem[matchingHeader] = valueToApply;
                }
              }
            });

            return newItem;
          });

          const csvContent = Papa.unparse(newData);
          return { ...file, content: csvContent };
        })
      );

      return updatedData;
    } catch (error) {
      console.error('Item Specifics適用中エラー:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Item Specifics適用中にエラーが発生しました。',
        severity: 'error',
      });
      throw error;
    }
  },
  [
    user,
    setSnackbar,
    setProgress,
    overwriteExisting, // ←「既存値を上書き」チェック用ステート
    extractAIGeneratedContent,
    extractKeyValuePairs,
    truncateValueByWords
  ]
);



  useEffect(() => {
    setAvailableFunctions((prevFunctions) =>
      prevFunctions.map((func) => {
        if (func.name === 'applyPriceChanges') {
          return { ...func, func: applyPriceChanges };
        }
        if (func.name === 'applyAITitles') {
          return { ...func, func: applyAITitlesWrapper };
        }
        if (func.name === 'applyAIProductDescriptions') {
          return { ...func, func: applyAIProductDescriptionsWrapper };
        }
        return func;
      })
    );
  }, [applyPriceChanges, applyAITitlesWrapper, applyAIProductDescriptionsWrapper]);

  useEffect(() => {
    setAvailableFunctions((prevFunctions) =>
      prevFunctions.map((func) =>
        func.name === 'applyItemSpecifics'
          ? { ...func, func: applyItemSpecificsWrapper }
          : func
      )
    );
  }, [applyItemSpecificsWrapper]);

  const applyPatternSettings = (pattern) => {
    if (!pattern || !pattern.settings) {
      setSnackbar({
        open: true,
        message: '選択されたパターンが正しくありません。',
        severity: 'error',
      });
      return;
    }

    const settings = pattern.settings;

    if (settings.applyTemplate && settings.applyTemplate.selectedTemplateName) {
      setSelectedTemplateName(settings.applyTemplate.selectedTemplateName);
    }

    if (settings.applyPriceChanges && settings.applyPriceChanges.selectedShippingTemplateId) {
      setSelectedShippingTemplateId(settings.applyPriceChanges.selectedShippingTemplateId);
    }

    if (settings.applyAITitles) {
      setDeleteStrings(settings.applyAITitles.deleteStrings || []);
      setReplacePairs(settings.applyAITitles.replacePairs || []);
      setPrependText(settings.applyAITitles.prependText || '');
      setAppendText(settings.applyAITitles.appendText || '');
      setLimitTitleLength(settings.applyAITitles.limitTitleLength || false);

          // ここでパターン保存時に入れたselectedCategoryを復元
      if (settings.applyAITitles.selectedCategory) {
        setSelectedCategory(settings.applyAITitles.selectedCategory);
      } else {
        setSelectedCategory('');
      }
    }

    if (settings.applyAIProductDescriptions && settings.applyAIProductDescriptions.selectedDesignTemplate) {
      setSelectedDesignTemplate(settings.applyAIProductDescriptions.selectedDesignTemplate);
      setCustomFilters(settings.applyAIProductDescriptions.customFilters || []);
      setSentencesToDelete(settings.applyAIProductDescriptions.sentencesToDelete || []);
      setWordsToReplace(settings.applyAIProductDescriptions.wordsToReplace || []);
      setMandatoryKeywords(settings.applyAIProductDescriptions.mandatoryKeywords || []);
      if (settings.applyAIProductDescriptions.selectedAIDescriptionTemplate) {
        setSelectedAIDescriptionTemplate(settings.applyAIProductDescriptions.selectedAIDescriptionTemplate);
      }

      // テキスト変換テンプレートIDの復元
      if (settings.applyAIProductDescriptions.selectedTemplateId) {
        setSelectedTemplateId(settings.applyAIProductDescriptions.selectedTemplateId);
        // テンプレート読み込み処理がある場合はここで呼ぶ
        handleLoadTemplate(settings.applyAIProductDescriptions.selectedTemplateId);
  }
    }

    if (settings.applyItemSpecifics && settings.applyItemSpecifics.selectedItemSpecificsCategory) {
      setSelectedItemSpecificsCategory(settings.applyItemSpecifics.selectedItemSpecificsCategory);
      setSelectedItemSpecificsColumns(settings.applyItemSpecifics.selectedItemSpecificsColumns || []);
      setItemSpecificsMatchingOptions(settings.applyItemSpecifics.itemSpecificsMatchingOptions || itemSpecificsMatchingOptions);
    }

    const newSelectedFunctions = availableFunctions.filter((func) =>
      Object.keys(settings).includes(func.name)
    );
    setSelectedFunctions(newSelectedFunctions);

    setSnackbar({
      open: true,
      message: `パターン "${pattern.name}" が適用されました。`,
      severity: 'success',
    });
  };

  const [isSavePatternDialogOpen, setIsSavePatternDialogOpen] = useState(false);
  const [newPatternName, setNewPatternName] = useState('');
  const [isManagePatternsDialogOpen, setIsManagePatternsDialogOpen] = useState(false);

  const saveCurrentSettingsAsPattern = async () => {
    if (!newPatternName.trim()) {
      setSnackbar({
        open: true,
        message: 'パターン名を入力してください',
        severity: 'warning',
      });
      return;
    }

    const newPattern = {
      name: newPatternName.trim(),
      settings: {},
    };

    selectedFunctions.forEach((func) => {
      if (func.name === 'applyTemplate') {
        newPattern.settings.applyTemplate = { selectedTemplateName };
      }
      if (func.name === 'applyPriceChanges') {
        newPattern.settings.applyPriceChanges = { selectedShippingTemplateId };
      }
      if (func.name === 'applyAITitles') {
        newPattern.settings.applyAITitles = {
          deleteStrings,
          replacePairs,
          prependText,
          appendText,
          limitTitleLength,
          selectedCategory,
        };
      }
      if (func.name === 'applyAIProductDescriptions') {
        newPattern.settings.applyAIProductDescriptions = {
          selectedDesignTemplate,
          customFilters,
          sentencesToDelete,
          wordsToReplace,
          mandatoryKeywords,
          selectedAIDescriptionTemplate,
          selectedTemplateId,
        };
      }
      if (func.name === 'applyItemSpecifics') {
        newPattern.settings.applyItemSpecifics = {
          selectedItemSpecificsCategory,
          selectedItemSpecificsColumns,
          itemSpecificsMatchingOptions,
        };
      }
    });

    try {
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(userSettingsRef);
      let existingPatterns = [];
      if (docSnap.exists()) {
        existingPatterns = docSnap.data().patterns || [];
      }

      const patternExists = existingPatterns.some(
        (pattern) => pattern.name === newPattern.name
      );

      if (patternExists) {
        setSnackbar({
          open: true,
          message: '同名のパターンが既に存在します。',
          severity: 'error',
        });
        return;
      }

      existingPatterns.push(newPattern);
      await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
      setPatterns(existingPatterns);
      setIsSavePatternDialogOpen(false);
      setNewPatternName('');
      setSnackbar({
        open: true,
        message: 'パターンが保存されました。',
        severity: 'success',
      });
    } catch (error) {
      console.error('パターン保存中エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの保存中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  const handleDeletePattern = async (patternName) => {
    try {
      const userSettingsRef = doc(db, 'userSettings', user.uid);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists()) {
        let existingPatterns = docSnap.data().patterns || [];
        existingPatterns = existingPatterns.filter((p) => p.name !== patternName);
        await setDoc(userSettingsRef, { patterns: existingPatterns }, { merge: true });
        setPatterns(existingPatterns);
        setSnackbar({
          open: true,
          message: 'パターンが削除されました。',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('パターン削除エラー:', error);
      setSnackbar({
        open: true,
        message: 'パターンの削除中にエラーが発生しました。',
        severity: 'error',
      });
    }
  };

  const handleUploadAndExecute = async () => {
    if (!user) {
      setSnackbar({
        open: true,
        message: 'ユーザーが認証されていません。',
        severity: 'error',
      });
      return;
    }

    if (csvFiles.length === 0) {
      setSnackbar({
        open: true,
        message: 'アップロードするファイルがありません。',
        severity: 'warning',
      });
      return;
    }

    if (selectedFunctions.length === 0) {
      setSnackbar({
        open: true,
        message: '実行する機能を選択してください。',
        severity: 'warning',
      });
      return;
    }

    setIsScheduling(true);

    try {
      const filesData = csvFiles.map((file, index) => ({
        name: file.name,
        content: fileContents[index],
      }));

      const executeFunctions = async () => {
        let currentData = filesData;

        for (const func of selectedFunctions) {
          try {
            if (func.func.constructor.name === 'AsyncFunction') {
              if (func.name === 'applyTemplate') {
                currentData = await func.func(currentData, user, selectedTemplateName);
              } else if (func.name === 'applyItemSpecifics') {
                currentData = await func.func(currentData, {
                  selectedItemSpecificsCategory,
                  selectedItemSpecificsColumns,
                  itemSpecificsMatchingOptions,
                });
              } else {
                currentData = await func.func(currentData);
              }
            } else {
              if (func.name === 'applyTemplate') {
                currentData = func.func(currentData, user, selectedTemplateName);
              } else if (func.name === 'applyItemSpecifics') {
                currentData = func.func(currentData, {
                  selectedItemSpecificsCategory,
                  selectedItemSpecificsColumns,
                  itemSpecificsMatchingOptions,
                });
              } else {
                currentData = func.func(currentData);
              }
            }
          } catch (error) {
            console.error(`機能 "${func.displayName}" 実行中エラー:`, error);
            setSnackbar({
              open: true,
              message: `機能 "${func.displayName}" 実行中にエラーが発生しました。`,
              severity: 'error',
            });
            setIsScheduling(false);
            return;
          }
        }

        setProcessedData(currentData);

        setSnackbar({
          open: true,
          message: '選択された機能を実行しました。',
          severity: 'success',
        });
        setIsScheduling(false);
      };

      if (isImmediate) {
        await executeFunctions();
      } else {
        const now = new Date();
        const scheduledDateTime = new Date(scheduledTime);
        const delay = scheduledDateTime - now;

        if (delay > 0) {
          setSnackbar({
            open: true,
            message: 'タスクがスケジュールされました。',
            severity: 'success',
          });
          setTimeout(async () => {
            await executeFunctions();
          }, delay);
        } else {
          setSnackbar({
            open: true,
            message: '指定された時間は過去です。正しい時間を選択してください。',
            severity: 'error',
          });
          setIsScheduling(false);
        }
      }
    } catch (error) {
      console.error('処理中エラー:', error);
      setSnackbar({
        open: true,
        message: '処理中にエラーが発生しました。',
        severity: 'error',
      });
      setIsScheduling(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    const parseCSVFiles = () => {
      const data = fileContents.map((content) => {
        const parsedResult = Papa.parse(content, { header: true });
        return {
          data: parsedResult.data,
          columns: parsedResult.meta.fields,
        };
      });
      setParsedData(data);
    };

    if (fileContents.length > 0) {
      parseCSVFiles();
    } else {
      setParsedData([]);
    }
  }, [fileContents]);

  const [processedParsedData, setProcessedParsedData] = useState([]);

  useEffect(() => {
    if (processedData.length > 0) {
      const data = processedData.map((file) => {
        const parsedResult = Papa.parse(file.content, { header: true });
        return {
          name: file.name,
          data: parsedResult.data,
          columns: parsedResult.meta.fields,
        };
      });
      setProcessedParsedData(data);
    } else {
      setProcessedParsedData([]);
    }
  }, [processedData]);

  const handleFilterChange = (label) => {
    setSelectedFilters((prev) =>
      prev.includes(label) ? prev.filter((item) => item !== label) : [...prev, label]
    );
  };

  const handleAddCustomFilter = () => {
    if (customFilterInput.trim() !== '') {
      setCustomFilters([...customFilters, customFilterInput.trim()]);
      setCustomFilterInput('');
    }
  };

  const handleDeleteCustomFilter = (index) => {
    const updatedFilters = [...customFilters];
    updatedFilters.splice(index, 1);
    setCustomFilters(updatedFilters);
  };

  const getFirstImageUrl = (picUrlString) => {
    if (!picUrlString) return '';
    const urls = picUrlString.split('|');
    return urls[0] || '';
  };

  

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  const handleOpenImageModal = (url) => {
    setCurrentImageUrl(url);
    setIsImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImageUrl('');
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const ImageGallery = ({ picUrlString }) => {
    if (!picUrlString) return null;
    const urls = picUrlString.split('|');

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {urls.map((url, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <img
              src={url}
              alt={`商品画像 ${index + 1}`}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleOpenImageModal(url);
              }}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        CSVファイルのアップロードとタスクのスケジュール
      </Typography>

      <Button variant="contained" component="label">
        ファイルを選択（最大5つまで）
        <input
          type="file"
          accept=".csv"
          multiple
          hidden
          onChange={handleFileChange}
        />
      </Button>

      {csvFiles.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">選択されたファイル:</Typography>
          <List>
            {csvFiles.map((file, index) => (
              <ListItem key={index}>
                <Typography>{file.name}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        onClick={handleFunctionDialogOpen}
        startIcon={<MenuIcon />}
      >
        実行する機能を選択
      </Button>

      <Box sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>パターンを選択</InputLabel>
          <Select
            value={selectedPatternName}
            onChange={(e) => {
              const patternName = e.target.value;
              setSelectedPatternName(patternName);
              const pattern = patterns.find((p) => p.name === patternName);
              applyPatternSettings(pattern);
            }}
          >
            <MenuItem value="">
              <em>パターンを選択</em>
            </MenuItem>
            {patterns.map((pattern) => (
              <MenuItem key={pattern.name} value={pattern.name}>
                {pattern.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsSavePatternDialogOpen(true)}
          sx={{ mt: 1 }}
        >
          現在の設定をパターンとして保存
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsManagePatternsDialogOpen(true)}
          sx={{ mt: 1, ml: 1 }}
        >
          パターンを管理
        </Button>
      </Box>

      {selectedFunctions.some((func) => func.name === 'applyTemplate') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">1. テンプレート適用</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>テンプレートを選択</InputLabel>
                <Select
                  value={selectedTemplateName}
                  onChange={(e) => setSelectedTemplateName(e.target.value)}
                >
                  {templateList.map((template) => (
                    <MenuItem key={template.name} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* 選択中のテンプレート詳細表示エリア */}
          {selectedTemplateName && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" sx={{ fontSize: '1rem', fontWeight: 'bold', mb: 1 }}>
                {selectedTemplateName} の詳細
              </Typography>
              {(() => {
                const selectedTemplate = templateList.find(t => t.name === selectedTemplateName);
                if (selectedTemplate && selectedTemplate.operations && selectedTemplate.operations.length > 0) {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '8px',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {selectedTemplate.operations.map((op, index) => (
                        <Card
                          key={index}
                          sx={{
                            width: '100px',
                            boxShadow: 1,
                            p: 0
                          }}
                        >
                          <CardContent
                            sx={{
                              padding: '8px',
                              '&:last-child': { paddingBottom: '8px' }
                            }}
                          >
                            {/* 項目名や列名：文字サイズ小さめ、はみ出しテキストは省略 */}
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mb: 0.5
                              }}
                            >
                              {op.columnName || op.title || 'No title'}
                            </Typography>

                            {/* 以下、アクション別の表示 */}
                            {op.action === 'replace' && (
                                <>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem', color: 'text.secondary', display: 'block' }}>
                                    replace
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                    {op.value}
                                  </Typography>
                                </>
                              )}

                              {op.action === 'ConditionID設定' && (
                                <>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem', color: 'text.secondary', display: 'block' }}>
                                    Condition ID設定
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                    {op.value}
                                  </Typography>
                                </>
                              )}

                              {op.action === 'setItemSpecific' && (
                                <>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem', color: 'text.secondary', display: 'block' }}>
                                    アイテムスペシフィック設定
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                    {op.value}
                                  </Typography>
                                </>
                              )}

                              {op.action === 'prefix' && (
                                <>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem', color: 'text.secondary', display: 'block' }}>
                                    prefix
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                    {op.value}
                                  </Typography>
                                </>
                              )}

                              {op.action === 'suffix' && (
                                <>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem', color: 'text.secondary', display: 'block' }}>
                                    suffix
                                  </Typography>
                                  <Typography variant="caption" sx={{ fontSize: '0.7rem' }}>
                                    {op.value}
                                  </Typography>
                                </>
                              )}
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  );
                } else {
                  return (
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
                      このテンプレートには詳細な操作がありません。
                    </Typography>
                  );
                }
              })()}
            </Box>
          )}
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyPriceChanges') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">2. 価格機能</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {shippingRateTemplates.length > 0 && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>送料設定を選択</InputLabel>
                  <Select
                    value={selectedShippingTemplateId}
                    onChange={(e) => setSelectedShippingTemplateId(e.target.value)}
                  >
                    {shippingRateTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">現在の価格設定</Typography>
            <Typography>為替レート: 1 USD = {exchangeRate} JPY</Typography>
            <Typography>目標利益率: {targetProfitMargin}%</Typography>
            <Typography>eBay最終価値手数料: {fees.ebayFinalValue || 0}%</Typography>
            <Typography>Payoneer手数料: {fees.payoneer || 0}%</Typography>
            <Typography>広告出品手数料: {fees.promotedListing || 0}%</Typography>
            <Typography>eBay取引手数料: {fees.ebayTransactionFee || 0} USD</Typography>
            <Typography>
              ベストオファー自動承認価格:{' '}
              {bestOfferSettings.bestOfferAutoAcceptPercentage || 0}%
            </Typography>
            <Typography>
              最小ベストオファー価格:{' '}
              {bestOfferSettings.minimumBestOfferPercentage || 0}%
            </Typography>
          </Box>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyAITitles') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">3. AIタイトル</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>タイトルカスタマイズテンプレートを選択</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => {
                    const selectedName = e.target.value;
                    setSelectedCategory(selectedName);

                    if (selectedName) {
                      const category = customCategories.find((cat) => cat.name === selectedName);
                      if (category) {
                        setDeleteStrings(category.deleteStrings || []);
                        setReplacePairs(category.replacePairs || []);
                        setPrependText(category.prependText || '');
                        setAppendText(category.appendText || '');
                        setLimitTitleLength(category.limitTitleLength || false);
                      }
                    } else {
                      setDeleteStrings([]);
                      setReplacePairs([]);
                      setPrependText('');
                      setAppendText('');
                      setLimitTitleLength(false);
                    }
                  }}
                >
                  {customCategories.map((cat) => (
                    <MenuItem key={cat.name} value={cat.name}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">タイトルカスタマイズ設定</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  label="削除したい単語 (カンマ区切り)"
                  value={deleteStrings.join(', ')}
                  onChange={(e) =>
                    setDeleteStrings(e.target.value.split(',').map((s) => s.trim()))
                  }
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">置換ペア</Typography>
                {replacePairs.map((pair, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <TextField
                      label="置換前"
                      value={pair.from}
                      onChange={(e) => handleReplacePairChange(index, 'from', e.target.value)}
                    />
                    <TextField
                      label="置換後"
                      value={pair.to}
                      onChange={(e) => handleReplacePairChange(index, 'to', e.target.value)}
                      sx={{ ml: 1 }}
                    />
                    <IconButton onClick={() => handleRemoveReplacePair(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button variant="outlined" onClick={handleAddReplacePair} sx={{ mt: 1 }}>
                  置換ペアを追加
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="先頭に追加する文字列"
                  value={prependText}
                  onChange={(e) => setPrependText(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="末尾に追加する文字列"
                  value={appendText}
                  onChange={(e) => setAppendText(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={limitTitleLength}
                      onChange={(e) => setLimitTitleLength(e.target.checked)}
                    />
                  }
                  label="80文字に制限する"
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyAIProductDescriptions') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">4. AI商品説明</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
          {designTemplateNames.length > 0 && (
            // 既存のデザインテンプレート選択UIここから下は変更なし
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>デザインテンプレートを選択</InputLabel>
                <Select
                  value={selectedDesignTemplate}
                  onChange={(e) => setSelectedDesignTemplate(e.target.value)}
                >
                  {designTemplateNames.map((templateName) => (
                    <MenuItem key={templateName} value={templateName}>
                      {templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedDesignTemplate && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  選択中のテンプレート: {selectedDesignTemplate}
                </Typography>
              )}
            </Grid>
          )}

            <Grid item xs={12} sm={6}>

            {templates && templates.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>テキスト変換テンプレートを選択</InputLabel>
                  <Select
                    value={selectedTemplateId}
                    onChange={(e) => {
                      const templateId = e.target.value;
                      handleLoadTemplate(templateId); // テンプレートを読み込む
                    }}
                  >
                    <MenuItem value="">
                      <em>選択なし</em>
                    </MenuItem>
                    {templates.map((tpl) => (
                      <MenuItem key={tpl.id} value={tpl.id}>
                        {tpl.name || 'Unnamed Template'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

              <FormControlLabel
                control={
                  <Switch
                    checked={useImageDescription}
                    onChange={(e) => setUseImageDescription(e.target.checked)}
                    name="useImageDescription"
                    color="primary"
                  />
                }
                label="画像解析の説明を使用"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <OptionsPanel
                selectedFilters={selectedFilters}
                handleFilterChange={handleFilterChange}
                customFilterInput={customFilterInput}
                setCustomFilterInput={setCustomFilterInput}
                customFilters={customFilters}
                handleAddCustomFilter={handleAddCustomFilter}
                handleDeleteCustomFilter={handleDeleteCustomFilter}
                sentencesToDelete={sentencesToDelete}
                setSentencesToDelete={setSentencesToDelete}
                wordsToReplace={wordsToReplace}
                setWordsToReplace={setWordsToReplace}
              />
            </Grid>


              <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">必ず含めたいキーワード</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <TextField
                  label="キーワードを入力"
                  value={mandatoryKeywordInput}
                  onChange={(e) => setMandatoryKeywordInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMandatoryKeyword}
                  sx={{ ml: 1, height: '56px' }}
                >
                  追加
                </Button>
              </Box>
              <List>
                {mandatoryKeywords.map((keyword, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteMandatoryKeyword(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={keyword} />
                  </ListItem>
                ))}
              </List>              
            </Grid>
          </Grid>
        </Paper>
      )}

      {selectedFunctions.some((func) => func.name === 'applyItemSpecifics') && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">5. Item Specifics適用</Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>適用するカテゴリー</InputLabel>
                <Select
                  value={selectedItemSpecificsCategory}
                  onChange={(e) => {
                    setSelectedItemSpecificsCategory(e.target.value);
                    setSelectedItemSpecificsColumns([]);
                  }}
                  label="適用するカテゴリー"
                >
                  {Object.keys(itemSpecificsCategories).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {selectedItemSpecificsCategory && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>適用するカラムを選択</InputLabel>
                    <Select
                      multiple
                      value={selectedItemSpecificsColumns}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.includes('all')) {
                          const allColumns = getColumnsForSelectedCategory();
                          if (
                            selectedItemSpecificsColumns.length === allColumns.length
                          ) {
                            setSelectedItemSpecificsColumns([]);
                          } else {
                            setSelectedItemSpecificsColumns(allColumns);
                          }
                        } else {
                          setSelectedItemSpecificsColumns(value);
                        }
                      }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return '適用するカラムを選択';
                        }

                        const displayedValues = selected.slice(0, 5);
                        const hiddenCount = selected.length - displayedValues.length;

                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {displayedValues.map((value) => (
                              <Chip key={value} label={value} size="small" />
                            ))}
                            {hiddenCount > 0 && (
                              <Chip label={`+${hiddenCount}個`} size="small" />
                            )}
                          </Box>
                        );
                      }}
                    >
                      <MenuItem
                        value="all"
                        onClick={() => {
                          const allColumns = getColumnsForSelectedCategory();
                          if (selectedItemSpecificsColumns.length === allColumns.length) {
                            setSelectedItemSpecificsColumns([]);
                          } else {
                            setSelectedItemSpecificsColumns(allColumns);
                          }
                        }}
                      >
                        <Checkbox
                          checked={
                            selectedItemSpecificsColumns.length ===
                              getColumnsForSelectedCategory().length &&
                            selectedItemSpecificsColumns.length > 0
                          }
                          indeterminate={
                            selectedItemSpecificsColumns.length > 0 &&
                            selectedItemSpecificsColumns.length <
                              getColumnsForSelectedCategory().length
                          }
                        />
                        <Typography variant="body1">全選択</Typography>
                      </MenuItem>
                      {getColumnsForSelectedCategory().map((column) => (
                        <MenuItem key={column} value={column}>
                          <Checkbox
                            checked={selectedItemSpecificsColumns.indexOf(column) > -1}
                          />
                          <Typography variant="body1">{column}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  マッチングの基準を選択してください。
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={itemSpecificsMatchingOptions.matchSource}
                    onChange={(e) =>
                      setItemSpecificsMatchingOptions((prev) => ({
                        ...prev,
                        matchSource: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="title"
                      control={<Radio />}
                      label="タイトルのみ"
                    />
                    <FormControlLabel
                      value="description"
                      control={<Radio />}
                      label="商品説明のみ (AI生成部分のみ)"
                    />
                    <FormControlLabel
                      value="both"
                      control={<Radio />}
                      label="タイトルと商品説明の両方 (AI生成部分のみ)"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemSpecificsMatchingOptions.caseSensitive}
                      onChange={(e) =>
                        setItemSpecificsMatchingOptions((prev) => ({
                          ...prev,
                          caseSensitive: e.target.checked,
                        }))
                      }
                      name="caseSensitive"
                    />
                  }
                  label="大文字小文字を区別する"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itemSpecificsMatchingOptions.partialMatch}
                      onChange={(e) =>
                        setItemSpecificsMatchingOptions((prev) => ({
                          ...prev,
                          partialMatch: e.target.checked,
                        }))
                      }
                      name="partialMatch"
                    />
                  }
                  label="部分一致を許可する"
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={overwriteExisting}
                      onChange={(e) => setOverwriteExisting(e.target.checked)}
                    />
                  }
                  label="既存の値を上書きする"
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  マッチングの動作を選択してください。
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  (上記でAI商品説明からマッチングを選択している場合は、自動処理でAI生成した文章からカラムの適所に適用されます。自動処理によって特に値の変更がなかった場合以下の設定が適用されます。)
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={itemSpecificsMatchingOptions.matchingOption}
                    onChange={(e) =>
                      setItemSpecificsMatchingOptions((prev) => ({
                        ...prev,
                        matchingOption: e.target.value,
                      }))
                    }
                  >
                    <FormControlLabel
                      value="priority"
                      control={<Radio />}
                      label="優先順位に基づいて適用"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="マッチしたすべての値を適用"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          )}
        </Paper>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">実行モード:</Typography>
        <Button
          variant={isImmediate ? 'contained' : 'outlined'}
          onClick={() => setIsImmediate(true)}
          sx={{ mr: 1 }}
        >
          即時実行
        </Button>
        <Button
          variant={!isImmediate ? 'contained' : 'outlined'}
          onClick={() => setIsImmediate(false)}
        >
          スケジュール実行
        </Button>
      </Box>

      {!isImmediate && (
        <Box sx={{ mt: 2 }}>
          <TextField
            label="実行開始時間"
            type="datetime-local"
            value={scheduledTime}
            onChange={(e) => setScheduledTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}

      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: 2 }}
        onClick={handleUploadAndExecute}
        disabled={isScheduling}
      >
        {isScheduling ? '処理中...' : 'アップロードして実行'}
      </Button>

      {isScheduling && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">処理進捗:</Typography>
          <Typography>
            {progress.completedItems} / {progress.totalItems} 件が完了しました
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(progress.completedItems / progress.totalItems) * 100}
          />
        </Box>
      )}

{processedParsedData.length > 0 && (
  <Box sx={{ mt: 4 }}>
    <Typography variant="h6">処理結果のプレビュー:</Typography>
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
    >
      {processedParsedData.map((file, index) => (
        <Tab key={index} label={file.name} />
      ))}
    </Tabs>
    {processedParsedData.map((file, index) => {
      const isCurrentTab = activeTab === index;

      // ここから追加: itemsPerPageForPreviewに応じた表示データの切り取りとページング
      // itemsPerPageForPreviewが"All"の場合は全件表示
      const parsedItemsPerPage = itemsPerPageForPreview === "All" ? file.data.length : itemsPerPageForPreview;
      const totalItems = file.data.length;
      const totalPages = Math.ceil(totalItems / parsedItemsPerPage);
      const startIndex = (previewCurrentPage - 1) * parsedItemsPerPage;
      const endIndex = itemsPerPageForPreview === "All" ? totalItems : startIndex + parsedItemsPerPage;
      const displayData = file.data.slice(startIndex, endIndex);
      // ここまで追加

      return (
        <div
          key={index}
          role="tabpanel"
          hidden={!isCurrentTab}
          id={`tabpanel-${index}`}
        >
          {isCurrentTab && (
            <Box sx={{ p: 2 }}>
              
              {/* ここから追加: ページネーション設定UI */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
                <FormControl size="small" sx={{ width: 120 }}>
                  <InputLabel>表示件数</InputLabel>
                  <Select
                    value={itemsPerPageForPreview}
                    label="表示件数"
                    onChange={(e) => {
                      setItemsPerPageForPreview(e.target.value);
                      setPreviewCurrentPage(1); // ページ変更時は1ページ目にリセット
                    }}
                  >
                    <MenuItem value={50}>50件</MenuItem>
                    <MenuItem value={100}>100件</MenuItem>
                    <MenuItem value={200}>200件</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                  </Select>
                </FormControl>

                {itemsPerPageForPreview !== "All" && totalPages > 1 && (
                  <Pagination
                    count={totalPages}
                    page={previewCurrentPage}
                    onChange={(event, value) => setPreviewCurrentPage(value)}
                    color="primary"
                  />
                )}
              </Box>
              {/* ここまで追加: ページネーション設定UI */}

              <Paper elevation={2} sx={{ overflowX: 'auto' }}>
                <TableContainer>
                  <Table
                    size="small"
                    sx={{
                      tableLayout: 'auto',
                      width: 'auto',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {file.columns.map((column, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              maxWidth: '150px',
                              whiteSpace: 'nowrap',
                              padding: '8px',
                              border: '1px solid rgba(224, 224, 224, 1)',
                              fontWeight: 'bold',
                            }}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayData.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          onClick={() => {
                            setSelectedProduct(row);
                            setIsDrawerOpen(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {file.columns.map((column, colIndex) => (
                            <TableCell
                              key={colIndex}
                              sx={{
                                maxWidth: '150px',
                                whiteSpace: 'nowrap',
                                padding: '8px',
                                border: '1px solid rgba(224, 224, 224, 1)',
                              }}
                            >
                              {column === 'PicURL' ? (
                                row[column] ? (
                                  <img
                                    src={getFirstImageUrl(row[column])}
                                    alt="商品画像"
                                    style={{
                                      width: '50px',
                                      height: '50px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                ) : (
                                  'なし'
                                )
                              ) : (
                                row[column]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              {/* ここから追加: ページ下部のページネーション (All以外で複数ページがある場合のみ表示) */}
              {itemsPerPageForPreview !== "All" && totalPages > 1 && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                  <Pagination
                    count={totalPages}
                    page={previewCurrentPage}
                    onChange={(event, value) => setPreviewCurrentPage(value)}
                    color="primary"
                  />
                </Box>
              )}
              {/* ここまで追加 */}

              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              >
                <Box sx={{ width: 700, p: 2 }}>
                  {selectedProduct ? (
                    <>
                      <Typography variant="h6">{selectedProduct['Title']}</Typography>
                      <ImageGallery picUrlString={selectedProduct['PicURL']} />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        価格: {selectedProduct['StartPrice']}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 2 }}>説明:</Typography>
                      <Box
                        sx={{ mt: 2 }}
                        dangerouslySetInnerHTML={{ __html: selectedProduct['Description'] }}
                      />
                    </>
                  ) : (
                    <Typography variant="body1">商品が選択されていません</Typography>
                  )}
                </Box>
              </Drawer>

              <Dialog
                open={isImageModalOpen}
                onClose={handleCloseImageModal}
                maxWidth="lg"
              >
                <DialogContent>
                  <img
                    src={currentImageUrl}
                    alt="拡大画像"
                    style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseImageModal}>閉じる</Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </div>
      );
    })}
  </Box>
)}


      {errorItems.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" color="error">
            エラーが発生したアイテム:
          </Typography>
          <List>
            {errorItems.map((item, index) => (
              <ListItem key={index}>
                <Typography>
                  ファイル名: {item.fileName}, 行番号: {item.rowIndex}, エラー: {item.error}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      {processedData.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">処理結果のダウンロード:</Typography>
          {processedData.map((file, index) => (
            <Button
              key={index}
              variant="contained"
              sx={{ mt: 1, mr: 1 }}
              onClick={() => {
                const blob = new Blob([file.content], {
                  type: 'text/csv;charset=utf-8;',
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              {file.name}
            </Button>
          ))}
        </Box>
      )}

      <Dialog open={functionDialogOpen} onClose={handleFunctionDialogClose}>
        <DialogTitle>実行する機能を選択</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="functions">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {availableFunctions.map((func, index) => (
                    <Draggable key={func.name} draggableId={func.name} index={index}>
                      {(provided) => (
                        <ListItem
                          button
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Checkbox
                            checked={
                              selectedFunctions.findIndex((f) => f.name === func.name) !== -1
                            }
                            onClick={handleFunctionToggle(func)}
                          />
                          <Typography>{func.displayName}</Typography>
                          <IconButton {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </IconButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFunctionDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSaveTemplateDialogOpen}
        onClose={() => setIsSaveTemplateDialogOpen(false)}
      >
        <DialogTitle>テンプレートの保存</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              value={saveOption}
              onChange={(e) => setSaveOption(e.target.value)}
            >
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="新しいテンプレートとして保存"
              />
              {selectedTemplateId && (
                <FormControlLabel
                  value="overwrite"
                  control={<Radio />}
                  label="既存のテンプレートを上書き保存"
                />
              )}
            </RadioGroup>
          </FormControl>
          <TextField
            label="テンプレート名"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveTemplate} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManageTemplatesDialogOpen}
        onClose={() => setIsManageTemplatesDialogOpen(false)}
      >
        <DialogTitle>テンプレートの管理</DialogTitle>
        <DialogContent>
          <List>
            {templates.map((template) => (
              <ListItem key={template.id}>
                <ListItemText primary={template.name} />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteTemplate(template.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManageTemplatesDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSavePatternDialogOpen}
        onClose={() => setIsSavePatternDialogOpen(false)}
      >
        <DialogTitle>現在の設定をパターンとして保存</DialogTitle>
        <DialogContent>
          <TextField
            label="パターン名"
            value={newPatternName}
            onChange={(e) => setNewPatternName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSavePatternDialogOpen(false)}>キャンセル</Button>
          <Button onClick={saveCurrentSettingsAsPattern} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManagePatternsDialogOpen}
        onClose={() => setIsManagePatternsDialogOpen(false)}
      >
        <DialogTitle>パターンの管理</DialogTitle>
        <DialogContent>
          <List>
            {patterns.map((pattern) => (
              <ListItem key={pattern.name}>
                <ListItemText primary={pattern.name} />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeletePattern(pattern.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsManagePatternsDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CsvSchedulerUploader;